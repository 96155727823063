export const title={
    AIExperiment:'AI Experiment',
    Interior:'Interior',
    Outdoor:'Outdoor',
    Exterior:'Exterior',
    Shop:'Shop',
    uploadTitle:`Start from scratch`,
    upload:`Upload your room photo`,
    example:'Or use example images',
    Reviews:'Discussion',
    exampleName:'Example',
    mode:'Mode',
    Architectural: 'Architectural',
    tools:'Tools',
    similar_items:'Similar items',
    materials:'Materials',
    colorPalette:'Color palette',
    style:'Describe the style you like',
    furniture:'Choose your preferred furniture',
    stepsLeft:'steps left',
    register:'Already have a Crewzz account?',
    welcome:'Welcome Back',
    signIn: 'Sign in',
    signUp: 'Sign up',
    cardNumber:'Card Number*',
    paymentInformation:'Payment Information',
    selectCard:'Select Card',
    newDesign:'New design',
    redesign:'Home Redesign',
    partial:'Partial Remodel',
    kitchenRemodeling:'Cabinet Real Fill',
    editing:'Furniture Editing',
    addItem:'Add Item',
    eraser:'Magic Eraser',
    lighting:'Change Lighting',
    detection:'Furniture Detection',
    realTimeDesign:'Real-time Design',
    DIY:'Mood Board DIY',
    tool:'Tool',
    mask:'Please select the section you would like AI to help you.',
    area:'Area of adding item',
    assistant:'Visual Assistant',
    roomType:'Tell us about your space details',
    selection:'Selection',
    refine:'Real time editing? (optional)',
    category:'Select the furniture you want to edit',
    productImage:'Color and material',
    TETX_2_IMG:'InteriorGen',
    MIDJOURNEY_V6:'Midjourney V6',
    FLOORPLAN_GPT:'Floorplan GPT',
    DRAFT_IMAGE:'Draft & Imagine AI design',
    fastPreserved:'Instant Home Redesign',
    color:'Color and material (optional)',
    train:'Train your style',
    rendering: 'Rendering',
    myAssets: 'My Assets',
    design: 'Design',
    myDesign: 'My Design',
    publish: 'Publish',
    chat: 'Chat',
    history: 'History',
    before: 'Before',
    after: 'After',
    preview: 'Preview',
    ChatDesigner: 'Chat Designer',
    GenVideo: 'Short Video Generator',
    ProductVideoStoryboard:'Product Video Storyboard',
    video: 'Video',
    character: 'Assistant',
    talk:'Integrate Al into Your Business',
    AICommunity:'AI Community',
    bedroomDesign:'Bedroom design',
    bohemianStyle:'Bohemian style',
    scandinavianLivingRoom:'Scandinavian living room',
    topDay:'Top of the day',
    topWeek:'Top of the week',
}
