export const inforPage = {
  billing: {
    title: 'Billing Information',
  },
  credit: {
    title: 'Credit History',
  },
  // Billing Information
  currentPlan: 'Current Plan',
  planUsage: 'Plan Usage',
  remaining: 'Remaining',
  billingCycle: 'Billing cycle',
  billingPeriod: 'Billing period',
  paymentmethod: 'Payment method',
  supportTxt1: 'Visit the',
  supportTxt2: 'term and condition',
  supportTxt3: 'page or',
  supportTxt4: 'contact us',
  supportTxt5: 'for additional support.',
  unsubscribe: 'Unsubscribe',
  unsubscribeTxt: 'Please be aware that unsubscribe will cause you to lose all of your saved content.',
  deleteMyAccount: 'Delete my account',
  deleteMyAccountTxt: 'Are you sure you want to delete your account? This will permanently erase your account.',
  // Credit History
  nextPayment: 'Next Payment',
  paymentMethod: 'Payment Method',
  creditsAvailable: 'Credits available',
  btn: {
    editPlan: 'Edit plan',
    changePaymentMethod: 'Change payment method',
    unsubscribe: 'Unsubscribe',
    delete: 'Delete',
    edit: 'Edit',
  },
  table: {
    service: 'Service',
    date: 'Date',
    remaining: 'Remaining',
  },
  backToCollov: 'BACK TO COLLOV',
  new: 'NEW',
} 