import axios, { CancelToken } from 'axios'
import { v4 as uuidv4 } from "uuid";
import qs from 'qs'
import { clientStore } from '@/stores/client'
import { ElMessage } from 'element-plus'
import {
  useLoginModelStore,
} from "pages/home/store/newLanding";


const sources = {};
const request = axios.create({
  baseURL: '/',
  timeout: 360000, //超时时间
  transformRequest: [function (data) {
    return qs.stringify(data)
  }],
  validateStatus(status: number) {
    switch (status) {
      case 400:
        ElMessage.error('Network error')
        break;
      case 401:
        // ElMessage.error('Token is invalid, please log in again.')
        const client = clientStore()
        client.token = ''
        client.setToken(null)
        client.userInfo = {}
        client.memberInfo = {}
        const loginModelStore = useLoginModelStore();
        client.loginCallbacks.push(() => {
          location.reload()
        })
        // if (!loginModelStore.show) {
        //   loginModelStore.toggle();
        // }
        if (!loginModelStore.typeVisible) {
          loginModelStore.toogleVisibleType();
        }
        break;
      case 403:
        break;
      case 404:
        break;
      case 500:
        ElMessage.error('The system is busy, please try again later.')
        break;
    }
    return status >= 200 && status < 300;
  }
})

/*请求拦截器*/
request.interceptors.request.use((config) => {
  const client = clientStore()
  const cancelTokenSource = CancelToken.source();
  config.cancelToken = cancelTokenSource.token;
  const requestId = config.url; // You might want to use a unique identifier for each request
  if (sources[requestId]) {
    if (requestId === '/clientMember/getMember') {
      return
    }
    sources[requestId].push(cancelTokenSource)
  } else {
    sources[requestId] = [cancelTokenSource];
  }
  let token: string | null = client.token
  if (!token || token === 'null' || token === 'undefined') {
    // token = uuidv4()
    client.setToken(token)
  }
  if (config.method === 'post') {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  }
  if (config.method === 'postByJson') {
    config.headers['Content-Type'] = 'application/json'
    config.method = 'post'
  }
  config.headers['authorization'] = 'Bearer ' + token
  return config
})
request.interceptors.response.use(
  (response) => {
    const client = clientStore()
    if (response.config.url && sources[response.config.url]) {
      sources[response.config.url].shift()
    }
    if (sources[response.config.url].length <= 0) {
      delete sources[response.config.url]
    }
    if (response.data && response.data.message) {
      if (response.data.message.indexOf('please log in first') > -1) {
        client.loginVisible = true
        client.loginType = 'login'
      } else if (response.data.message.indexOf('no credits') > -1) {
        client.showUpgrade()
      } else if (response.data.message.indexOf('Upgrade') > -1) {
        client.upgradeTip = true
        client.showUpgrade()
      }
    }
    return response.data
  },
  (error) => {
    if (!(error && error.response)) {
      error.message = '连接服务器失败'
    }
    // ElMessage.error(error.message)
    return Promise.reject(error.message)
  }
)
request.cancelRequest = function (requestId) {
  if (sources[requestId]) {
    sources[requestId].forEach(f => {
      f.cancel('Request canceled by the user');
    })
    delete sources[requestId]
  }
};
//post 请求
request.upload = function (url, options) {
  const client = clientStore()
  return new Promise((resolve, reject) => {
    axios.post(url, options, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: 'Bearer ' + client.token
      }
    }).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.msg)
      }
    }).catch(e => {
      if ({ ...e }.response.status === 401) {
        // client.loginVisible = true
        // client.loginType = 'login'
        const loginModelStore = useLoginModelStore();
        client.loginCallbacks.push(() => {
          location.reload()
        })
        loginModelStore.toggle();
        reject('please log in first')
      }
    })
  })
};
//post 请求
request.postByJson = function (url, options) {
  const client = clientStore()
  return new Promise((resolve, reject) => {
    axios.post(baseURL + url, options, {
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + client.token
      }
    }).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.msg)
      }
    }).catch(e => {
      if ({ ...e }.response.status === 401) {
        client.loginVisible = true
        client.loginType = 'login'
        reject('please log in first')
      }
    })
  })
};

request.runPredict = function (options) {
  // const runUrl='http://34.122.43.231:8021/run/predict'
  // const runUrl='http://34.136.219.223:8023/testFastApiFile'

  const runUrl = `https://${location.hostname}/run`
  // const runUrl='http://34.122.43.231:8027/run'
  const client = clientStore()
  return new Promise((resolve, reject) => {
    axios.post(runUrl, options, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: client.token
      }
    }).then(response => {
      if (response.status === 200) {
        resolve(response.data)
      } else {
        reject(response.msg)
      }
    }).catch(e => {
      const detailStatus = { ...e }
      if (detailStatus.response && detailStatus.response.status === 401) {
        client.loginVisible = true
        client.loginType = 'login'
      }
      reject(e)
    })
  })
};
export default request
