export const home = {
    landing: {
        btn: {
            'ExploreCrewzzServices': 'Explore Crewzz Services',
            'LearnMore': 'Learn More'
        },
        CrewzzDesign: {
            title: 'Crewzz Design',
            text: `Reimagining the homeowner's experience`
        },
        AIExperiment: {
            title: 'AI Experiment',
            text: 'Discover Crewzz AI Experiment: Revolutionizing home design with advanced AI for breathtaking, personalized spaces.'
        },
        InteriorDesign: {
            title: 'Interior Design',
            text: 'Discover the warmth of a space designed by Crewzz Interior, where every detail echoes your personal lifestyle and desires.'
        },
        CrewzzBlueprint: {
            title: 'Crewzz Blueprint',
            text: 'Craft your legacy with Crewzz Architectural Design, where structures tell your story and innovation meets intimacy and comfort.'
        },
        OutdoorDesign: {
            title: 'Outdoor Design',
            text: 'Create your oasis with Crewzz Outdoor Design, blending nature’s canvas with the rhythm of your life, tailored to every sunrise and sunset.'
        },
        ExteriorDesign: {
            title: 'Exterior Design',
            text:  `Define elegance with Crewzz Exterior Design, redefine your home's first impression, where your home’s facade becomes a beacon of your personal taste and style.`
        }
    }
} 