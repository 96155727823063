export const menu={
    redesign:'redesign',
    dashboard_pricing:'Pricing',
    backToHomepage:'Back to Homepage',
    creditHistory:'Credit History',
    billingInformation:'Billing Information',
    billingHistory:'Billing History',
    myDesign:'MY DESIGN',
    enterprise:'ENTERPRISE',
    pricing:'PRICING',
    community:'COMMUNITY',
    DesignGallery:'Design Gallery',
    Stylecommunity:'Style community',
    designer:'Need designer help?',
    account:'My Account',
    enterpriseManage:'Enterprise Dashboard',
    Profile:'Profile',
    dashboard_home: 'Home',
    dashboard_tools:'AI tools',
    dashboard_myAssets: 'My Assets',
    dashboard_enterprise: 'Enterprise',
    freePackage: 'Free Package',
    dashboard_publish: 'Published',
    roomType:'Room type',
    'Feature':'Feature',
    'AI Design':'AI Design',
    'Instant Home Redesign':'Instant Home Redesign',
    'Kitchen Remodeling':'Kitchen Remodeling',
    'Partial Modification':'Partial Modification',
    'Your Design Model':'Your Design Model',
    'Chat Designer':'Chat Designer',
    'Product Storyboard':'Product Storyboard',
    'InteriorGen':'InteriorGen',
    'Image Up-scale':'Image Up-scale',
    'Visual Assistant':'Visual Assistant',
    'Remove Background':'Remove Background',
    'Short video generation':'Short video generation',
    'No Watermark':'No Watermark',
    perGeneration:'per generation',
    Ask:'/ Ask',
    message:'/ message',
    AIApplications:'AI Applications',
    developmentTools:'Development Tools',
    database:'Database',
    Amount:'Amount',
    Date:'Date',
    Status:'Status',
    'Payment Method':'Payment Method',
    'Service':'Service',
    'Cost':'Cost',
    'Remaining':'Remaining',
    FAILED:'FAILED',
    Paid:'Paid',
    dashboard_community: 'Community',
    Design: 'Design',
    Assistant: 'Assistant',
    BETA: 'BETA',
    TermsConditions:'Terms and Conditions',
    TermsofService:'Terms of Service',
    PrivacyPolicy:'Privacy Policy',
    /*chat*/
    replaceFurniture:'replace this furniture',
    textDesign:'Design a modern living room with white walls, a gray sofa, light wooden floors',
    textDesignwooden:'Design a rustic kitchen with wooden beams, stone walls, a farmhouse sink and cream cabinets',
    textDesignoffice:'Design an elegant office with a mahogany desk, leather chair, built-in bookshelves, a plush rug, and a window overlooking a garden.',
    GenVideo:'A lady is cooking in a modern kitchen',
    GenVideobedroom:'A contemporary bedroom in the sunshine',
    GenVideoparty:'Illustrate setting up a patio for a party',
    ChatDesigner:'Please design a modern bedroom with a king bed and a dresser for me',
    ChatDesignerroom:'Please redesign my room',
    ChatDesignerlivingroom:'Please design my living room',
    ProductVideoStoryboard:'Design an advertising script for my new kitchen design',
    ProductVideoStoryboardColor:'Color psychology interior design, target young people, modern, 5 key frames',
    ProductVideoStoryboardPerfume:'perfume, target young lady, 4 keyframes',
    publishSuccessDetail:'Give me design suggestion',
    defaultChat:'Design an advertising script for my new kitchen design.',
    defaultChatColor:'Color psychology interior design, target young people, modern, 5 key frames.',
    defaultChatPerfume:'perfume, target young lady, 4 keyframes.',

    style:'Official Style',
    styleImage:'Upload Reference',
    prompt:'Text Prompt',
}
