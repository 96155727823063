import {defineStore} from 'pinia'
import {useI18n} from "vue-i18n";
import {
    requestGetEditRecord,
    requestGetRecord,
    requestSelectImage,
    requestGenerateImage,
    requestGenerateMaskUrls,
    requestListFurnitureForNewAi,
    requestGetMaskUrls, requestSaveMagicEraser, requestGetUpScaleUrl,
} from "@/apis/design";
import {ElMessage} from 'element-plus'
import {clientStore} from "@/stores/client";
import {Loading} from "@/libs/loading/index";
import {requestUploadBase64} from "@/apis/common";
import {categories} from "@/dictionary/categories";
/*存初始数据*/

const basicForm = {
    quality: 'HIGH',
    strength: 'STRONG',
    privacy: 'PUBLIC',
    mode: 'PRESERVED',
    speed: 'FAST',
    type: 'FREE',
    emptyRoom: 'NO',
    sourceId: '',
    maskTypes: '',
}
const defaultForm = {
    'designModel.id': '',
    requestId: '',
    roomType: '',
    styleImage: '',
    style: '',
    uploadUrl: '',
    maskUrl: '',
    color: '',
    material: '',
    prompt: '',
    productImage: '',
    productMessage: '',
    thumbPrompt: '',
    generateUrl: '',
    category: '',
    mode: '',
}
export const currentState = {
    recordForm: {
        ...basicForm,
        ...defaultForm
    }
}

import {canvasStatus, createGenerateForm, designTool, getModeTool} from "@/dictionary/designTool";
import {formatText, goDesign, retry} from "@/hooks/util";
import {userRecord} from "@/hooks/record";
import {useDraw} from "@/hooks/draw";
import {listRooms} from "@/apis/designDashBoard";
import {styleNavs} from "@/dictionary/style";

const undoList = []
const redoList = []
export const magicObj = {
    result: [],
    categories: [],
    maskArea: ''
}
export const designStore = defineStore<any, any>('design', {
    state: () => {
        return {
            form: {
                ...defaultForm,
                videoUrl: '',
            },
            formType: '',
            generateMode: '',
            id: '',
            imagLoad: false,
            styleActive: '',
            showUrl: '',
            initUrl: '',
            disabled: false,
            /*设计类型*/
            type: '',
            loading: false,
            enhancing: false,
            earseLoading: false,
            /*活动状态*/
            activeType: 'Design',
            refresh: false,
            redo: 0,
            undo: 0,
            canUndo: false,
            canRedo: false,
            drawType: 'cursor',
            /*设计步骤*/
            stepObj: {
                next: false,
                active: '',
                ask: false,
                left: 0,
                top: 0,
                x: 0,
                y: 0,
            },
            historyList: [],
            /*选择区域模块*/
            selected: [],
            selected2: [],
            referenceId: -1,
            magicType: 'furnishing',
            architectural: '',
            itemDetail: {},
            /*商品*/
            similar: {
                loading: false,
                list: []
            },
            categories: [],
            backTimes: 0
        }
    },
    getters: {
        getTypeInfo(state) {
            // 自动完成! ✨
            const idx = designTool.findIndex(f => f.type === state.type)
            return idx >= 0 ? designTool[idx] : {}
        },
        getTime(state) {
            return this.getTypeInfo.time || 40
        },
        getNeedCompleteList(state) {
            const {formType} = this.getTypeInfo
            if (!formType) {
                return []
            }
            const {step, formKey} = formType
            const requiredList = step.filter(f => f.required)
            const needEditList = requiredList.filter(({key, active}) => {
                const keys = formKey[key]
                const hasValue = keys&&keys.some(function (value) {
                    if (!active) return !!state.form[value]
                    return !!state.form[state[active]]
                })
                return !hasValue
            })
            return needEditList || []
        },
        getAlList(state) {
            const info = this.getTypeInfo
            const _formType=this.formType||'formType'
            if (!info[_formType]) {
                return []
            }
            const infoDetail =state.type==='fastPreserved'? info[_formType]:info['formType']
            const step = infoDetail.step
            const formKey = infoDetail.formKeys || infoDetail.formKey
            const needEditList = step.filter(({key, active, condition}) => {
                if (condition) {
                    return !condition(state.form)
                }
                if (formKey instanceof Array) {
                    return true
                }
                const keys = formKey[key]
                const hasValue = keys&&keys.some(function (value) {
                    if (!active) return !!state.form[value]
                    return !!state.form[state[active]]
                })
                return !hasValue

            })
            return needEditList || []
        },
        buttonType(state) {
            if (!state.type) {
                return false
            }
            if (state.type === 'assistant') {
                if (state.stepObj.ask) return 'done'
                return false

            }
            if (state.type === 'eraser') {
                return 'done'
            }
            if (state.type === 'fastPreserved' && state.formType === 'generateForm') {
                return 'done'
            }
            if (state.type === 'Upscale') {
                return 'upscale'
            }
            const len = this.getNeedCompleteList?.length
            return len < 1 ? 'generate' : false
        },
        /*总步骤*/
        allStep(state) {
            if (!state.type) {
                return 0
            }
            const {formType} = this.getTypeInfo
            if (!formType) {
                return 0
            }
            const {step} = formType
            return step.filter(f => f.required).length
        },
        /*获取当前类型所有的步骤*/
        hasStep(state) {
            if (!state.type) {
                return []
            }
            const {formType} = this.getTypeInfo
            if (!formType) {
                return []
            }
            const {step} = formType
            return step.map(m => m.key)
        },
        /*获取下一步*/
        getNextStep(state) {
            if (!state.type) {
                return ''
            }

            if (this.getAlList.length > 0) {
                return this.getAlList[0].key
            }
            return ''
        },
        /*剩余步骤*/
        getLeft(state) {
            if (!state.type) {
                return 0
            }
            return this.getNeedCompleteList.length
        },
        maskType(state) {
            if (state.magicType === 'furnishing') {
                return 'Furniture'
            }
            return 'Architectural'

        },
        getUseList(state) {
            return (name = 'result',locale) => {
                const arr=designTool.filter(f => f.showList && f.showList.indexOf(name) > -1).filter(f => f.includes && state.drawType ? f.includes.includes(state.drawType) : true)
                return arr.filter(f=>f.country?.includes(locale))
            }
        },
        getEditType(state) {
            return state.drawType ? canvasStatus.includes(state.drawType) ? 'draw' : 'design' : 'design'
        },
        getCategories(state) {
            let arr = []
            const category = formatText(state.form.category)
            categories.forEach(f => {
                const idx = f.subcategories.findIndex(s => {
                    const name = formatText(s.name)
                    return name === category
                })
                if (idx >= 0) {
                    arr = f.subcategories[idx].products
                }
            })
            return arr
        },

    },
    actions: {
        /*清空form*/
        clearForm() {
            for (let i in this.form) {
                this.form[i] = defaultForm[i]
            }
            undoList.splice(0, undoList.length)
            redoList.splice(0, redoList.length)
            this.undo = undoList.length
            this.redo = redoList.length
        },
        /*获取record数据*/
        setInitForm(res, notNeed) {
            // this.selected = []
            this.id = res.id
            this.form.mode = ''
            magicObj.result = []
            magicObj.categories = []
            magicObj.maskArea = ''
            this.generateMode = res.mode || ''
            this.form.privacy = res.privacy || 'PUBLIC'
            this.form.emptyRoom = res.emptyRoom || 'NO'
            this.initUrl = res.upScaleUrl || res.generateUrl || res.uploadUrl

            if (notNeed) {
                this.form.privacy = 'PUBLIC'
                this.form.emptyRoom = 'NO'
                this.form.uploadUrl = res.uploadUrl || res.generateUrl
                this.form.generateUrl = res.generateUrl
                this.showUrl = res.upScaleUrl || res.generateUrl

            } else {
                for (let i in this.form) {
                    this.form[i] = res[i]
                }
                if (res.maskTypes) {
                    const maskTypes = JSON.parse(res.maskTypes)
                    if ('furnishing' in maskTypes) {
                        this.selected = maskTypes['furnishing']
                        this.magicType = 'furnishing'
                    } else {
                        this.architectural = maskTypes['architectural'][0]
                        this.magicType = 'architectural'
                    }
                }
            }
            if (this.type === 'redesign') {
                this.form.mode = res.mode || 'PRESERVED'
            }
            for (let i in currentState.recordForm) {
                currentState.recordForm[i] = res[i] || currentState.recordForm[i]
            }
            this.addRecord()
        },
        selectImage(id, index) {
            return requestSelectImage({id, index})
        },
        async saveMagicEraser(router) {
            try {
                if (!this.id) {
                    return ElMessage.warning('please select the area you want to remove')
                }
                this.disabled = true
                this.loading = true
                await requestSaveMagicEraser({id: this.id, url: this.form.generateUrl || this.form.uploadUrl})
                this.loading = false
                this.refresh = !this.refresh
                router.push('/result/' + this.id)
            } catch (e) {
                this.disabled = false
                this.loading = false
                ElMessage.error(e)
            }

        },
        /*开始生成图片*/
        async generageImage(route, router, url) {
            if (!this.toVerify()) {
                return Promise.reject(false)
            }
            if (this.loading) {
                return
            }
            const idx = designTool.findIndex(f => f.type === this.type)
            const {mode} = designTool[idx]
            if (this.type !== 'eraser') {
                this.loading = true
            }
            this.form.maskUrl = this.form.maskUrl || url
            if (this.form.maskUrl && this.form.maskUrl.indexOf('data:image/png;') > -1) {
                this.form.maskUrl = await this.uploadBase64(this.form.maskUrl)
                this.saveMaskArea()
            } else if (this.form.maskUrl === 'maskArea') {
                this.form.maskUrl = await this.uploadBase64(magicObj.maskArea)
                this.saveMaskArea()
            }
            const params = {
                ...basicForm,
                ...this.form,
                mode: this.form.mode || mode,
                productImage: this.form.productImage || this.form.productMessage || ''
            }
            const list = styleNavs.filter(f => f.key !== this.styleActive)
            list.forEach(f => {
                delete params[f.key]
            })
            if (params.generateUrl) {
                params.uploadUrl = params.generateUrl
            }
            const detail = getModeTool(this.type, 'type')
            if (!detail.redesign && route.name === 'result') {
                params.id = this.id
                if (this.form.generateUrl) {
                    this.form.uploadUrl = this.form.generateUrl
                }
                delete params.generateUrl
            }
            /* if (this.id && this.type !== 'redesign'&&this.type !== 'kitchenRemodeling'&&this.type !== 'fastPreserved'&&route.name==='result') {
                 params.id = this.id
                 if (this.form.generateUrl) {
                     this.form.uploadUrl = this.form.generateUrl
                 }
                 delete params.generateUrl
             }*/
            return this.saveGenerate(params, route, router)
        },
        async generagePendingImage(route, router, url) {
            if (!this.toVerify()) {
                return Promise.reject(false)
            }
            if (this.loading) {
                return
            }
            const idx = designTool.findIndex(f => f.type === this.type)
            const detailTool = designTool[idx]
            const formKey = detailTool.formType?.formKey || {}
            const form = this.form
            /*for(let i in formKey){
                formKey[i].forEach(f=>{
                    form[f]=this.form[i]
                })
            }*/
            const {mode} = detailTool
            if (this.type !== 'eraser') {
                this.loading = true
            }
            this.form.maskUrl = this.form.maskUrl || url
            if (this.form.maskUrl && this.form.maskUrl.indexOf('data:image/png;') > -1) {
                this.form.maskUrl = await this.uploadBase64(this.form.maskUrl)
                this.saveMaskArea()
            } else if (this.form.maskUrl === 'maskArea') {
                this.form.maskUrl = await this.uploadBase64(magicObj.maskArea)
                this.saveMaskArea()
            }
            const params = {
                ...form,
                mode: this.form.mode || mode,
                productImage: this.form.productImage || this.form.productMessage || ''
            }
            const list = styleNavs.filter(f => f.key !== this.styleActive)
            list.forEach(f => {
                delete params[f.key]
            })
            delete params.videoUrl
            delete params.generateUrl
            return this.saveGenerate(params, route, router)
        },
        async generateForm({params, route, router}) {
            const detail = getModeTool(this.type, 'type')
            if (!params) {
                params = createGenerateForm(detail.generateForm, this.form)
            }
            if (!this.toVerify()) {
                return Promise.reject(false)
            }
            if (this.loading) {
                return
            }
            this.loading = true
            return this.saveGenerate(params, route, router)
        },

        saveGenerate(params, route, router, pageType) {
            return new Promise(((resolve, reject) => {
                requestGenerateImage(params).then(res => {
                    const client = clientStore()
                    if (!res.success) {
                        this.loading = false
                        if (res.message.indexOf('no credits') > -1) {
                            client.showUpgrade()
                            this.loading = false
                            reject(false)
                            return false
                        }
                        ElMessage.error(res.message)
                        reject(false);
                        return
                    } else {
                        client.getMember()
                        if (params.mode === 'MAGIC_ERASER') {
                            this.loading = false
                            resolve(res)
                        } else if (params.id) {
                            this.getEditRecord(res.data.id)
                            return;
                        } else if (pageType) {
                            if (params.mode === 'FAST_PRESERVED') {
                                setTimeout(() => {
                                    goDesign('/design/pending?t=' + new Date().getTime() + '&id=' + res.data.id)
                                }, 1 * 1000)
                            } else {
                                this.loading = false
                                goDesign('/design/pending?t=' + new Date().getTime() + '&id=' + res.data.id)
                            }
                        } else if (route) {
                            if (params.mode === 'FAST_PRESERVED') {
                                setTimeout(() => {
                                    this.toPageNext(res, route, router)
                                }, 1 * 1000)
                            } else {
                                this.loading = false
                                this.toPageNext(res, route, router)
                            }
                            return;
                        }
                        return new Promise(resolve => res)

                    }
                }).catch(() => {
                    this.loading = false
                })
            }))

        },
        toVerify() {
            const client = clientStore()
            if (!client.hasLogin) {
                client.loginVisible = true
                client.loginType = 'login'
                return false;
            }
            if (this.loading) {
                return false
            }
            return true
        },
        getEditRecord(id) {
            this.loading = true
            retry(requestGetEditRecord, {id}, 'editing', null).then(res => {
                this.loading = false
                if (res.status === 'FAILED') {
                    const {t} = useI18n()
                    return ElMessage.error(t('pending.failTip'))
                } else {
                    this.form.uploadUrl = res.generateUrl
                    this.refresh = !this.refresh
                    this.saveRecord()
                }
            }).catch(() => {
                this.loading = false
            })
        },
        toPageNext(res, route, router) {
            const query = route.query
            const params = {
                id: res.data.id,
                from: query.from
            }
            router.push({
                path: '/pending',
                query: {...params, t: new Date().getTime()}
            })
        },
        reGenerageImage(route, router, pageType, item) {
            if (!this.toVerify()) {
                return Promise.reject(false)
            }
            this.loading = true
            let params = {}
            if (pageType !== 'history') {
                params = {
                    ...currentState.recordForm
                }
            } else {
                params = {
                    ...item
                }
            }
            return this.saveGenerate(params, route, router, pageType)
        },
        toUpscale() {
            const params = {
                id: this.id,
                generateUrl: this.form.generateUrl,
                size: '4x'
            }
            requestGetUpScaleUrl(params)
        },
        async generateMaskUrls(url,text) {
            const uploadUrl = url
            if (!uploadUrl) {
                return
            }
            this.success = false
            const vm = Loading({
                text: text||'Analysing Image',
                close: () => {
                }
            })
            const res = await requestGenerateMaskUrls({uploadUrl})
            if (res.success) {
                return new Promise(resolve => {
                    retry(requestGetMaskUrls, {uploadUrl: url}, '').then((res) => {
                        const result = JSON.parse(res.data.result)
                        vm.props.onClose()
                        resolve(result)
                    })
                })
            } else {
                ElMessage.error(res.message)
                vm.props.onClose()
            }
        },
        async uploadBase64(base64) {
            const data = {
                base64,
                referType: 'OTHER'
            }
            const res = await requestUploadBase64(data)
            return res.data.url
        },
        async listFurnitureForNewAi(base64) {
            const params = {
                base64: base64.replace('data:image/jpeg;base64,', ''),
                secondaryType: this.itemDetail.secondaryType,
                type: this.itemDetail.type,
                count: 1000,
                splitCount: 50,
                isNewAi: true
            }
            const res = await requestListFurnitureForNewAi(params)
            this.similar.loading = false
            this.similar.list = res.list || []
            // this.vendors = res.vendors || []
            // this.uuids = res.uuids || []

            // console.log(res)
        },
        saveMaskArea() {
            const obj = {}
            if (this.magicType === 'furnishing') {
                obj['furnishing'] = this.selected
            } else {
                obj['architectural'] = [this.architectural]
            }
            this.form.maskTypes = JSON.stringify(obj)
        },
        saveRecord() {
            const record = userRecord()
            const {undo, redo} = record.save({state: JSON.stringify(this.$state)})
            this.undo = undo
            this.redo = redo
        },
        addRecord() {
            const record = userRecord()
            record.addData({state: JSON.stringify(this.$state)})
        },
        listRooms(recordId) {
            listRooms({recordId}).then(res => {
                this.historyList = res
            })
        }
    }
})
export const modelStore = defineStore<any, any>('model', {
    state: () => {
        return {
            step: 0,
            loading: false,
            result: {},
            form: {
                id: '',
                name: '',
                description: '',
                styleStrength: 1,
                styleImage: '',
                prompt: '',
                coverImage: '',
            }
        }
    },
    getters: {},
    actions: {}
})

