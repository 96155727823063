
// import 'element-plus/es/components/message/style/css'
import { ElMessage } from 'element-plus'
import { defineStore } from 'pinia'
import {
  requestUserInfo,
} from "@/apis/client";
import { requestGetMember } from "@/apis/design";
import { reqHasSubscription } from "@/apis/design/newIndex";
import { designStore } from "@/stores/design";
import { goGptHome } from "@/hooks/util";
import { login, register, oauthRender, checkLogin, logout } from "@/apis/common"
import type { PersistedStateOptions } from 'pinia-plugin-persistedstate';

interface UserInfo {
  avatar?: string
  username?: string,
}
interface MemberInfo {
  aiRemainCount?: number
  subscriptionRemainCount?: number
  hasOverDue?: boolean
}
/*客户store*/
//@ts-ignore
export const clientStore = defineStore('client', {
  state: () => {
    return {
      userInfo: {} as UserInfo,
      token: '' as string | null,
      loginVisible: false,
      loginType: 'login',
      upgrade: {
        visible: false,
        payVisible: false,
        paymentId: '',
        payType: '',
        index: 1,
        initIndex: 0,
      },
      isScription: false,
      loading: false,
      form: {
        firstName: '',
        lastName: '',
        username: '',
        password: '',
        password2: '',
        mobile: '',
        email: '',
        code: '',
        verCode: '',
        mobileCode: ''
      },
      upgradeTip: false,
      memberInfo: {} as MemberInfo,
      errMessage: '',
      loginCallbacks: [] as Function[]
    }
  },
  getters: {
    avatar: (state) => {
      return state.userInfo?.avatar
    },
    hasLogin: (state) => {
      return state.userInfo.username ? true : false
    },
    member: (state) => {
      const keys = Object.keys(state.memberInfo)
      const isMember = !!(keys.indexOf('hasOverDue') > -1 && !state.memberInfo.hasOverDue)
      return state.userInfo.username ? isMember : false
    },
    userName: (state) => {
      return state.userInfo.username || ''
    },
    credits: (state) => {
      const aiRemainCount = state.memberInfo.aiRemainCount || 0//非会员积分
      const count = state.memberInfo.subscriptionRemainCount || 0
      return count > 2000 ? 'Unlimited' : count + aiRemainCount
    },
    creditNum: (state) => {
      return state.memberInfo.subscriptionRemainCount || 0
    }
  },
  persist: {
    key: 'collov_ai_client',
    enabled: true,
    storage: localStorage,
    paths: ['token', 'userInfo', 'memberInfo'],
  } as PersistedStateOptions,
  actions: {
    async getMember() {
      const res = await requestGetMember()
      this.memberInfo = res
      this.upgradeTip = false
      return new Promise(resolve => resolve(true))
    },
    async hasSubscription() {
      if (!this.hasLogin) {
        return false
      }
      return new Promise(async (resolve) => {
        const res = await reqHasSubscription()
        this.isScription = res.data || false
        resolve(res)
      })
    },
    setToken(token: string | null) {
      this.token = token
    },
    vaildateForm(locale: string, loginType: string) {
      this.errMessage = ''
      const emailPattern = locale === 'zh' ? new RegExp("^1[3456789]\\d{9}$") : /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      const passwordPattern = new RegExp("^(?=.*[A-Za-z])[A-Za-z\\d$@$!%*#?&_=+-/]{8,}$");
      if (loginType === 'code') {
        if (!this.form.email || !this.form.mobileCode) {
          this.errMessage = '请填写完整的手机号码和验证码'
          return false
        } else if (!emailPattern.test(this.form.email)) {
          this.errMessage = locale === 'zh' ? '请输入正确的手机号码' : 'Email enter in the format: name@example.com.'
          return false
        }
      } else {

        if (!this.form.email || !this.form.password) {
          this.errMessage = locale === 'zh' ? '请填写完整的手机号码址和密码' : 'Please enter your full email address and password'
          return false
        } else if (loginType === 'register' && locale === 'zh') {
          if (!this.form.mobileCode) {
            this.errMessage = '请输入正确的手机验证码'
            return false
          }
        } else if (!emailPattern.test(this.form.email)) {
          this.errMessage = locale === 'zh' ? '请输入正确的手机号码' : 'Email enter in the format: name@example.com.'
          return false
        } else if (!passwordPattern.test(this.form.password)) {
          this.errMessage = locale === 'zh' ? '密码必须至少包含8个字符，并且至少包含1个字母和1个数字。' : 'password enter 8+ characters with 1 letter and 1 number'
          return false
        }
      }

      return true
    },
    vaildateRegisterForm() {
      this.errMessage = ''
      if (!this.form.firstName) {
        this.errMessage = 'Please enter your first name'
        return false
      }
      if (!this.form.lastName) {
        this.errMessage = 'Please enter your last name'
        return false
      }
      if (!this.form.username) {
        this.errMessage = 'Please enter your email address'
        return false
      }
      if (this.form.username.indexOf('@') === -1) {
        this.errMessage = 'Please input the correct email address'
        return false
      }
      if (!this.form.password) {
        this.errMessage = 'Please enter your password'
        return false
      }
      if (!this.form.mobile) {
        this.errMessage = 'Please enter your phone number'
        return false
      }
      return true
    },
    vaildateLoginForm() {
      this.errMessage = ''
      if (!this.form.username) {
        this.errMessage = 'Please enter your email address'
        return false
      }
      if (this.form.username.indexOf('@') === -1) {
        this.errMessage = 'Please input the correct email address'
        return false
      }
      if (!this.form.password) {
        this.errMessage = 'Please enter your password'
        return false
      }
      return true
    },
    async register() {
      this.errMessage = ''
      if (!this.vaildateRegisterForm()) return
      const params = {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        username: this.form.username,
        password: this.form.password,
        mobile: this.form.mobile,
      }
      this.loading = true
      const res = await register(params)
      this.loading = false
      if (res.data) {
        // 注册成功
        this.login()
      } else {
        this.errMessage = 'Registration failed'
      }
    },
    vaildateRegisterForm_AI_WEBSITE_SYSTEM() {
      this.errMessage = ''
      if (!this.form.firstName) {
        this.errMessage = 'Please enter your first name'
        return false
      }
      if (!this.form.lastName) {
        this.errMessage = 'Please enter your last name'
        return false
      }
      if (!this.form.username) {
        this.errMessage = 'Please enter your email address'
        return false
      }
      if (this.form.username.indexOf('@') === -1) {
        this.errMessage = 'Please input the correct email address'
        return false
      }
      if (!this.form.password) {
        this.errMessage = 'Please enter your password'
        return false
      }
      return true
    },
    async register_AI_WEBSITE_SYSTEM(source?: string) {
      console.log('register_AI_WEBSITE_SYSTEM==>', this.form)
      this.errMessage = ''
      if (!this.vaildateRegisterForm_AI_WEBSITE_SYSTEM()) {
        return
      }
      const params = {
        username: this.form.username,
        password: this.form.password,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        mobile: '',
        platform: 'AI_WEBSITE_SYSTEM',
      }
      if (source) {
        params['source'] = source
      }
      this.loading = true
      try {
        const res = await register(params)
        this.loading = false
        if (res.code === 0) {
          ElMessage.success('Registered successfully!')
          return true
        } else {
          const msg = res.msg || 'Register failed'
          this.errMessage = 'Register failed'
          ElMessage.error(msg)
          return false
        }
      } catch (e) {
        this.loading = false
      }
    },
    async login() {
      this.errMessage = ''
      if (!this.vaildateLoginForm()) return
      const params = {
        username: this.form.username,
        password: this.form.password,
        platformType: 'AI_WEBSITE_SYSTEM'
      }
      this.loading = true
      try {
        return new Promise(async (resolve) => {
          const res = await login(params)
          if (res.code === 0 && res.data && res.data.token) {
            this.token = res.data.token
            this.userInfo = res.data.user
            this.loginVisible = false
            this.loginType = ''
            await this.hasSubscription()
            this.loading = false
            setTimeout(() => {
              this.loginCallbacks.forEach((callback: Function) => callback())
              this.loginCallbacks = []
            }, 1000)
            resolve(true)
          } else {
            this.loading = false
            const msg = res.errDesc || 'Login failed'
            this.errMessage = msg || 'Login failed'
            ElMessage.error(msg)
            resolve(false)
          }

        })
      } catch (e) {
        this.loading = false
      }
    },
    async logOut(home = false) {
      this.token = ''
      this.userInfo = {}
      this.memberInfo = {}
      this.setToken(null)
      const design = designStore()
      design.refresh = !design.refresh
      await logout({})
      if (home) {
        return goGptHome(true)
      }
    },
    getInfo() {
      return requestUserInfo().then((res: any) => {
        return new Promise((resolve) => {
          this.userInfo = res
          this.loginVisible = false
          resolve(true)
        })
      })

    },
    oauthLogin(path?: string) {
      location.href=import.meta.env.VITE_APP_BASEURL + 'restAuth/render'
      return
      // window.open(import.meta.env.VITE_APP_BASEURL + 'restAuth/render', '_blank')
      // return
      let width = 600,
        height = 600;
      let top = (window.screen.height - 30 - width) / 2;
      let left = (window.screen.width - 10 - height) / 2;
      const tempPage = window.open(
        "",
        "_blank",
        `width=${width},height=${height},top=${top},left=${left}`
      );
      /**/
      const redirect = path || ''
      tempPage.location = import.meta.env.VITE_APP_BASEURL + 'restAuth/render'
      // oauthRender({ loginType: 'google', type: 'login', redirect: redirect }).then((res: any) => {
      //   if (tempPage) {
      //     tempPage.location = `https://accounts.google.com/o/oauth2/v2/auth?scope=profile email&include_granted_scopes=true&response_type=code&state=state&client_id=${res.clientId
      //       }&redirect_uri=${encodeURI(res.redirectUrl)}`;
      //   }
      // })
    },
    oauthLoginCallback(body: {
      type: string,
      auth: string
    }) {
      checkLogin({ authId: body.auth }).then(async (res: any) => {
        if (res && res.data) {
          this.token = res.data.token
          this.userInfo = res.data.user
          this.loginVisible = false
          this.loginType = ''
          await this.hasSubscription()
          this.loading = false
          setTimeout(() => {
            this.loginCallbacks.forEach((callback: Function) => callback())
            this.loginCallbacks = []
          }, 1000)
        }
      });
    },
    showLogin() {
      this.loginVisible = true
      this.loginType = 'login'
    },
    showUpgrade() {
      this.upgrade.visible = true
      this.upgrade.payVisible = false
      this.upgrade.paymentId = ''
      this.upgrade.payType = ''
      this.upgrade.index = 1
    }
  }
})
