<template>
  <div
    id="register"
    class="modal"
    v-if="registerModelStore.show"
    @click="onClose"
  >
    <div class="close">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        @click.stop="registerModelStore.toggle"
      >
        <g clip-path="url(#clip0_3_747)">
          <path
            d="M1.70191 21.9993L22 1.70117L20.2988 -9.77705e-06L0.000730057 20.2981L1.70191 21.9993Z"
            fill="white"
          />
          <path
            d="M20.2988 22L22 20.2988L1.70191 0.000739834L0.000729551 1.70192L20.2988 22Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_3_747">
            <rect width="22" height="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="content zero-scroll" @click.stop>
      <div class="login" @click.stop>
        <img src="@/assets/images/home/login/login.png" alt="" />
        <h2>Register</h2>
        <div :class="{ firstName: errMessage.firstName }" class="input">
          <input
            type="firstName"
            v-model="client.form.firstName"
            placeholder="firstName"
          />
        </div>
        <div :class="{ lastName: errMessage.lastName }" class="input">
          <input
            type="lastName"
            v-model="client.form.lastName"
            placeholder="lastName"
          />
        </div>
        <div
          :class="{
            username: errMessage.username,
            'correct-username': errMessage.correctUsername,
          }"
          class="input"
        >
          <input
            type="email"
            v-model="client.form.username"
            placeholder="Email"
          />
        </div>
        <div :class="{ password: errMessage.password }" style="width: 100%">
          <input
            type="password"
            v-model="client.form.password"
            placeholder="password"
          />
        </div>
        <!-- <div class="forget">Forget password?</div> -->
        <div class="btn" v-loading="client.loading" @click="onRegister">
          REGISTER
        </div>
        <div class="sign-in">
          Already have a Crewzz account?
          <strong @click="onSignIn">Sign In</strong>
        </div>
        <!-- <div class="foot">Continue with Google <img src="@/assets/images/home/login/google.png" alt="" /></div> -->
      </div>
    </div>
  </div>

  <el-dialog
    v-model="show"
    title=""
    modal-class="dialog"
    :append-to-body="true"
    :width="289"
    :height="192"
  >
    <div class="content">
      <div class="desc">Need to design this page</div>
    </div>
  </el-dialog>
</template>
<script setup lang="ts">
import { reactive, ref } from "vue";
import {
  useRegisterModelStore,
  useLoginModelStore,
} from "pages/home/store/newLanding";
import { clientStore } from "@/stores/client";

const client = clientStore();

const loginModelStore = useLoginModelStore();
const registerModelStore = useRegisterModelStore();
const onClose = () => {
  registerModelStore.toggle();
};

const show = ref(false);

const form = reactive({
  username: "",
  password: "",
  firstName: "",
  lastName: "",
  platform: "AI_WEBSITE_SYSTEM",
});
client.form.username = "";
client.form.password = "";
client.form.firstName = "";
client.form.lastName = "";
client.form.platform = "AI_WEBSITE_SYSTEM";
const errMessage = reactive({
  username: false,
  correctUsername: false,
  password: false,
  firstName: false,
  lastName: false,
});

// 校验表单
const onCheckForm = () => {
  console.log("form :>> ", form);
  errMessage.username = client.form.username === "";
  errMessage.correctUsername = client.form.username.indexOf("@") === -1;
  errMessage.password = client.form.password === "";
  errMessage.firstName = client.form.firstName === "";
  errMessage.lastName = client.form.lastName === "";
};

const onRegister = async () => {
  if (client.loading) return;
  onCheckForm();
  if (errMessage.username || errMessage.password) return;
  console.log("client.form==>", client.form);
  const isRegister = await client.register_AI_WEBSITE_SYSTEM();
  if (isRegister) {
    loginModelStore.toggle();
    registerModelStore.toggle();
  }
};
function onSignIn() {
  onClose();
  loginModelStore.toggle();
}
</script>
<style lang="less" scoped>
.firstName {
  position: relative;
  margin-bottom: 1.5rem;
  &::after {
    content: "Please Enter Your First Name ! ";
    position: absolute;
    bottom: -1.1rem;
    left: 20px;
    width: 100%;
    font-size: 10px;
    z-index: 99999;
    color: red;
  }
}
.lastName {
  position: relative;
  margin-bottom: 1.5rem;
  &::after {
    content: "Please Enter Your Last Name ! ";
    position: absolute;
    bottom: -1.1rem;
    left: 20px;
    width: 100%;
    font-size: 10px;
    z-index: 99999;
    color: red;
  }
}
.username {
  position: relative;
  margin-bottom: 1.5rem;
  &::after {
    content: "Please Enter Your Email Address ! ";
    position: absolute;
    bottom: -1.1rem;
    left: 20px;
    width: 100%;
    font-size: 10px;
    z-index: 99999;
    color: red;
  }
}
.correct-username {
  position: relative;
  margin-bottom: 1.5rem;
  &::after {
    content: "Please Enter The Correct Email Address !";
    position: absolute;
    bottom: -1.1rem;
    left: 20px;
    width: 100%;
    font-size: 10px;
    z-index: 99999;
    color: red;
  }
}

.password {
  position: relative;
  margin-bottom: 1.5rem;
  &::after {
    content: "Please Enter Your Password ! ";
    position: absolute;
    bottom: -1.1rem;
    left: 20px;
    width: 100%;
    font-size: 10px;
    z-index: 99999;
    color: red;
  }
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
  height: 100vh;
  width: 100vw;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(15px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h2 {
    color: #1d4651;
    text-align: center;
    font-family: Leon;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;
    margin-top: 24px;
  }
  .content {
    border-radius: 1.25rem;
    width: 30.8125rem;
    overflow-y: auto;
    max-height: 90%;
    box-sizing: border-box;
    position: relative;
    @media screen and (max-width: @phone-landscape) {
      width: 100%;
    }
  }
  .close {
    width: 30.8125rem;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.5rem;
    svg {
      cursor: pointer;
    }
    @media screen and (max-width: @phone-landscape) {
      width: 100%;
    }
  }
  .login {
    padding: 32px 48px;
    box-sizing: border-box;
    flex-shrink: 0;
    border-radius: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    img {
      width: 15.0625rem;
      flex-shrink: 0;
      border-radius: 10px;
    }

    .forget {
      color: rgba(29, 70, 81, 0.5);
      font-family: Leon;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.439rem; /* 115.121% */
      cursor: pointer;
      margin-top: 0.5rem;
    }
    .input {
      width: 100%;
      margin-bottom: 1.5rem;
    }
    input {
      // width: 297px;
      width: 100%;
      // height: 34px;
      padding: 0.94rem 1.75rem;
      box-sizing: border-box;
      border-radius: 50px;
      background: #f5f5f5;
      border: none;
      outline: none;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      color: #1d4651;

      &::placeholder {
        color: #1d4651;
        font-family: Leon;
      }
    }

    .btn {
      border-radius: 50px;
      margin-top: 10px;
      width: 100%;
      height: 3.375rem;
      flex-shrink: 0;
      background: #1d4651;
      cursor: pointer;
      display: grid;
      place-content: center;
      color: #fff;
      font-family: Leon;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.439rem;
      margin-top: 3rem;
    }
    .sign-in {
      margin-top: 1.5rem;
      color: #1d4651;
      font-family: Leon;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.439rem; /* 115.121% */
      strong {
        border-bottom: 1px solid #1d4651;
        cursor: pointer;
        color: #1d4651;
        font-family: Leon;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.439rem;
        margin-left: 0.5rem;
      }
    }

    .foot {
      color: #1d4651;
      font-family: ABeeZee;
      font-size: 16px;
      font-style: italic;
      font-weight: 400;
      line-height: 23.024px; /* 115.121% */
      display: flex;
      margin-top: 10px;
      align-items: center;
      img {
        height: 30px;
        width: 30px;
        margin-left: 10px;
      }
    }
  }
}
</style>
