(function () {
  // rem等比适配配置文件
  // 基准大小
  const baseSize = 16;
  // 设置 rem 函数
  function setRem() {
    // 当前页面宽度相对于 1440宽的缩放比例，可根据自己需要修改。
    const clientWidth = document.documentElement.clientWidth
    // const clientWidth = Math.min(document.documentElement.clientWidth,1440)
    const scale =clientWidth / 1440;
    let devicePixelRatio = 1;
    if(isMobile()) {
      devicePixelRatio = window.devicePixelRatio;
    }
    // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
    document.documentElement.style.fontSize =
      baseSize * Math.min(scale, 2) * devicePixelRatio + "px";
  }
  // 初始化
  setRem();
  // 改变窗口大小时重新设置 rem
  window.onresize = function () {
    const timer = setTimeout(() => {
      setRem();
      clearTimeout(timer)
    }, 0);
  };

  function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
})();
