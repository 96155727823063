<template>
  <div class="danger-button">
    <slot></slot>
  </div>
</template>
<script setup lang="ts">
</script>
<style scoped lang="less">
.danger-button {
  // width: 129px;
  padding: 0 1rem;
  height: 2.6875rem;
  line-height: 2.6875rem;
  text-align: center;
  font-weight: bold;
  color: white;
  background-color: #e75465;
  border-radius: 50px;
  cursor: pointer; 
  transition: background-color 0.3s;
  color: #FFF;
  font-family: Leon;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
}
.danger-button:hover {
  background-color: rgba(229, 86, 104, 0.8);
}
.danger-button:active {
  background-color: #d9363e;
}
</style>
