interface Style {
    name:string
    value:string
    url:string
    locked:boolean
    category:string
}
export const styleNavs=[
    {name:'Official Style',key:'style',includes:['redesign','partial','fastPreserved']},
    {name:'Upload Reference',key:'styleImage',includes:['redesign']},
    {name:'Text Prompt',key:'prompt',includes:['redesign','partial']},
    {name:'Style',key:'style',includes:['kitchenRemodeling']},
]
export const styleList:Style[] = [
    {
        "name": "christmas",
        "value": "christmas",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/christmas.jpg",
        "locked": false,category:'Festival'
    },
    {
        "name": "halloween",
        "value": "halloween",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/halloween.jpg",
        "locked": false,category:'Festival'
    },
    {
        name: 'Bohemian',
        value: 'bohemian',
        url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/bohemian.jpg',
        locked: false,
        category: "Classics",
    },
    {
        name: 'Coastal',
        value: 'coastal',
        url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/coastal.jpg',
        locked: false,
        category: "Classics",
    },
    {
        name: 'Farmhouse',
        value: 'farmhouse',
        url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/farmhouse.jpg',
        locked: false,
        category: "Classics",
    },
    {
        name: 'Eclectic',
        value: 'eclectic',
        url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/eclectic.jpg',
        locked: false,
        category: "Classics",
    },
    {
        name: 'Scandinavian',
        value: 'scandinavian',
        url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/scandinavian.jpg',
        locked: false,
        category: "Classics",
    },
    {
        name: 'Modern',
        value: 'modern',
        url: 'https://d17axom7zrjq3q.cloudfront.net/20200325/8f117606f5d148708b28122acf7698e9.jpg',
        locked: true,
        category: "Classics",
    },
    {
        name: 'Transitional',
        value: 'transitional',
        url: 'https://d17axom7zrjq3q.cloudfront.net/image/style2/transitional.jpg',
        locked: true,
        category: "Classics",
    },
    {
        name: 'Mid-century',
        value: 'mid-century',
        url: 'https://d17axom7zrjq3q.cloudfront.net/20200324/dd71697a0f464a8f819c9e80c53baff3.jpg',
        locked: true,
        category: "Classics",
    },
    {
        "name": "new chinese",
        "value": "new chinese",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/new chinese.jpg",
        "locked": false,category:'International'
    },
    {
        "name": "french",
        "value": "french",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/french.jpg",
        "locked": false,category:'International'
    },
    {
        "name": "light luxury",
        "value": "light luxury",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/light luxury.jpg",
        "locked": false,category:'International'
    },
    {
        "name": "wabi-sabi",
        "value": "wabi-sabi",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/wabi-sabi.jpg",
        "locked": false,category:'International'
    },
    {
        "name": "minimalist",
        "value": "minimalist",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/minimalist.jpg",
        "locked": false,category:'Modern'
    },
    {
        "name": "natural wood",
        "value": "natural wood",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/natural wood.jpg",
        "locked": false,category:'Modern'
    },
    {
        "name": "creamy",
        "value": "creamy",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/creamy.jpg",
        "locked": false,category:'International'
    },
    {
        "name": "modern minimalist",
        "value": "modern minimalist",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/modern minimalist.jpg",
        "locked": false,
        category: 'Modern'
    },
    {
        "name": "nordic",
        "value": "nordic",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/nordic.jpg",
        "locked": false,category:'Scandivian'
    },
    {
        "name": "contemporary",
        "value": "contemporary",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/contemporary.jpg",
        "locked": false, category: 'Modern'
    },
    {
        "name": "industrial",
        "value": "industrial",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/industrial.jpg",
        "locked": false,category:'Industrial'
    },
    {
        "name": "country",
        "value": "country",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/country.jpg",
        "locked": false,
        category:'Farmhouse'
    },
    {
        "name": "rustic",
        "value": "rustic",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/rustic.jpg",
        "locked": false,category:'Farmhouse'
    },
    {
        "name": "minimalism",
        "value": "minimalism",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/minimalism.jpg",
        "locked": false, category: 'Modern'
    },
    {
        "name": "chinese",
        "value": "chinese",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/chinese.jpg",
        "locked": false,category:'International'
    },
    {
        "name": "urban chinese",
        "value": "urban chinese",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/urban chinese.jpg",
        "locked": false,category:'International'
    },
    {
        "name": "retro",
        "value": "retro",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/retro.jpg",
        "locked": false,category:'Retro'
    },
    {
        "name": "vintage",
        "value": "vintage",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/vintage.jpg",
        "locked": false,category:'Retro'
    },
    {
        "name": "japanese",
        "value": "japanese",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/japanese.jpg",
        "locked": false,category:'International'
    },
    {
        "name": "neoclassical",
        "value": "neoclassical",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/neoclassical.jpg",
        "locked": false,category:'Historical'
    },
    {
        "name": "american country",
        "value": "american country",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/american country.jpg",
        "locked": false,category:'Farmhouse'
    },
    {
        "name": "soft",
        "value": "soft",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/soft.jpg",
        "locked": false, category: 'Modern'
    },
    {
        "name": "art deco",
        "value": "art deco",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/art deco.jpg",
        "locked": false,category:'Glam'
    },
    {
        "name": "hi-tech",
        "value": "hi-tech",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/hi-tech.jpg",
        "locked": false,category:'Gaming'
    },
    {
        "name": "italian",
        "value": "italian",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style/italian.jpg",
        "locked": false,category:'International'
    },
    {
        "name": "mid-century modern",
        "value": "mid-century modern",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/mid-century modern.jpg",
        "locked": false,category:'Mid-Century'
    },
    {
        "name": "shabby chic",
        "value": "shabby chic",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/shabby chic.jpg",
        "locked": false,category:'Historical'
    },
    {
        "name": "japandi",
        "value": "japandi",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/japandi.jpg",
        "locked": false,category:'International'
    },
    {
        "name": "colonial",
        "value": "colonial",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/colonial.jpg",
        "locked": false,category:'Historical'
    },
    {
        "name": "hollywood regency",
        "value": "hollywood regency",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/hollywood regency.jpg",
        "locked": false,category:'Glam'
    },
    {
        "name": "regency moderne",
        "value": "regency moderne",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/regency moderne.jpg",
        "locked": false,category:'Glam'
    },
    {
        "name": "mediterranean revival",
        "value": "mediterranean revival",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/mediterranean revival.jpg",
        "locked": false,category:'International'
    },
    {
        "name": "rococo",
        "value": "rococo",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/rococo.jpg",
        "locked": false,category:'Historical'
    },
    {
        "name": "late baroque",
        "value": "late baroque",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/late baroque.jpg",
        "locked": false,category:'Historical'
    },
    {
        "name": "hollywood glam",
        "value": "hollywood glam",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/hollywood glam.jpg",
        "locked": false,category:'Glam'
    },
    {
        "name": "mediterranean",
        "value": "mediterranean",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/mediterranean.jpg",
        "locked": false,category:'International'
    },
    {
        "name": "southwestern",
        "value": "southwestern",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/southwestern.jpg",
        "locked": false,category:'Farmhouse'
    },
    {
        "name": "french country",
        "value": "french country",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/french country.jpg",
        "locked": false,category:'Farmhouse'
    },
    {
        "name": "asian zen",
        "value": "asian zen",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/asian zen.jpg",
        "locked": false,category:'International'
    },
    {
        "name": "modern farmhouse",
        "value": "modern farmhouse",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/modern farmhouse.jpg",
        "locked": false,category:'Farmhouse'
    },
    {
        "name": "african",
        "value": "african",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/african.jpg",
        "locked": false,category:'International'
    },
    {
        "name": "amish",
        "value": "amish",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/amish.jpg",
        "locked": false,category:'International'
    },
    {
        "name": "arabian",
        "value": "arabian",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/arabian.jpg",
        "locked": false,category:'International'
    },
    {
        "name": "art moderne",
        "value": "art moderne",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/art moderne.jpg",
        "locked": false,category:'Transitional'
    },
    {
        "name": "art nouveau",
        "value": "art nouveau",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/art nouveau.jpg",
        "locked": false,category:'Historical'
    },
    {
        "name": "artisan",
        "value": "artisan",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/artisan.jpg",
        "locked": false,category:'Farmhouse'
    },
    {
        "name": "arts & crafts",
        "value": "arts & crafts",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/arts & crafts.jpg",
        "locked": false,category:'Historical'
    },
    {
        "name": "baroque",
        "value": "baroque",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/baroque.jpg",
        "locked": false,category:'Historical'
    },
    {
        "name": "cuban",
        "value": "cuban",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/cuban.jpg",
        "locked": false,category:'International'

    },
    {
        "name": "gothic",
        "value": "gothic",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/gothic.jpg",
        "locked": false,category:'Historical'
    },
    {
        "name": "Moroccan",category:'International',
        "value": "moroccan",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/moroccan.jpg",
        "locked": false
    },
    {
        "name": "indian",category:'International',
        "value": "indian",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/indian.jpg",
        "locked": false
    },
    {
        "name": "tropical",
        "value": "tropical",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/tropical.jpg",
        "locked": false,category:'Tropical'
    },
    {
        "name": "biophilic",
        "value": "biophilic",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/biophilic.jpg",
        "locked": false,category:'Tropical'
    },
    {
        "name": "cottagecore",
        "value": "cottagecore",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/cottagecore.jpg",
        "locked": false,category:'Farmhouse'
    },
    {
        "name": "maximalist",
        "value": "maximalist",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/maximalist.jpg",
        "locked": false,category:'Eclectic'
    },
    {
        "name": "gaming",
        "value": "gaming",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/gaming.jpg",
        "locked": false,category:'Gaming'
    },
    {
        "name": "cyberpunk",
        "value": "cyberpunk",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/cyberpunk.jpg",
        "locked": false,category:'Gaming'
    },
    {
        "name": "vaporwave",
        "value": "vaporwave",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/vaporwave.jpg",
        "locked": false,category:'Gaming'
    },
    {
        "name": "ski chalet",
        "value": "ski chalet",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/ski chalet.jpg",
        "locked": false,category:'Farmhouse'
    },
    {
        "name": "tribal",
        category:'International',
        "value": "tribal",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/tribal.jpg",
        "locked": false
    },
    {
        "name": "medieval",
        "value": "medieval",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/medieval.jpg",
        "locked": false,category:'Historical'
    },
    {
        "name": "easter",
        "value": "easter",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/easter.jpg",
        "locked": false,category:'Festival'
    },
    {
        "name": "chinese new year",
        "value": "chinese new year",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/chinese new year.jpg",
        "locked": false,category:'Festival'
    },

    {
        "name": "hot pink",
        "value": "hot pink",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/hot pink.jpg",
        "locked": false,category:'Gaming'
    },
    {
        "name": "neoclassic",
        "value": "neoclassic",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/neoclassic.jpg",
        "locked": false,category:'Historical'
    },
    {
        "name": "victorian",
        "value": "victorian",
        "url": "https://d17axom7zrjq3q.cloudfront.net/image/style2/victorian.jpg",
        "locked": false,category:'Historical'
    }
]
