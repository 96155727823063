<script setup lang="ts">
// import LoginModal from '@/components/loginModal/index.vue'
import RegisterModal from "./layout/components/RegisterModal.vue";
import LoginModal from "./layout/components/LoginModal.vue";
import loginType from "./layout/components/loginType.vue";
import { clientStore } from "@/stores/client";
import { useRoute, useRouter } from "vue-router";
import { onMounted, watch } from "vue";
const route = useRoute();
const router = useRouter();
const client = clientStore();
watch(
  () => route.query.auth,
  () => {
    if (route.query.auth) {
      client.oauthLoginCallback({ type: "login", auth: route.query.auth });
      router.replace("/");
    }
  }
);
onMounted(() => {
  if (route.query.auth) {
    client.oauthLoginCallback({ type: "login", auth: route.query.auth });
    router.replace("/");
  }
  // const client = clientStore()
  // window.callback = function (auth:string) {
  //   client.oauthLoginCallback({type: 'login', auth: auth})
  // }
  // let url = window.location.href
  // if(url.split('?').length === 1) return
  // if(getUrlParams(url).tokenFail) {
  //   client.logOut(true)
  // }
});
// const getUrlParams = (url) => {
//   if(url.split('?').length === 1) return
//   const paramsArray = url.split('?')[1].split('&')
//   const params = paramsArray.reduce((acc, param) => {
//     const [key, value] = param.split('=')
//     acc[key] = value
//     return acc
//   }, {})
//   return params
// }
</script>
<template>
  <login-modal />
  <loginType />
  <register-modal />
  <RouterView />
</template>
<style>
html,
body {
  overflow: hidden;
  background: #f5f5f5;
}
.el-loading-spinner .path {
  -webkit-animation: loading-dash 1.5s ease-in-out infinite;
  animation: loading-dash 1.5s ease-in-out infinite;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-width: 2;
  stroke-linecap: round;
  stroke: #e55667 !important;
}
.el-loading-spinner .el-loading-text {
  color: #e55667 !important;
  margin: 3px 0;
  font-size: 14px;
}
</style>
