export const constantRoute = [

  {
    path: '/',
    name: 'dashboard_layout',
    component: () => import('../layout/newLayout.vue'),
    redirect: '/',
    children: [{
      path: '/',
      component: () => import('../views/newlanding/index.vue'),
      name: 'newlanding',
      meta: { preload: true }
    },
    {
      path: '/info/billingInformation',
      component: () => import('../views/billingInformation/index.vue'),
      name: 'billing_information',
      meta: { preload: true }
    },{
      path: '/pricing',
      component: () => import('../views/pricing/index.vue'),
      name: 'pricing',
      meta: { preload: true }
    },
    {
      path: '/info/changePlan',
      component: () => import('../views/billingInformation/changePlan.vue'),
      name: 'billing_information_changePlan',
      meta: { preload: true }
    },
    {
      path: '/info/account',
      component: () => import('../views/account/setting/index.vue'),
      name: 'my_info_account',
      meta: { preload: true }
    },
    ]
  },
  {
    path: '/ai',
    name: 'dashboard_design_layout',
    component: () => import('../layout/designLayout.vue'),
    redirect: '/ai/index',
    children: [
      {
        path: '/ai/index',
        component: () => import('../views/design/home/index.vue'),
        name: 'design_index',
        meta: { preload: true }
      },
      {
        path: '/ai/interior',
        component: () => import('../views/design/interior/index.vue'),
        name: 'design_interior',
        meta: { preload: true }
      },
      {
        path: '/ai/exterior',
        component: () => import('../views/design/exterior/index.vue'),
        name: 'design_exterior',
        meta: { preload: true }
      },
      {
        path: '/ai/outdoor',
        component: () => import('../views/design/outdoor/index.vue'),
        name: 'design_outdoor',
        meta: { preload: true }
      },
      {
        path: '/ai/virtual',
        component: () => import('../views/design/virtual/index.vue'),
        name: 'design_virtual',
        meta: { preload: true }
      },
      {
        path: '/ai/myProjects',
        component: () => import('../views/design/myProjects/index.vue'),
        name: 'design_myProjects',
        meta: { preload: true }
      },
      {
        path: '/ai/myAccount',
        component: () => import('../views/design/myAccount/index.vue'),
        name: 'design_myAccount',
        meta: { preload: true }
      },
      {
        path: '/ai/billingDetails',
        component: () => import('../views/design/billingDetails/index.vue'),
        name: 'design_billingDetails',
        meta: { preload: true }
      },
    ]
  },
  {
    path: '/des/account',
    component: () => import('../views/account/setting/index.vue'),
    name: 'info_account',
    meta: { preload: true }
  },
  {
    path: '/subscribe',
    name: 'design_order',
    component: () => import('../views/order/index.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
  },
  {
    path: '/step',
    name: 'step',
    component: () => import('../views/step/index.vue'),
  },
  {
    path: '/subscription',
    name: 'design_order_subscription',
    component: () => import('../views/order/subscription.vue'),
  },
  {
    path: '/image-design',
    name: 'design_image',
    component: () => import('../views/designImage/index.vue'),
  },
  {
    path: '/select-room',
    name: 'select_room',
    component: () => import('../views/selectRoom/index.vue'),
  },

]
