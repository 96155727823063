import { ElMessage } from "element-plus";
import { corsImgSrc } from "@/hooks/imageCors";
import moment from 'moment-timezone'
import { getModeTool } from "@/dictionary/designTool";
import { statusStore } from "@/stores/status";
let times = 0

const lang = import.meta.env.VITE_APP_LANG
// const lineHost=lang==='en'?'collov.ai':'collov.cn'
const lineHost = location.hostname
const getParams = (obj: any, notNeed = false) => {
  if (!notNeed) {
    const detail = localStorage.getItem('collov_ai_client')
    obj.clientInfo = window.btoa(encodeURIComponent(detail));
  }
  let params = []
  for (let key in obj) {
    if (key && obj[key]) {
      let value = obj[key];
      if (key === 'utm_content') value = value.replace("%", "");
      if (typeof (value) !== 'undefined' && value !== 'undefined') {
        params.push(`${key}=${value}`);
      }
    }
  }

  return params.length > 0 ? `?${params.join('&')}` : ''
}
export const retry = function (promiseFn, params, type: string, call) {
  return new Promise((resolve, reject) => {
    const inner = async function () {
      const res = await promiseFn(params)
      res.retryTimes = times
      let status = false
      if (type === 'editing' || type === 'record') {
        status = res.status === 'PENDING'
      } else if (type === 'upscale') {
        if (times > 30) return reject(res)
        status = !res.upScaleUrl
      } else {
        status = res.data.status === 'PENDING'
      }
      times++
      if (status) {
        setTimeout(inner, 3 * 1000)
        call && call(res)
      } else {
        resolve(res)
      }

    }
    inner()
  })
}
export function Retry() {
  const self = this;
  self.times = 0;
  self.isClose = false
  self.close = function () {
    self.isClose = true
  }
  self.func = function (promiseFn, params, type: string, call) {
    return new Promise((resolve, reject) => {
      const inner = async function () {
        const res = await promiseFn(params)
        res.retryTimes = self.times++
        let status = false
        let tryTime = 3
        const detail = getModeTool(res)
        if (type === 'editing' || type === 'record') {
          tryTime = detail.tryTime || 3
          status = res.status === 'PENDING'
        } else if (type === 'upscale') {
          tryTime = 3
          if (self.times > 30) return reject(res)
          status = !res.upScaleUrl
        } else {
          tryTime = detail.tryTime || 3
          status = res.data.status === 'PENDING'
        }
        times++
        if (status) {
          if (!self.isClose) {
            setTimeout(inner, tryTime * 1000)
          }
          call && call(res)
        } else {
          resolve(res)
        }

      }
      inner()
    })
  }
}
export const toCopyValue = (valueToCopy) => {
  // 创建一个虚拟的textarea元素
  const textarea = document.createElement('textarea');
  textarea.value = valueToCopy;
  textarea.setAttribute('readonly', ''); // 设置为只读以确保兼容性
  textarea.style.position = 'absolute';
  textarea.style.left = '-9999px'; // 将其移动到屏幕外

  // 将textarea添加到body
  document.body.appendChild(textarea);

  // 选中文本并复制
  textarea.select();
  document.execCommand('copy');

  // 移除textarea
  document.body.removeChild(textarea);

}
export const toShare = (value, call) => {
  const valueToCopy = value || location.href;
  toCopyValue(valueToCopy)

  // 显示成功消息
  ElMessage.success('Link copied!');

  call && call()
}
const drawImage = (url) => {
  const image = new Image();
  image.src = corsImgSrc(url)
  image.setAttribute("crossOrigin", 'Anonymous')
  return new Promise((resolve => {
    image.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = this.naturalWidth;
      canvas.height = this.naturalHeight;
      const ctx = canvas.getContext('2d')

      // 将图片插入画布并开始绘制
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      const result = canvas.toDataURL('image/png')
      resolve(result);
    };
  }))

}
// 仅支持视频下载和图片下载
const downLoadVideo = (videoUrl) => {
  let fileName = 'download'; //dayjs
  var xhr = new XMLHttpRequest();
  xhr.open("GET", corsImgSrc(videoUrl), true);
  xhr.responseType = "blob"; // 返回类型blob
  xhr.onload = () => {
    if (xhr.readyState === 4 && xhr.status === 200) {
      let blob = xhr.response;
      let downLoadUrl = window.URL.createObjectURL(
        new Blob([blob], {
          type: "video/mp4",
        })
      );
      let a = document.createElement("a");
      a.download = fileName;
      a.href = downLoadUrl;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  };
  xhr.send();
}
export const downLoad = (url, fileName, videoUrl) => {
  if (videoUrl) {
    return downLoadVideo(videoUrl)
  }
  drawImage(url).then(imgURL => {
    const dlLink = document.createElement('a');
    dlLink.download = 'downLoad';
    dlLink.href = imgURL;
    dlLink.dataset.downloadurl = ["image/png", dlLink.download, dlLink.href].join(':');

    document.body.appendChild(dlLink);
    dlLink.click();
    document.body.removeChild(dlLink);
  })

}
export const formatDate = (date, fomart) => {
  moment.tz.setDefault('UTC/GMT')
  let timezone = new Date().getTimezoneOffset()
  return moment
    .utc(date)
    .zone(timezone)
    .format(fomart)
}
export const isMobile = () => {
  return document.documentElement.clientWidth < 992
}
export const isSmallDesk = () => {
  return document.documentElement.clientWidth < 1000
}
export const setSess = (client) => {
  const params = new URLSearchParams(window.location.search);
  if (params.has('params')) {
    const detail = JSON.parse(params.get('params'))
    if (detail.sess) {
      const sess = JSON.parse(atob(detail.sess))
      if (sess && sess.email) {
        client.setToken(sess.sessionId)
        client.userInfo = sess
      }
    } else if (detail.status === 'logout') {
      client.LogOut()
    }
    params.delete('params');
    const path = window.location.pathname;
    const newUrl = `${path}?${params.toString()}`;
    window.history.replaceState({}, '', newUrl);

  } else if (params.has('clientInfo')) {
    const clientInfo = params.get('clientInfo')
    const detailObj = JSON.parse(decodeURIComponent(atob(clientInfo)))
    client.memberInfo = detailObj.memberInfo
    client.token = detailObj.token
    client.userInfo = detailObj.userInfo

    params.delete('clientInfo');
    const path = window.location.pathname;
    const newUrl = `${path}?${params.toString()}`;
    window.history.replaceState({}, '', newUrl);
  }
}
export const goDesign = (path = '/', params = {}) => {
  const hostName = location.hostname
  const app = statusStore()
  if (app.isDesignApp) {
    if (path.includes('/design/design')) {
      path = path.replace('/design', '')
    }
    return app.router.push(path + getParams(params, true))
  }
  let host = app.isDevMode ? `http://${hostName}:5001` : `https://${lineHost}`
  if (!path.includes('/design')) {
    host = host + '/design'
  }
  if (app.isHomeApp || app.isDevMode) {
    return location.href = host + path + getParams(params || {});
  }
  return location.href = host + path + getParams(params || {}, true);
}
export const goInstant = (path, params) => {
  // const url=lang==='en'?'https://instant.collov.ai':`https://${lineHost}`
  const url = `https://${lineHost}`
  return location.href = url + path + getParams(params || {});
}
export const goRealTime = (path, params) => {
  const hostName = location.hostname
  if (hostName === 'localhost' || hostName.indexOf('127') > -1) {
    return location.href = `http://${hostName}:3003/design` + path + getParams(params || {});
  } else {
    // const url='https://instant.collov.ai/design'
    const url = `https://${location.hostname}/design`
    return location.href = url + path + getParams(params || {});
  }
}
export const goStudio = (path, params) => {
  const hostName = location.hostname
  const url = 'https://' + lineHost + '/studio'
  if (hostName === 'localhost' || hostName.indexOf('127') > -1) {
    if (params) {
      return location.href = `http://${hostName}:3000/studio${path}` + getParams(params || {});
    }
    return location.href = `http://${hostName}:3000/studio${path}`
  }
  if (hostName !== lineHost) {
    return location.href = url + path + getParams(params || {});
  } else if (params) {
    return location.href = url + path + getParams(params || {});
  }
  return location.href = url + path;
}
export const goGptHome = (logout = false) => {
  const hostName = location.hostname
  const app = statusStore()
  if (app.isHomeApp) {
    return app.router.push('/')
  }
  if (app.isDesignApp) {
    if (logout) {
      return location.href = 'https://' + hostName + '?status=logout'
    }
    return location.href = 'https://' + hostName + '?from=studio'
  }
  return location.href = 'https://' + hostName + '?from=studio'
}
export const goDashBoard = () => {

}
export const goHome = (path = '/', params = {}, open = false) => {
  const app = statusStore()
  app.router.push('/')
}
export const formatMoney = (number, decPlaces = 2, decSep = '.', thouSep = ',') => {
  if (number === 'Unlimited') {
    return number
  }
  (decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces),
    (decSep = typeof decSep === 'undefined' ? '.' : decSep)
  thouSep = typeof thouSep === 'undefined' ? ',' : thouSep
  var sign = number < 0 ? '-' : ''
  var i = String(parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces))))
  var j = (j = i.length) > 3 ? j % 3 : 0

  return (
    sign +
    (j ? i.substr(0, j) + thouSep : '') +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, '$1' + thouSep) +
    (decPlaces
      ? decSep +
      Math.abs(number - i)
        .toFixed(decPlaces)
        .slice(2)
      : '')
  )
}
export const calculateNumbers = (num = 0) => {
  if (num && num > 0) {
    num = num + ''
  } else {
    return num = '0'
  }
  // 若数字是四位数以下没到千位的，就直接返回
  if (num < 1000) { return num }
  else {
    // 获取倒数第四个数及之前/去掉后三个数/千位数之前
    let num1 = num.substring(0, num.length - 3)
    // 获取百位数
    let num2 = num.substring(num.length - 3, num.length - 2)
    // 获取十位数
    let num3 = num.substring(num.length - 2, num.length - 1)
    // 设置一个小数点
    let point = '.'
    // 若十位数大于5了，要进一位给百位数
    if (num3 >= 5) {
      // 若百位数恰好等于9，则要进位，百位就成了0，千位数之前的加1
      if (num2 == 9) {
        num2 = 0
        num1 = (++num1) + ''
      } else {
        // 若不等于9就百位数加1即可
        num2 = ++num2
      }
    }
    // 完事后若百位数是0的话需要省略掉百位，及小数点
    if (num2 == 0) {
      num2 = ''
      point = ''
    }
    // 最后拼接千位数之前、小数点、百位数、和一个k
    return num1.concat(point, num2, 'k')
  }
}
export const formatText = (text) => {
  if (typeof text !== 'string') {
    return text
  }
  const cleanString = text.toLowerCase()
    .replaceAll(/[^a-zA-Z0-9]/g, '')
    .replaceAll('and', '');
  return cleanString
}
export const toCollov = (name, target, obj = {}) => {
  let url = `https://${lineHost}/`
  if (name) {
    url = url + name
  }
  if (target) {
    window.open(url + getParams(obj))
  } else {
    location.href = url + getParams(obj);
  }
}
export const checkMobile = () => {
  const w = document.documentElement.clientWidth
  if (w < 980) {
    return true
  } else {
    return false
  }
}

export const pollTimer = (interval = 1000) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, interval)
  })
}
export const usePolling = () => {
  let flag = true
  const stop = () => {
    flag = false
  }
  const run = async (options: {
    promiseFn: (params: any) => Promise<any>
    resolveFn?: (res: any) => boolean | undefined | void
    params?: any
    retry?: number
    interval?: number
  }) => {
    const {
      promiseFn,
      resolveFn = () => true,
      params = {},
      retry = 0,
      interval = 1000
    } = options
    return new Promise(async (resolve, reject) => {
      let count = 0
      while (flag) {
        count++
        try {
          const res = await promiseFn(params)
          if (resolveFn && resolveFn(res)) {
            resolve(res)
            break
          }
          await pollTimer(interval)
        } catch (e) {
          reject(e)
          break
        }
        if (retry && count >= retry) {
          break
        }
      }
    })
  }
  return { stop, run }
}

export const hideWebView = (router) => {
  let hideWebview = localStorage.getItem('hideWebview')
  if (hideWebview) {
    return true
  } else {
    hideWebview = router.query && router.query.hideHeader
    if (hideWebview) {
      localStorage.setItem('hideWebview', hideWebview)
      return true
    } else {
      return false
    }
  }
}