const perks = [
    {
        title: 'All features available for your use.',
        children: ['Up-scale to high resolution', 'Fast generation speed']
    },
    'No watermark',
    'Keep uploaded and generated images private'
]
export const upgrade = {
    title: 'collov.ai Pro',
    message: 'Upgrade to Pro for access to our AI interior design tool and advanced features. Adjust your credits plan as needed to bring your vision to life.',
    perks: JSON.stringify(perks),
    text: `Ideal for interior designers, realtors, furniture vendors, and related professionals in the field of home industry.`,
    perMonth: 'per month',
    usage: {
        title: `There are several AI features available, each with varying capabilities and price points.`,

    },
}
