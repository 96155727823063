import {message} from "../modules/message";
import {description} from "../modules/description";
import {menu} from "../modules/menu";
import {title} from "../modules/title";
import {form} from "../modules/form";
import {designForm} from "../modules/designForm";
import {design} from "../modules/design";
import {inforPage} from "../modules/inforPage";
import {account} from "../modules/account";
import {billing} from "../modules/billing";
import {btn} from "../modules/btn";
import {upgrade} from "../modules/upgrade";
import {pricing} from "@/I18N/en/modules/pricing";
import {priceList} from "@/dictionary/priceList";
import {enterprise} from "@/I18N/en/modules/enterprise";
import {confirm} from "@/I18N/en/modules/confirm";
import {modal} from "@/I18N/en/modules/modal";
import {client} from "@/I18N/en/modules/client";
import {member} from "@/I18N/en/modules/member";
import {plan} from "@/I18N/en/modules/plan";
import {contact} from "@/I18N/en/modules/contact";
import {home} from "@/I18N/en/modules/home";

export const Lang={
    home,
    title,
    form,
    designForm,
    design,
    inforPage,
    account,
    billing,
    btn,
    menu,
    member,
    message,
    description,
    upgrade,
    pricing,
    enterprise,
    confirm,
    modal,
    client,
    plan,
    contact,
    priceList:JSON.stringify(priceList),

}
