import { defineStore } from "pinia";
export const statusStore = defineStore('status', {
  state: () => {
    return {
      name: import.meta.env.VITE_APP,
      theme: '',
      router: null,
    }
  },
  getters: {
    isHomeApp: () => import.meta.env.MODE === 'home',
    isDesignApp: () => import.meta.env.MODE === 'design',
    isDevMode: () => import.meta.env.DEV,
  },
  actions: {

  },
})
