export const pricing={
    title:`Plans & Pricing`,
    subTitle:['All pricing is in USD. You can change plans or cancel anytime.','        Each subscription plan is given a monthly credit amount that automatically resets at the start of each billing cycle, regardless of usage. These do not roll over to the next month.'],
    ContactSales:'Contact Sales',
    Subscribe:'Subscribe',
    Free:'Free',
    usage:{
        title:`There are several AI features available,<br/> each with varying capabilities and price points.`,
        rows:[
            {title:'Feature',weight:'Usage'},
            {title:'AI Design',weight:'1 credits per generation'},
            {title:'Partial Modification',weight:'2 credits'},
            {title:'Your Design Model',weight:'1 credits per generation'},
            // {title:'Creatives Generator',weight:'2 credits per generation'},
            {title:'Chat Designer',weight:'25 credits / New Chat'},
            {title:'Image Up-scale',weight:'0.5 credits'},
        ]
    },
    plans:[
        {
            title: 'collov.ai Free',
            title2: "free",
            headerCta: "Try It Free",
            moduleText: "Explore our AI interior design features for free.",
            price: 'Free',
            per:'',
            perks: [
                'Limited features available for your use.',
                '5 collov.ai credits'
            ],
            tip:'Ideal for anyone looking to design their home'
        },
        {
            title: 'collov.ai Enterprise',
            title2: "enterprise",
            headerCta: "Contact Sales",
            moduleText: "Want collov.ai Enterprise for advanced functions?",
            perks: [
                'API Calls',
                'Customized AI Learning & Training (Design Styles & Furniture)',
                'Generation of numerous design images in one click',
                'Technical support',
                '1-on-1 Customer Success Manager'
            ],
            tip:'Ideal for corporations that want to showcase AI features on their website or backend.'
        }
    ],
    upgradePlans:[
        {
            title: 'collov.ai Pro',
            title2: "AI_DESIGN",
            headerCta: "Subscribe",
            moduleText: "Upgrade to Pro for access to our AI interior design tool and advanced features. Adjust your credits plan as needed to bring your vision to life.",
            price: '19.99',
            salePrice: '19.99',
            per:'per month',
            perks: [
                {
                    title: 'All features available for your use.',
                    children: ['Up-scale to high resolution', 'Fast generation speed']
                },
                'No watermark',
                'Keep uploaded and generated images private'
            ],
            tip:'Ideal for interior designers, realtors, furniture vendors, and related professionals in the field of home industry.'
        }
    ],
    upgrade:{
        tip:'There are several AI features available, each with varying capabilities and price points.',
        ContactSales:'Contact Sales',
        Downgrade:'Downgrade',
        Subscribe:'Subscribe',
        permonth:'per month',
        credits:'credits',
    },
    priceUpgradePlan:[
        {price:9,credits:198,value:900},
        {price:16,credits:352,value:1600},
        {price:24,credits:528,value:2400},
        {price:40,credits:880,value:4000},
        {price:80,credits:1760,value:8000},
        {price:99,credits:'Unlimited',value:9900},
        // {price:120,credits:2640,value:12000},
        // {price:240,credits:5280,value:24000},
        // {value:'contact'},
    ],
    originalPriceUpgradePlan:[
        {price:8,credits:176,value:800},
        {price:9,credits:198,value:900},
        {price:16,credits:352,value:1600},
        {price:24,credits:528,value:2400},
        {price:40,credits:880,value:4000},
        {price:80,credits:1760,value:8000},
        {price:99,credits:'Unlimited',value:9900},
      /*  {price:120,credits:2640,value:12000},
        {price:240,credits:5280,value:24000},*/
        // {value:'contact'},
    ]
}
