export const priceList=[
    {price:9,credits:198,value:900},
    {price:16,credits:352,value:1600},
    {price:24,credits:528,value:2400},
    {price:40,credits:880,value:4000},
    {price:80,credits:1760,value:8000},
    {price:99,credits:'Unlimited',value:9900},
]
export const zhPriceList=[
    {price:9.9,credits:30,value:990},
    {price:19.9,credits:60,value:1990},
    {price:29.9,credits:90,value:2990},
    {price:39.9,credits:120,value:3990},
    {price:79.9,credits:240,value:7990},
    {price:119.9,credits:360,value:11990},
    {price:239.9,credits:720,value:23990},
]
