<template>
  <Teleport to="body">
    <div class="modal" v-if="loginModelStore.show" @click="onClose">
      <div class="close">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          @click.stop="onClose"
        >
          <g clip-path="url(#clip0_3_747)">
            <path
              d="M1.70191 21.9993L22 1.70117L20.2988 -9.77705e-06L0.000730057 20.2981L1.70191 21.9993Z"
              fill="white"
            />
            <path
              d="M20.2988 22L22 20.2988L1.70191 0.000739834L0.000729551 1.70192L20.2988 22Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_3_747">
              <rect width="22" height="22" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="content zero-scroll" @click.stop>
        <!-- 忘记密码 -->
        <div class="forget-wraper" v-if="loginModelStore.forget">
          <div class="title">Forgot password</div>
          <div class="form-item">
            <div :class="{ email: errMessage.email }" class="input">
              <input
                type="email"
                v-model="forgetForm.email"
                placeholder="Email"
              />
            </div>
            <div class="btn" @click="sendEmail" v-if="canSendCode">Send</div>
            <span v-else class="wait"
              >Wait <span class="time">{{ countdown }}</span> s.
            </span>
          </div>
          <div class="form-item">
            <div class="input" :class="{ code: errMessage.code }">
              <input
                type="text"
                v-model="forgetForm.code"
                placeholder="Verification code"
              />
            </div>
          </div>
          <div class="btn-next" v-loading="loading" @click="onNext">Next</div>
          <div class="sign-up">
            Don’t have an account? <strong @click="onSignUp">Sign Up</strong>
          </div>
        </div>
        <!-- 重置密码 -->
        <div class="forget-wraper" v-else-if="loginModelStore.reset">
          <div class="title">Reset password</div>
          <div class="form-item">
            <div :class="{ newPassword: errMessage.newPassword }" class="input">
              <input
                :type="resetForm.newPassword ? 'text' : 'password'"
                v-model="forgetForm.newPassword"
                placeholder="New password"
              />
              <div class="icons">
                <div class="complete" v-if="completeNew">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M21 7.75711L9 19.7571L3.5 14.2571L4.91 12.8471L9 16.9271L19.59 6.34711L21 7.75711Z"
                      fill="#4AB898"
                    />
                  </svg>
                </div>
                <div
                  class="eye"
                  @click="resetForm.newPassword = !resetForm.newPassword"
                >
                  <svg
                    v-if="!resetForm.newPassword"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M12 9.75708C11.2044 9.75708 10.4413 10.0732 9.87868 10.6358C9.31607 11.1984 9 11.9614 9 12.7571C9 13.5527 9.31607 14.3158 9.87868 14.8784C10.4413 15.441 11.2044 15.7571 12 15.7571C12.7956 15.7571 13.5587 15.441 14.1213 14.8784C14.6839 14.3158 15 13.5527 15 12.7571C15 11.9614 14.6839 11.1984 14.1213 10.6358C13.5587 10.0732 12.7956 9.75708 12 9.75708ZM12 17.7571C10.6739 17.7571 9.40215 17.2303 8.46447 16.2926C7.52678 15.3549 7 14.0832 7 12.7571C7 11.431 7.52678 10.1592 8.46447 9.22155C9.40215 8.28386 10.6739 7.75708 12 7.75708C13.3261 7.75708 14.5979 8.28386 15.5355 9.22155C16.4732 10.1592 17 11.431 17 12.7571C17 14.0832 16.4732 15.3549 15.5355 16.2926C14.5979 17.2303 13.3261 17.7571 12 17.7571ZM12 5.25708C7 5.25708 2.73 8.36708 1 12.7571C2.73 17.1471 7 20.2571 12 20.2571C17 20.2571 21.27 17.1471 23 12.7571C21.27 8.36708 17 5.25708 12 5.25708Z"
                      fill="#073B4C"
                    />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M11.83 9.75708L15 12.9171C15 12.8671 15 12.8071 15 12.7571C15 11.9614 14.6839 11.1984 14.1213 10.6358C13.5587 10.0732 12.7956 9.75708 12 9.75708C11.94 9.75708 11.89 9.75708 11.83 9.75708ZM7.53 10.5571L9.08 12.1071C9.03 12.3171 9 12.5271 9 12.7571C9 13.5527 9.31607 14.3158 9.87868 14.8784C10.4413 15.441 11.2044 15.7571 12 15.7571C12.22 15.7571 12.44 15.7271 12.65 15.6771L14.2 17.2271C13.53 17.5571 12.79 17.7571 12 17.7571C10.6739 17.7571 9.40215 17.2303 8.46447 16.2926C7.52678 15.3549 7 14.0832 7 12.7571C7 11.9671 7.2 11.2271 7.53 10.5571ZM2 5.02708L4.28 7.30708L4.73 7.75708C3.08 9.05708 1.78 10.7571 1 12.7571C2.73 17.1471 7 20.2571 12 20.2571C13.55 20.2571 15.03 19.9571 16.38 19.4171L16.81 19.8371L19.73 22.7571L21 21.4871L3.27 3.75708M12 7.75708C13.3261 7.75708 14.5979 8.28386 15.5355 9.22155C16.4732 10.1592 17 11.431 17 12.7571C17 13.3971 16.87 14.0171 16.64 14.5771L19.57 17.5071C21.07 16.2571 22.27 14.6171 23 12.7571C21.27 8.36708 17 5.25708 12 5.25708C10.6 5.25708 9.26 5.50708 8 5.95708L10.17 8.10708C10.74 7.88708 11.35 7.75708 12 7.75708Z"
                      fill="#073B4C"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="form-item">
            <div
              class="input"
              :class="{ confirmPassword: errMessage.confirmPassword }"
            >
              <input
                :type="resetForm.confirmPassword ? 'text' : 'password'"
                v-model="forgetForm.confirmPassword"
                placeholder="Confirm password"
              />
              <div class="icons">
                <div class="complete" v-if="completeReset">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M21 7.75711L9 19.7571L3.5 14.2571L4.91 12.8471L9 16.9271L19.59 6.34711L21 7.75711Z"
                      fill="#4AB898"
                    />
                  </svg>
                </div>
                <div
                  class="eye"
                  @click="
                    resetForm.confirmPassword = !resetForm.confirmPassword
                  "
                >
                  <svg
                    v-if="!resetForm.confirmPassword"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M12 9.75708C11.2044 9.75708 10.4413 10.0732 9.87868 10.6358C9.31607 11.1984 9 11.9614 9 12.7571C9 13.5527 9.31607 14.3158 9.87868 14.8784C10.4413 15.441 11.2044 15.7571 12 15.7571C12.7956 15.7571 13.5587 15.441 14.1213 14.8784C14.6839 14.3158 15 13.5527 15 12.7571C15 11.9614 14.6839 11.1984 14.1213 10.6358C13.5587 10.0732 12.7956 9.75708 12 9.75708ZM12 17.7571C10.6739 17.7571 9.40215 17.2303 8.46447 16.2926C7.52678 15.3549 7 14.0832 7 12.7571C7 11.431 7.52678 10.1592 8.46447 9.22155C9.40215 8.28386 10.6739 7.75708 12 7.75708C13.3261 7.75708 14.5979 8.28386 15.5355 9.22155C16.4732 10.1592 17 11.431 17 12.7571C17 14.0832 16.4732 15.3549 15.5355 16.2926C14.5979 17.2303 13.3261 17.7571 12 17.7571ZM12 5.25708C7 5.25708 2.73 8.36708 1 12.7571C2.73 17.1471 7 20.2571 12 20.2571C17 20.2571 21.27 17.1471 23 12.7571C21.27 8.36708 17 5.25708 12 5.25708Z"
                      fill="#073B4C"
                    />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M11.83 9.75708L15 12.9171C15 12.8671 15 12.8071 15 12.7571C15 11.9614 14.6839 11.1984 14.1213 10.6358C13.5587 10.0732 12.7956 9.75708 12 9.75708C11.94 9.75708 11.89 9.75708 11.83 9.75708ZM7.53 10.5571L9.08 12.1071C9.03 12.3171 9 12.5271 9 12.7571C9 13.5527 9.31607 14.3158 9.87868 14.8784C10.4413 15.441 11.2044 15.7571 12 15.7571C12.22 15.7571 12.44 15.7271 12.65 15.6771L14.2 17.2271C13.53 17.5571 12.79 17.7571 12 17.7571C10.6739 17.7571 9.40215 17.2303 8.46447 16.2926C7.52678 15.3549 7 14.0832 7 12.7571C7 11.9671 7.2 11.2271 7.53 10.5571ZM2 5.02708L4.28 7.30708L4.73 7.75708C3.08 9.05708 1.78 10.7571 1 12.7571C2.73 17.1471 7 20.2571 12 20.2571C13.55 20.2571 15.03 19.9571 16.38 19.4171L16.81 19.8371L19.73 22.7571L21 21.4871L3.27 3.75708M12 7.75708C13.3261 7.75708 14.5979 8.28386 15.5355 9.22155C16.4732 10.1592 17 11.431 17 12.7571C17 13.3971 16.87 14.0171 16.64 14.5771L19.57 17.5071C21.07 16.2571 22.27 14.6171 23 12.7571C21.27 8.36708 17 5.25708 12 5.25708C10.6 5.25708 9.26 5.50708 8 5.95708L10.17 8.10708C10.74 7.88708 11.35 7.75708 12 7.75708Z"
                      fill="#073B4C"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-next" v-loading="loading" @click="onReset">
            {{ completeNew && completeReset ? "Reset" : "Next" }}
          </div>
        </div>
        <div class="login" v-else>
          <img src="@/assets/images/home/login/login.png" alt="" />
          <h2>Login</h2>
          <div
            :class="{ username: errMessage.username }"
            style="margin-bottom: 1.5rem"
          >
            <input
              type="email"
              v-model="client.form.username"
              placeholder="Email"
            />
          </div>
          <div :class="{ password: errMessage.password }">
            <input
              type="password"
              v-model="client.form.password"
              placeholder="password"
            />
          </div>
          <div class="forget" @click="onForget">Forget password?</div>
          <div class="btn" v-loading="client.loading" @click="onLogin">
            LOGIN
          </div>
          <div class="sign-up">
            First time here? <strong @click="onSignUp">Sign Up</strong>
          </div>
          <!-- <div class="foot">Continue with Google <img src="@/assets/images/home/login/google.png" alt="" /></div> -->
        </div>
      </div>
    </div>

    <el-dialog
      v-model="show"
      title=""
      modal-class="dialog"
      :append-to-body="true"
      :width="289"
      :height="192"
    >
      <div class="content">
        <div class="desc">Need to design this page</div>
      </div>
    </el-dialog>
  </Teleport>
</template>
<script setup lang="ts">
import { computed, reactive, ref } from "vue";
import {
  useLoginModelStore,
  useRegisterModelStore,
} from "pages/home/store/newLanding";
import { clientStore } from "@/stores/client";
import { sendCode, updatePwd, checkCode } from "@/apis/common";

const client = clientStore();

const loginModelStore = useLoginModelStore();
const registerModelStore = useRegisterModelStore();
const onClose = () => {
  loginModelStore.toggle();
};
const onForget = () => {
  loginModelStore.toggleForget();
};
const show = ref(false);
const countdown = ref(60);
const loading = ref(false);
const canSendCode = ref(true);

const form = reactive({
  username: "",
  password: "",
});
const forgetForm = reactive({
  email: "",
  code: "",
  newPassword: "",
  confirmPassword: "",
});
const completeNew = computed(() => {
  return !!forgetForm.newPassword.trim().length;
});
const completeReset = computed(() => {
  return (
    completeNew.value && forgetForm.newPassword === forgetForm.confirmPassword
  );
});
const resetForm = reactive({
  newPassword: false,
  confirmPassword: false,
});
if (import.meta.env.DEV) {
  client.form.username = "997390890@qq.com";
  client.form.password = "00000000a";
}

const errMessage = reactive({
  username: false,
  password: false,
  email: false,
  code: false,
  newPassword: false,
  confirmPassword: false,
});

// 校验表单
const onCheckForm = () => {
  console.log("form :>> ", form);
  errMessage.username = client.form.username === "";
  errMessage.password = client.form.password === "";
};
const sendVerificationCode = async () => {
  canSendCode.value = false;
  const timer = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value--;
    } else {
      clearInterval(timer);
      canSendCode.value = true;
      countdown.value = 60; // 重置倒计时
    }
  }, 1000);
};
const onLogin = async () => {
  if (client.loading) return;
  onCheckForm();
  if (errMessage.username || errMessage.password) return;
  const isLogin = await client.login();
  isLogin && loginModelStore.toggle();
};
function onSignUp() {
  registerModelStore.toggle();
  onClose();
}
//发送邮件
async function sendEmail() {
  if (loading.value) {
    return;
  }
  if (!forgetForm.email) {
    return (errMessage.email = true);
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(forgetForm.email)) {
    return (errMessage.email = true);
  }
  errMessage.email = false;
  loading.value = true;
  try {
    const res = await sendCode({ email: forgetForm.email });
    if (res.data === "SUCCESS") {
      sendVerificationCode();
    } else {
      ElMessage.error(res.errDesc||res.data);
    }
    loading.value = false;
  } catch (e) {
    loading.value = false;
    ElMessage.error(e);
  }
}
//校验验证码
async function onNext() {
  if (loading.value) {
    return;
  }
  if (!forgetForm.email) {
    return (errMessage.email = true);
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(forgetForm.email)) {
    return (errMessage.email = true);
  }
  if (!forgetForm.code) {
    errMessage.email = false;
    return (errMessage.code = true);
  }
  errMessage.email = false;
  errMessage.code = false;
  loading.value = true;
  try {
    const res = await checkCode({
      email: forgetForm.email,
      code: forgetForm.code,
    });
    loading.value = false;
    if (res.data === "SUCCESS") {
      loginModelStore.toReset();
    } else {
      ElMessage.error(res.errDesc || res.error);
    }
  } catch (e) {
    loading.value = false;
  }
}
async function onReset() {
  if (!completeNew.value) {
    return (errMessage.newPassword = true);
  }
  if (!completeReset.value) {
    errMessage.newPassword = false;
    return (errMessage.confirmPassword = true);
  }
  errMessage.newPassword = false;
  errMessage.confirmPassword = false;
  loading.value = true;
  try {
    const res = await updatePwd({
      email: forgetForm.email,
      code: forgetForm.code,
      newPassword: forgetForm.newPassword,
      confirmPassword: forgetForm.confirmPassword,
    });
    loading.value = false;
    if (res.data === "SUCCESS") {
      ElMessage.success(res.data);
      loginModelStore.toLogin();
    } else {
      loginModelStore.forget=true
      ElMessage.error(res.errDesc || res.error);
    }
  } catch (e) {
    loginModelStore.forget=true
    loading.value = false;
  }
}
</script>
<style lang="less" scoped>
.username {
  position: relative;
  margin-bottom: 1.5rem;
  &::after {
    content: "Please Enter Your Email Address ! ";
    position: absolute;
    bottom: -1.1rem;
    left: 20px;
    width: 100%;
    font-size: 10px;
    z-index: 99999;
    color: red;
  }
}
.email {
  position: relative;
  &::after {
    content: "Please enter the correct email address";
    position: absolute;
    bottom: -1.1rem;
    left: 20px;
    width: 100%;
    font-size: 10px;
    z-index: 99999;
    color: red;
  }
}
.newPassword {
  position: relative;
  &::after {
    content: "Please Enter Your Wew password ! ";
    position: absolute;
    bottom: -1.1rem;
    left: 20px;
    width: 100%;
    font-size: 10px;
    z-index: 99999;
    color: red;
  }
}
.confirmPassword {
  position: relative;
  &::after {
    content: "Please enter the same password twice ! ";
    position: absolute;
    bottom: -1.1rem;
    left: 20px;
    width: 100%;
    font-size: 10px;
    z-index: 99999;
    color: red;
  }
}
.code {
  position: relative;
  &::after {
    content: "Please Enter Your Verification Code ! ";
    position: absolute;
    bottom: -1.1rem;
    left: 20px;
    width: 100%;
    font-size: 10px;
    z-index: 99999;
    color: red;
  }
}

.password {
  margin-bottom: 1.5rem;
  position: relative;
  &::after {
    content: "Please Enter Your Password ! ";
    position: absolute;
    bottom: -1.1rem;
    left: 20px;
    width: 100%;
    font-size: 10px;
    z-index: 99999;
    color: red;
  }
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2005;
  height: 100vh;
  width: 100vw;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(15px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .content {
    border-radius: 1.25rem;
    width: 30.8125rem;
    overflow-y: auto;
    max-height: 90%;
    box-sizing: border-box;
    position: relative;
    @media screen and (max-width: @phone-landscape) {
      width: 100%;
    }
  }
  .close {
    width: 30.8125rem;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.5rem;
    svg {
      cursor: pointer;
    }
    @media screen and (max-width: @phone-landscape) {
      width: 100%;
    }
  }
  .login {
    padding: 32px 48px;
    box-sizing: border-box;
    flex-shrink: 0;
    border-radius: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 15.0625rem;
      flex-shrink: 0;
      border-radius: 10px;
    }
    div {
      width: 100%;
      text-align: center;
    }
    svg {
      position: absolute;
      cursor: pointer;
      top: -40px;
      right: 0;
    }
    img {
      width: 15.0625rem;
      flex-shrink: 0;
      border-radius: 10px;
    }
    .forget {
      color: rgba(29, 70, 81, 0.5);
      font-family: Leon;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.439rem; /* 115.121% */
      cursor: pointer;
      margin-top: 0.5rem;
    }
    h2 {
      color: #1d4651;
      text-align: center;
      font-family: Leon;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 1.5rem;
      margin-top: 1.5rem;
    }

    input {
      // width: 297px;
      width: 100%;
      // height: 34px;
      padding: 0.94rem 1.75rem;
      box-sizing: border-box;
      border-radius: 50px;
      background: #f5f5f5;
      border: none;
      outline: none;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      color: #1d4651;

      &::placeholder {
        color: #1d4651;
        font-family: Leon;
      }
    }

    .btn {
      border-radius: 50px;
      margin-top: 10px;
      width: 100%;
      height: 3.375rem;
      flex-shrink: 0;
      background: #1d4651;
      cursor: pointer;
      display: grid;
      place-content: center;
      color: #fff;
      font-family: Leon;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.439rem;
      margin-top: 3rem;
    }
    .sign-up {
      margin-top: 1.5rem;
      color: #1d4651;
      font-family: Leon;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.439rem; /* 115.121% */
      strong {
        border-bottom: 1px solid #1d4651;
        cursor: pointer;
        color: #1d4651;
        font-family: Leon;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.439rem;
        margin-left: 0.5rem;
      }
    }

    .foot {
      color: #1d4651;
      font-family: ABeeZee;
      font-size: 16px;
      font-style: italic;
      font-weight: 400;
      line-height: 23.024px; /* 115.121% */
      display: flex;
      margin-top: 10px;
      align-items: center;
      img {
        height: 30px;
        width: 30px;
        margin-left: 10px;
      }
    }
  }
  .forget-wraper {
    padding: 2rem 3rem;
    box-sizing: border-box;
    flex-shrink: 0;
    border-radius: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      color: #1d4651;
      text-align: center;
      font-family: Leon;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .form-item {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      margin-top: 1.5rem;
      .btn {
        border-radius: 3.125rem;
        background: #1d4651;
        padding: 0.8rem 1.75rem;
        box-sizing: border-box;
        color: var(--Gray-00, #fff);
        font-family: Leon;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.439rem; /* 115.121% */
        margin-left: 0.5rem;
        cursor: pointer;
      }
      .input {
        flex: 1;
        flex-shrink: 0;
        position: relative;
        input {
          padding: 0.8rem 1.5rem;
          box-sizing: border-box;
          width: 100%;
          border-radius: 50px;
          background: #f5f5f5;
          border: 0;
          outline: none;
          color: #1d4651;
          font-family: Leon;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.439rem; /* 115.121% */
          &::placeholder {
            color: #1d4651;
          }
        }
        .icons {
          position: absolute;
          right: 24px;
          top: 50%;
          z-index: 9;
          display: flex;
          align-items: center;
          transform: translateY(-50%);
          svg {
            margin-left: 8px;
            cursor: pointer;
          }
        }
      }
    }
    .wait {
      color: rgba(29, 70, 81, 0.5);
      font-family: Leon;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-left: 8px;
      line-height: 23.024px;
      .time {
        color: #e75465;
      }
    }
    .btn-next {
      border-radius: 3.125rem;
      margin-top: 10px;
      width: 100%;
      height: 3.375rem;
      flex-shrink: 0;
      background: #1d4651;
      cursor: pointer;
      display: grid;
      place-content: center;
      color: #fff;
      font-family: Leon;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.439rem;
      margin-top: 3rem;
    }
    .sign-up {
      margin-top: 48px;
      color: #1d4651;
      font-family: Leon;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.439rem; /* 115.121% */
      strong {
        border-bottom: 1px solid #1d4651;
        cursor: pointer;
        color: #1d4651;
        font-family: Leon;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.439rem;
        margin-left: 0.5rem;
      }
    }

    .foot {
      color: #1d4651;
      font-family: ABeeZee;
      font-size: 16px;
      font-style: italic;
      font-weight: 400;
      line-height: 23.024px; /* 115.121% */
      display: flex;
      margin-top: 10px;
      align-items: center;
      img {
        height: 30px;
        width: 30px;
        margin-left: 10px;
      }
    }
  }
}
</style>
