<template>
  <el-dialog v-model="loginModelStore.typeVisible" :center="true" :show-close="false" modal-class="dialog-login-type">
    <div class="modal-wrap">
      <div class="colose-btn" @click="loginModelStore.toogleVisibleType()">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
          <g clip-path="url(#clip0_5657_1126)">
            <path d="M1.70191 21.9993L22 1.70117L20.2988 -9.77705e-06L0.000730057 20.2981L1.70191 21.9993Z"
              fill="white" />
            <path d="M20.2949 22L21.9961 20.2988L1.69801 0.000739834L-0.0031767 1.70192L20.2949 22Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_5657_1126">
              <rect width="22" height="22" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="title">Select User Type</div>
      <div class="row">
        <div class="col" :class="{ active: loginModelStore.type === 'register' }"
          @click="loginModelStore.choosType('register')">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
              <path
                d="M45.7389 49.8409V49.8309C44.2245 49.1891 42.9325 48.1158 42.0238 46.7448C41.1151 45.3738 40.63 43.7657 40.6289 42.1209C40.6287 40.4533 41.1267 38.8237 42.0589 37.4409V37.4309C42.3088 37.0433 42.4183 36.5818 42.3691 36.1232C42.32 35.6647 42.1152 35.2368 41.7889 34.9109C41.2742 34.4025 40.73 33.925 40.1589 33.481C38.8776 32.4758 37.4602 31.6576 35.9489 31.0509C37.4086 29.8927 38.5877 28.4196 39.3981 26.7417C40.2085 25.0637 40.6292 23.2244 40.6289 21.361C40.6236 18.0792 39.3176 14.9333 36.997 12.6128C34.6765 10.2922 31.5307 8.98625 28.2489 8.98096C24.9671 8.98625 21.8213 10.2922 19.5007 12.6128C17.1802 14.9333 15.8742 18.0792 15.8689 21.361C15.8681 23.2254 16.2897 25.0659 17.102 26.7441C17.9143 28.4224 19.0962 29.8948 20.5589 31.0509C17.2578 32.3923 14.4313 34.6869 12.4401 37.6418C10.4488 40.5966 9.38306 44.0778 9.37891 47.641V52.5009C9.38049 53.0309 9.5917 53.5387 9.96643 53.9134C10.3412 54.2882 10.849 54.4994 11.3789 54.5009H45.1089C45.5003 54.5036 45.8836 54.3886 46.2089 54.171C46.4855 53.9888 46.7124 53.7409 46.8695 53.4494C47.0266 53.158 47.1088 52.8321 47.1089 52.5009V51.7409C47.1093 51.3204 46.9771 50.9105 46.7311 50.5694C46.4852 50.2283 46.138 49.9734 45.7389 49.8409Z"
                :fill="loginModelStore.type === 'register' ? '#CDD8DB' : '#073B4C'"
                :fill-opacity="loginModelStore.type === 'register' ? 1 : 0.2" />
              <path
                d="M52.9927 40.119H51.0037V38.127C51.0037 37.5965 50.793 37.0878 50.4179 36.7127C50.0428 36.3377 49.5342 36.127 49.0037 36.127C48.4733 36.127 47.9646 36.3377 47.5895 36.7127C47.2144 37.0878 47.0037 37.5965 47.0037 38.127V40.1159H45.0117C44.4813 40.1159 43.9725 40.3266 43.5975 40.7017C43.2224 41.0768 43.0117 41.5855 43.0117 42.1159C43.0117 42.6464 43.2224 43.1551 43.5975 43.5302C43.9725 43.9052 44.4813 44.1159 45.0117 44.1159H46.9997V46.1039C46.9997 46.6344 47.2104 47.1431 47.5855 47.5182C47.9606 47.8932 48.4693 48.1039 48.9997 48.1039C49.5301 48.1039 50.0389 47.8932 50.4139 47.5182C50.789 47.1431 50.9997 46.6344 50.9997 46.1039V44.1159H52.9887C53.5191 44.1159 54.0278 43.9052 54.4029 43.5302C54.778 43.1551 54.9887 42.6464 54.9887 42.1159C54.9879 41.5867 54.7774 41.0794 54.4033 40.7051C54.0291 40.3308 53.522 40.12 52.9927 40.119Z"
                :fill="loginModelStore.type === 'register' ? 'white' : '#073B4C'" />
            </svg>
          </div>
          <div class="name">New User</div>
        </div>
        <div class="col" :class="{ active: loginModelStore.type === 'login' }"
          @click="loginModelStore.choosType('login')">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
              <path
                d="M54.4188 38.321C54.233 38.135 54.0125 37.9875 53.7697 37.8868C53.5269 37.7862 53.2666 37.7344 53.0038 37.7344C52.741 37.7344 52.4807 37.7862 52.2379 37.8868C51.9951 37.9875 51.7746 38.135 51.5888 38.321L47.6688 42.2409L46.4188 40.981C46.2324 40.7957 46.0113 40.6489 45.7682 40.5491C45.525 40.4493 45.2646 40.3985 45.0017 40.3994C44.7389 40.4003 44.4788 40.453 44.2364 40.5545C43.9939 40.656 43.7739 40.8043 43.5888 40.9909C43.4034 41.176 43.2563 41.3957 43.1559 41.6377C43.0556 41.8796 43.0039 42.139 43.0039 42.4009C43.0039 42.6629 43.0556 42.9222 43.1559 43.1642C43.2563 43.4061 43.4034 43.6259 43.5888 43.8109L45.2988 45.5209C45.61 45.8321 45.9796 46.0788 46.3862 46.2469C46.7929 46.4151 47.2287 46.5014 47.6688 46.5009C48.1089 46.5014 48.5447 46.4151 48.9514 46.2469C49.3581 46.0788 49.7276 45.8321 50.0388 45.5209L54.4188 41.1509C54.6047 40.9652 54.7523 40.7446 54.8529 40.5018C54.9536 40.259 55.0054 39.9988 55.0054 39.736C55.0054 39.4731 54.9536 39.2129 54.8529 38.9701C54.7523 38.7273 54.6047 38.5067 54.4188 38.321Z"
                :fill="loginModelStore.type === 'login' ? '#FFFFFF' : '#073B4C'
                  " />
              <path
                d="M45.7389 49.8412V49.8312C44.2245 49.1894 42.9325 48.1161 42.0238 46.7451C41.1151 45.3741 40.63 43.766 40.6289 42.1212C40.6287 40.4536 41.1267 38.8239 42.0589 37.4412V37.4312C42.3088 37.0436 42.4183 36.582 42.3691 36.1235C42.32 35.6649 42.1152 35.2371 41.7889 34.9112C41.2743 34.4028 40.73 33.9253 40.1589 33.4812C38.8776 32.4761 37.4602 31.6579 35.9489 31.0512C37.4086 29.8929 38.5877 28.4199 39.3981 26.7419C40.2085 25.064 40.6292 23.2246 40.6289 21.3612C40.6236 18.0795 39.3176 14.9336 36.997 12.613C34.6765 10.2925 31.5307 8.98649 28.2489 8.9812C24.9671 8.98649 21.8213 10.2925 19.5008 12.613C17.1802 14.9336 15.8742 18.0795 15.8689 21.3612C15.8681 23.2257 16.2897 25.0661 17.102 26.7444C17.9143 28.4226 19.0962 29.895 20.5589 31.0512C17.2578 32.3926 14.4313 34.6871 12.4401 37.642C10.4488 40.5969 9.38306 44.078 9.37891 47.6412V52.5012C9.38049 53.0311 9.5917 53.5389 9.96643 53.9137C10.3412 54.2884 10.849 54.4996 11.3789 54.5012H45.1089C45.5003 54.5038 45.8835 54.3888 46.2089 54.1712C46.4854 53.9891 46.7124 53.7412 46.8695 53.4497C47.0266 53.1582 47.1088 52.8323 47.1089 52.5012V51.7412C47.1093 51.3207 46.9771 50.9107 46.7312 50.5696C46.4852 50.2285 46.138 49.9736 45.7389 49.8412Z"
                :fill="loginModelStore.type === 'login' ? '#CDD8DB' : '#CDD8DB'
                  " />
            </svg>
          </div>
          <div class="name">Existing User</div>
        </div>
      </div>
      <div class="btn" v-if="!!loginModelStore.type" @click="onNext()">
        Next
      </div>
    </div>
  </el-dialog>
</template>
<script setup lang="ts">
import { useRouter } from "vue-router";
import { useLoginModelStore } from "pages/home/store/newLanding";
const loginModelStore = useLoginModelStore();
const router = useRouter();
function onNext() {
  router.push(`/login?type=${loginModelStore.type}&redict_url=/subscribe?from=design`)
  loginModelStore.toogleVisibleType()
}
</script>
<style lang="less">
.dialog-login-type {

  // background-color: transparent;
  .el-dialog {
    background-color: transparent;
    box-shadow: none;
    width: 100%;
  }

  .colose-btn {
    position: absolute;
    right: 0;
    top: -1.13rem;
    cursor: pointer;
    transform: translateY(-100%);
  }

  .modal-wrap {
    width: 780px;
    max-width: 100%;
    margin: auto;
    border-radius: 20px;
    background: #f4f4f4;
    padding: 68px 86px 147px 68px;
    box-sizing: border-box;
    position: relative;

    @media screen and (max-width: @phone-landscape) {
      padding: 24px;
      // padding-bottom: 9.19rem;
      padding-bottom: 86px;
      gap: 12px;
      box-sizing: border-box;
    }

    .btn {
      position: absolute;
      bottom: 47px;
      box-sizing: border-box;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      display: flex;
      width: 180px;
      height: 55px;
      padding: 14px 56px;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      flex-shrink: 0;
      border-radius: 3.125rem;
      border: 1px solid #e75465;
      background: #e75465;
      color: #fff;
      font-family: Leon;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media screen and (max-width: @phone-landscape) {
        bottom:12px;
      }
    }
  }

  .title {
    color: #1D4651;
    font-family: Leon;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.024px;
    text-align: center;
  }

  .row {
    margin-top: 50px;
    display: flex;
    gap:79px;

    @media screen and (max-width: @phone-landscape) {
      gap:12px;
      box-sizing: border-box;
    }

    .col {
      display: flex;
      // width: 16.5rem;
      flex: 1;
      padding: 50px 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      border-radius: 0.625rem;
      background: #fff;
      cursor: pointer;
      color: #1D4651;
      font-family: Leon;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 23.024px;
      text-align: center;
      @media screen and (max-width: @phone-landscape) {
        flex: 1;
        padding: 24px;
      }

      &:hover {
        box-shadow: 0px 4px 38px 0px rgba(0, 0, 0, 0.1);
      }

      &.active {
        color: #fff;
        background: #073b4c;
      }
    }
  }
}
</style>
