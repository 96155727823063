import request from '@/utils/request'
const formatParam = function (params:any) {
    return {
        params: params
    }
};
export const requestGetRecord = (id:string) => (request as any).get('/aiGenerateRecord/getRecord',formatParam({id}))
export const requestPublishPage = (params) => (request as any).get('/aiGenerateRecord/publishPage',formatParam(params))
export const requestGetEditRecord = (params => (request as any).get('/aiGenerateRecord/getEditRecord',formatParam(params)))
export const requestGetUpScaleUrl = (params => (request as any).get('/aiGenerateRecord/getUpScaleUrl',formatParam(params)))
export const requestQueryLineUp = (params) => (request as any).get('/aiGenerateRecord/queryLineUp',formatParam(params))
export const requestSelectImage = (params) => (request as any).post('/aiGenerateRecord/selectImage', params)
export const requestGenerateImage = (params) => (request as any).post('/aiGenerateRecord/generateImage', params)
export const requestSaveMagicEraser = (params) => (request as any).post('/aiGenerateRecord/saveMagicEraser', params)
export const requestUseReference = (params) => (request as any).post('/aiGenerateRecord/manage/useReference', params)
export const requestListKitchenStyles = () => (request as any).get('/common/listKitchenStyles')
export const cabinetComment = (params) => (request as any).post('/companyCooperation/cabinetComment',params)
export const requestFindUrls = (params) => (request as any).post('/fastGenerateRecord/findUrls',params)
export const requestSelectImageForFastMultiple = (params) => (request as any).post('/aiGenerateRecord/manage/selectImageForFastMultiple ',params)
export const requestRefine = (params) => (request as any).post('/fastGenerateRecord/refine ',params)
export const requestListSketchCategory = () => (request as any).get('/common/listSketchCategory')
export const requestListSketchImages = (params) => (request as any).post('/common/listSketchImages',params)
// export const requestGenerateEdit = (params) => (request as any).upload('/fastGenerateRecord/manage/generateEdit',params)
export const requestGenerateEdit = (params) => (request as any).upload('/fastGenerateRecord/manage/generateEdit',params)
export const requestMagicBrush = (params) => (request as any).upload('/fastGenerateRecord/manage/magicBrush',params)
export const requestRunPredict = (params) => (request as any).runPredict(params)
export const requestFastUpscale  = (params) => (request as any).upload('/fastGenerateRecord/manage/fastUpscale',params)
export const requestRefineUrl  = (params) => (request as any).upload('/fastGenerateRecord/refineUrl',params)
export const selectImageForRealtime  = (params) => (request as any).upload('/aiGenerateRecord/manage/selectImageForRealtime',params)
export const requestListPosition = (params) => (request as any).get('/common/listPosition', formatParam(params))
export const requestGenerateMaskUrls = (params) => (request as any).post('/aiGenerateRecord/generateMaskUrls', params)
export const requestGetMaskUrls = (params) => (request as any).get('/aiGenerateRecord/getMaskUrls', formatParam(params));
export const requestListFurnitureForNewAi = (params) => (request as any).post('/case/listFurnitureForNewAi', params)
export const requestListEditImages = (id) => (request as any).post('/aiGenerateRecord/listEditImages', {id})
export const requestSearchComment = (params) => (request as any).get('/designModel/searchComment', formatParam(params))
export const requestSaveComment = (params) => (request as any).post('/designModel/manage/saveComment', params)
export const requestGetMember = () => (request as any).get('/clientMember/getMember')
export const requestSaveModel = (params) => (request as any).post('/designModel/manage/save', params);
export const requestTrain = (params) => (request as any).post('/designModel/manage/train', params);
export const requestPublish = (params) => (request as any).post('/designModel/manage/publish', params);
export const requestGetTrainRecord = (params) => (request as any).get('/designModel/manage/getTrainRecord', formatParam(params));
export const getStylequizeListLabel = (params) => (request as any).get('/stylequize/listLabel', formatParam(params));
export const stylequizeNextStep = (params) => (request as any).post('/stylequize/nextStep', params);
export const getStylequizeListCase = (params) => (request as any).post('/stylequize/listCase', params);
export const getStylequizeResult = (params) => (request as any).post('/stylequize/statisticsImage', params);

export const searchMineAiGenerateRecord = (params) => (request as any).get('/record/searchMineAiGenerateRecord', formatParam(params));
export const generateImage = (params) => (request as any).post('/record/generateImage', params);
export const getRecord = (params) => (request as any).get('/record/getRecord', formatParam(params));
export const generateMaskUrls = (params) => (request as any).post('/record/generateMaskUrls', params);
export const getMaskUrls = (params) => (request as any).get('/record/getMaskUrls', formatParam(params));
export const listPosition = (params) => (request as any).get('/record/listPosition', formatParam(params));
export const kitchenModelPage = (params) => (request as any).get('/designModel/page', formatParam(params));



export const getChatListRooms = (params) => (request as any).get('/chatDesigner/listRooms', formatParam(params));
export const deleteChatRoom = (params) => (request as any).post('/chatDesigner/deleteRoom', params);
export const updateChatRoom = (params) => (request as any).post('/chatDesigner/updateRoom', params);
export const saveChat = (params) => (request as any).post('/chatDesigner/saveChat', params);
export const getChatDetail = (params) => (request as any).get('/chatDesigner/getChatDetail', formatParam(params));
export const getChatHistory = (params) => (request as any).get('/chatDesigner/search', formatParam(params));

export const getSimilarItems = (params) => (request as any).post('/record/moreLikeFurniture', params);
