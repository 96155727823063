import {message} from "../modules/message";
import {description} from "../modules/description";
import {menu} from "../modules/menu";
import {title} from "../modules/title";
import {form} from "../modules/form";
import {designForm} from "../modules/designForm";
import {btn} from "../modules/btn";
import {upgrade} from "../modules/upgrade";
import {pricing} from "../modules/pricing";
import {priceList} from "@/dictionary/priceList";
import {enterprise} from "../modules/enterprise";
import {confirm} from "../modules/confirm";
import {modal} from "../modules/modal";
import {client} from "../modules/client";
import {member} from "../modules/member";
import {plan} from "../modules/plan";
import {contact} from "../modules/contact";

export const zhLang={
    title,
    form,
    designForm,
    btn,
    menu,
    member,
    message,
    description,
    upgrade,
    pricing,
    enterprise,
    confirm,
    modal,
    client,
    plan,
    contact,
    priceList:JSON.stringify(priceList),

}
