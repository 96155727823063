export const account = {
  profileInformation: 'Profile Information',
  passwordUpdate: 'Password Update',
  emailAddress: 'Email address',
  password: 'Password',
  paymentMethod: 'Payment Method',
  phoneNumber: 'Phone Number',
  currentPassword: 'Current Password',
  newPassword: 'New Password',
  yourPassword: 'Your Password',
  btn: {
    edit: 'Edit',
    add: 'Add',
    hide: 'Hide',
    show: 'Show',
    cancel: 'Cancel',
    save: 'Save',
  },
} 