export const description={
    redesign:'Home redesign offers AI-driven room redesigns from your photos.',
    fastPreserved:'Instant home redesign offers AI-driven room redesigns from your photos.',
    partial:'Select and revamp a room section with our AI-assisted partial remodel tool.',
    kitchenRemodeling:'Upload your kitchen image and choose a cabinet style; our AI instantly creates a personalized, futuristic design.',
    realTimeDesign:'AI-powered interior design tool: Adjust settings on the left, see the instant transformation on the right in a side-by-side comparison.',
    eraser:'Erase room elements effortlessly with our AI magic eraser tool.',
    ChatDesigner:'Generate artistic design inspirations through AI-powered conversational chat.',
    ProductVideoStoryboard:'Product StoryBoard Director, here to transform your product script ideas into captivating visual keyframes!',
    GenVideo:'Create and explore interior design concepts with AI-driven text-to-video generation.',
    TETX_2_IMG:'Envision and refine room designs instantly with text-to-image AI technology.',
    MIDJOURNEY_V6:'Envision and refine room designs instantly with text-to-image AI technology.',
    FLOORPLAN_GPT:'This unique feature allows for the visualization of rooms based on the provided floor plan.',
    DRAFT_IMAGE:'Draft image: After the room is visualized, users have the flexibility to make any modifications to this visualized space.',
    editing:'Customize your room\'s furnishings with our AI-powered furniture editing suite.',
}
