import App from './App.vue'
import router from './router'
import '@/utils/rem'
import '@/assets/styles/index.less'
import '@/assets/styles/common.less'
import '@/assets/styles/dynamic.less'
import 'element-plus/theme-chalk/dark/css-vars.css'
import 'element-plus/es/components/message/style/css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'

import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css';
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
/*svg 插件需要配置代码*/
import 'virtual:svg-icons-register'
/*全局组件配置*/
import gloalComponent from '@/components/index'
import {lazyPlugin} from "@/hooks/lazyLoad";
const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
/*国际化配置*/
import {Lang} from '@/I18N/en/studio/index'
import {zhLang} from '@/I18N/zh/studio/index'
const numberFormats = {
    'en': {
        currency: {
            style: 'currency',
            currency: 'USD'
        }
    },
    'zh': {
        currency: {
            style: 'currency',
            currency: 'JPY'
        }
    }
}
const locale= import.meta.env.VITE_APP_LANG
const messages={}
/*国际化配置*/
if(locale==='zh'){
    messages.zh=zhLang
}else {
    messages.en=Lang
}
const i18n = createI18n({
    locale: import.meta.env.VITE_APP_LANG==='zh'?'zh':'en',
    numberFormats,
    legacy:false,
    globalInjection: true,
    messages
})
router.beforeEach((to, from, next) => {
    next()
})
app.use(VueAwesomeSwiper)
app.use(pinia)
app.use(router)
app.use(gloalComponent)
app.use(lazyPlugin)
app.use(i18n as any)
// app.use(ElementPlus)
app.mount('#app')
