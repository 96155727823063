<template>
  <div class="c-button" @click="toClick"
       :style="{'--icon-color':color}"
       :class="[type||'default',size||'',
       {'round':round},{'block':block},
       {'square':square},{'disabled':disabled},className]">
    <div class="flex-center">
      <div class="icon-spin" v-if="loading">
        <svg-type name="loading" :color="color" :bg="false" width="14px" height="14px"></svg-type>
      </div>
      <slot></slot>
      <span style="margin-left: 8px;" v-if="!!rightIcon">
        <img src="@/assets/icons/share.svg" alt="" v-if="rightIcon==='share'">
        <img src="@/assets/icons/refresh.svg" alt="" v-else-if="rightIcon==='refresh'">
        <svg-type name="right"  v-else></svg-type>
      </span>
    </div>
  </div>
</template>
<script setup lang="ts">
import {defineEmits, defineProps} from "vue";
type Type = 'primary'
interface Props {
  type?: Type
  icon?: string
  size?: Type
  color?: string
  round?: boolean
  block?: boolean
  square?: boolean
  loading?: boolean
  disabled?: boolean
  rightIcon?: string
  className?: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'click'): void
  (e: 'toClick'): void
}>()
const toClick = () => {
  if (props.loading || props.disabled || props.type==='disabled') {
    return
  }
  emit('click')
  emit('toClick')

}
</script>
<style scoped lang="less">
.c-button {
  color: @color-font;
  padding: 10px 20px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  user-select: none;

  &.block {
    width: 100%;
    .flex-center();
  }

  .icon-spin {
    margin-right: 8px;
    position: relative;
    top: 2px;
  }

  &.disabled {
    background: #f1f2f2;
    border-color: #f1f2f2;
    color: #aaaeb2;
    cursor: not-allowed;
  }

  &.round {
    border-radius: 16px;
  }
  &.large {
    padding: 20px;
  }

  &.square {
    border-radius: 4px;
  }

  &.shadow {
    box-shadow: 0px 5px 3px 0px rgba(68, 72, 76, 0.08), 0px 3px 2px 0px rgba(68, 72, 76, 0.04);
  }

  &.default {
    border: 1px solid #C6C9CC;
    .font(14px, 700, @color-font);
    letter-spacing: 0.5px;
  }
  &.whilte{
    background: #F1F2F2;
    .font(14px, 700, #44484C);
    cursor: pointer;
    border-color: #F1F2F2;
    letter-spacing: 0.5px;
  }
  &.confirm {
    background: #806B5A;
    box-shadow: 0px 3px 2px rgba(68, 72, 76, 0.04), 0px 5px 3px rgba(68, 72, 76, 0.08);
    cursor: pointer;
    border-color: #806B5A;
    .font(14px, 700, #ffffff);
    letter-spacing: 0.5px;
  }
  &.transparent {
    background: transparent;
    cursor: pointer;
    border: 1px solid  #F1F2F2;
    .font(14px, 700, #ffffff);
    letter-spacing: 0.5px;
  }
  &.primary {
    .font(14px, 700, #FFF);
    letter-spacing: 0.5px;
    background: @color-primary;
  }
  &.design {
    letter-spacing: 0.5px;
    background: #44484C;
    color: #ffffff;
    font-weight: 700;
  }
  &.order {
    letter-spacing: 0.5px;
    background: #E75465;
    color: #ffffff;
    font-weight: 700;
  }
  &.edit {
    border-radius: 12px;
    background: var(--sonic-silver-20, #E3E4E5);
  }
  &.small{
    padding: 6px 16px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 600;
  }
  &.error {
    background: #8f3400;
    border-color: #8f3400;
    -webkit-box-shadow: 0 3px 2px rgba(68,72,76,.04), 0 5px 3px rgba(68,72,76,.08);
    box-shadow: 0 3px 2px rgba(68,72,76,.04), 0 5px 3px rgba(68,72,76,.08);
    color: #fff;
    cursor: pointer;
  }
  &.wx {
    background: #58be6a;
    box-shadow: 0px 3px 2px rgba(68, 72, 76, 0.04), 0px 5px 3px rgba(68, 72, 76, 0.08);
    color: #ffffff;
    cursor: pointer;
    border-color: #58be6a;
  }
  &.border {
    border: 0.3px solid rgba(143, 147, 156, 0.2);
  }
}
</style>
