import request from '@/utils/request'
const formatParam = function (params:any) {
    return {
        params: params
    }
};
import type {LoginParams,GetLoginDataParams} from "@/apis/client/type";
export const requestLogin = (params:LoginParams) => (request as any).post('/client/login',params)
export const requestLoginByPhone = (params:LoginParams) => (request as any).post('/client/loginByPhone',params)
export const requestGetValidCode = (params:LoginParams) => (request as any).post('/client/getValidCode',params)
export const requestLoginByPhoneValid = (params:LoginParams) => (request as any).post('/client/loginByPhoneValid',params)
export const requestRegisterByMobile = (params:LoginParams) => (request as any).post('/client/registerByMobile',params)
export const createOrderForAlipay = (params) => (request as any).post('/clientMember/manage/createOrderForAlipay',params)
export const requestGetStatus = (parmas) => (request as any).get('/clientMember/manage/getStatus',formatParam(parmas))
export const createOrderForWx = (params) => (request as any).post('/clientMember/manage/createOrderForWx',params)
export const requestRegister = (params:LoginParams) => (request as any).post('/client/register',params)
export const requestUserInfo = () => (request as any).get('/client/manage/get')
export const hasLogin = () => (request as any).get('/client/hasLogin')
export const requestWeChatLogin = (params) => (request as any).post('/client/weChatLogin',params)
export const requestGetInfo = (params)=> (request as any).get('/client/getInfo', formatParam(params))
export const requestGetLoginData = (parmas:GetLoginDataParams) => (request as any).get('/client/getLoginData',formatParam(parmas))
export const requestCheckOauthLogin = (authId:string) => (request as any).get('/client/checkOauthLogin',formatParam({authId: authId}))
export const requestLogout = () => (request as any).post('/client/logout')
export const requestListCards = () => (request as any).get('/payment/manage/listCards')
export const requestGetPlan = () => (request as any).get('/payment/manage/getPlan')
export const requestSaveCards = (params) => (request as any).post('/payment/manage/save', params)
export const requestCreateOrder = (params) => (request as any).post('/clientMember/createOrder', params);
export const requestConversions = (params) => (request as any).postByJson('/impact/conversions', {params})
export const requestUpdateClient = (params) => (request as any).post('/client/manage/update', params)
export const requestReduceOrder = (params) => (request as any).post('/clientMember/reduceOrder', params)
export const requestCreateUpgradeOrder = (params) => (request as any).post('/clientMember/createUpgradeOrder', params)
export const requestUpdateSubscriptionCard = (params) => (request as any).post('/payment/manage/updateSubscriptionCard', params)
export const requestFeedBack = (params) => (request as any).post('/companyCooperation/feedBack', params);
