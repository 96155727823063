export const modal = {
    delete:{
        title:'Delete',
        content:'Are you sure you want to delete?',
        generation:'The design generation failed. Do you need to regenerate it?',
        okText:'Delete',
        cancelText:'Cancel',
    },
    unsubscribe:{
        title:'Unsubscribe the plan',
        content:'Are you sure you want to Unsubscribe the plan?',
        okText:'Sure',
        cancelText:'Cancel',
    },
    deleteAccount:{
        title:'Delete Account',
        content:'Are you sure you want to delete this account?',
        okText:'Sure',
        cancelText:'Cancel',
        name:'Delete my account',
        text:'Are you sure you want to delete your account? This will permanently erase your account.',
    },
    logout:{
        title:'Logout',
        content:'Are you sure you want to sign out?',
        okText:'Sure',
        cancelText:'Cancel',
    },
    quit:{
        content:'Are you sure you want to quit?',
        saved:'Your design has been saved',
        okText: 'Sure',
        cancelText: 'Cancel',
    },
    duplicate:{
        content:'Are you sure you want to duplicate this?',
        okText: 'Duplicate',
        cancelText: 'Cancel',
    },
    action:{
        content:'Are you sure you want to go back to the previous step',
        okText: 'Sure',
        cancelText: 'Cancel',
    },
    create:{
        title:'What do you want to create?',
        Assistant:'Assistant',
        AssistantText:'An AI character is a digital persona powered by artificial intelligence, capable of simulating conversation, learning from interactions, and providing responses based on its programming and accumulated knowledge. It represents the convergence of technology and personification.',
        videoGeneration:'Short Video',
        videoGenerationText:'This tool is an innovative video generation software that can create a 2-second video clip based on your description. Simply input your ideas, concepts, or scenarios, and the tool uses advanced AI algorithms to interpret and translate your words into a brief, dynamic video.',
        Interiorgen:'Image',
        InteriorgenText:'AI interiorGen is a cutting-edge technology that allows users to generate images from textual descriptions through a conversational interface. By providing detailed prompts to an AI, users can see their words translated into visual art instantaneously. This synthesis of natural language processing and generative art opens up creative possibilities for both casual users and professionals alike.',
    },
    contact:{
        title:`Provide Your Feedback:<br/>
Report Bugs and Request Features to Help Improve Our System`,
        feedbackThank:'Thank you for taking the time to provide your feedback!',
        SubmitFeedback:'Submit Feedback',
        customerFeedback:'Customer Feedback',
        featureRequest:'Feature Request',
        message:'Write your message...',
        messageInput:'Please input your message.',
        email:'E-mail',
        emailMessage:'Please input your E-mail.',
        name:'Your Name',
        nameMessage:'Please input your name.',
        selectMessage:'Please select',

    }
}
