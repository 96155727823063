<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-container">
        <div class="close cursor" @click="toClose">
          <img src="@/assets/icons/close.svg" alt="">
        </div>
        <svg-type name="loading" ></svg-type>
        <div class="loading-text" v-if="text"> {{ text || '' }}</div>
      </div>
    </div>
  </Transition>
</template>
<script setup lang="ts">
import SvgType from "@/components/common/SvgType.vue";
import {defineProps, onMounted, ref} from "vue";
let show = ref<boolean>(false)
const props =defineProps<{
  text:string
}>()
const toClose=()=>{
  show.value=false
}
onMounted(()=>{
  show.value=true
})
</script>
<style scoped lang="less">
.modal-container {
  width: 417px;
  padding: 74px 52px 74px 52px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 10px;
  transition: all 0.3s ease;
  margin: auto;
  position: relative;
  .close{
    position: absolute;
    top: 24px;
    right: 24px;
  }
  .icon-spin{
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .loading-text {
    color:#71787F;
    font-family: ProximaNova;
    font-size: 16px;
    font-style: normal;
    line-height: 22px; /* 137.5% */
    letter-spacing: 0.24px;
    text-align: center;
    margin-top: 32px;
  }
}

</style>
