export const design = {
  btn: {
    next: 'Next',
    viewPhotos: 'View Selected Photos',
    done: 'Done',
    erase: 'Erase',
    selectThisImage: 'Select this image',
    placeOnMoodBoard: 'Place on Mood Board',
    newChat: 'New Chat',
    chatbotDefinitionExplanation: 'Chatbot definition explanation',
  },
  before: 'Before',
  after: 'After',
  brushSize: 'Brush size',
  clear: 'clear',
  edit: 'Edit',
  filter: 'Filter',
  category: 'Category',
  aiExperimentTools: {        
    title: 'AI Experiment Tools',
    titleTextBtn: 'My Design History',
    btnStart: 'Get Started',
    list: [
      {
        title: 'AI Design',
        desc: `Utilizes advanced technology to create personalized, efficient living spaces. Integrates smart features, automated systems, and data-driven aesthetics for innovative, user-centric environments.`,
      },
      {
        title: 'Style Quiz',
        desc: `Delve into a world where intelligent algorithms tailor your aesthetic journey, crafting spaces that echo your unique style and vision.`,
      },
      {
        title: 'Kitchen Remodeling',
        desc: `Transforming kitchens, the heart of every home, into a canvas of elegance and innovation, seamlessly reimagined in an instant.`,
      },
      {
        title: 'Mood Board',
        desc: `Weaves a tapestry of textures, colors, and inspirations, crafting a visual story that echoes your vision, guiding the journey from concept to reality.`,
      },
      {
        title: 'Chat Designer',
        desc: `Utilizes advanced technology to create personalized, efficient living spaces. Integrates smart features, automated systems, and data-driven aesthetics for innovative, user-centric environments.`,
      },
      {
        title: 'Outdoor Design',
        desc: `Orchestrating natural tranquility with AI ingenuity, we tailor your outdoor spaces to foster harmony with the environment, ensuring every element aligns with your vision for leisure and sustainability.`,
      },
      {
        title: 'Exterior Design',
        desc: `Envisioning futuristic facades through AI precision, we craft exteriors that are both eco-conscious and aesthetically personal, integrating your style with sustainable innovation.`,
      },
    ]
  },
  aiDesign: {
    title: 'My Design History',
  },
  styleQuiz: {
    Transitional: {
      title: 'Transitional',
      desc: `You like to mix it up! Blending the old with the new allows you to create a punchy version of the traditional design. They like to call you the décor mixologist, because in your eyes, garnishing curved furnishings with straight-lined accents is the only way to drink in your newly designed space.`,
    },
    Eclectic: {
      title: 'Eclectic',
      desc: `Rich in funky detail, personalized collections, and gallery walls, your eclectic side really shines through to make your space uniquely you. Mixing textures, styles, and colors is your method for creating harmony at home.`,
    },
    MidCenturyBoho: {
      title: 'Mid-century Boho',
      desc: `If Janice Joplin became best friends with Don Draper, you'd have a vibe! Your taste for function over frills gets overturned from time to time with wandering, whimsical impulses - and why not? You're the writer of your own life story and your unique flair is never dictated by one single stylistic mindset. So let the rattan and flowing plants flow and thrive alongside the novelty of fun geometric shapes, tapered furniture legs, organic curves, and soothing, clean lines.`,
    },
  },
  homeRedesign: {
    IntelligentSpace: {
      titleSpan: 'AI Design',
      title: 'Intelligent Space',
      desc: `Intelligent algorithms orchestrate interiors, merging personalization with efficiency, creating spaces that extend beyond imagination in both form and function.`,
      choose: 'Choose from template',
      loadingTxt: 'Generating the rendering',
      similarItem: 'Similar item',
      magicEraser: 'Magic Eraser',      
    },
    actionPanel: {
      homeRedesign: 'Home Redesign',
      partialRemodel: 'Partial Remodel',
      uploadImage: 'Upload room image',
      Drag1: 'Drag and Drop or',
      Drag2: ' Browse ',
      Drag3: 'to upload',
      roomType: 'Room type',
      livingRoom: 'Living Room',
      style: 'Style',
      Halloween: 'Halloween',
      generate: 'Generate',
      areaRetouch: 'Area of retouch',
      furniture: 'Furniture',
      architectural: 'Architectural',
      selectAll: 'Select all',
      deselectAll: 'Deselect all',
      typeList: {
        cabinet: 'Cabinet',
        coffee: 'Coffee',
        ottoman: 'Ottoman',
        radiator: 'Radiator',
        rug: 'Rug',
        sofa: 'Sofa',
        television: 'Television',
        miscellaneous: 'Miscellaneous',
      }
    }
  },
  designStyle: {
    title: `Let’s find your Interior Design Style`,
  },
  styleQuizAll: {
    title: `Style Quiz: How Do You Want Your Room To Look?`,
    styleFilter: 'Style Filter',
  },
  yourStyle: {
    title: 'Your Style',
    quizNow: `Not feeling at home? Retake Quiz Now`,
  },
  kitchenRemodelingSpace: {
    title: 'Kitchen Remodeling',
    desc: `Intelligent algorithms orchestrate interiors, merging personalization with efficiency, creating spaces that extend beyond imagination in both form and function.`,
    choose: 'Choose from template',
    loadingTxt: 'Generating the rendering',
  },
  outdoorDesign: {
    title: 'Outdoor Design',
    desc: `Intelligent algorithms orchestrate interiors, merging personalization with efficiency, creating spaces that extend beyond imagination in both form and function.`,
    choose: 'Choose from template',
    loadingTxt: 'Generating the rendering',
  },
  exteriorDesign: {
    title: 'Exterior Design',
    desc: `Intelligent algorithms orchestrate interiors, merging personalization with efficiency, creating spaces that extend beyond imagination in both form and function.`,
    choose: 'Choose from template',
    loadingTxt: 'Generating the rendering',
  },
  designEdit: {
    search: 'Search',
    summary: 'Summary',
    moodboardtotal: 'Mood Board Total',
    layers: 'Layers',
    preview: 'Preview',
    guide: 'Guide',
    export: 'Export',
  },
  designArticle: {
    overview: 'Overview',
    details: 'Details',
    dimensions: 'Dimensions',
    
  }
} 