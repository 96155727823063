export const btn={
  share:'Share',
  detail:'Detail',
  back:'Back',
  comingSoon:'Coming Soon',
  publish:'Publish',
  use:'Use',
  cancel:'Cancel',
  credit:'credit',
  credits:'credits',
  done:'Done',
  Post:'Post',
  next:'Next',
  edit:'Edit',
  duplicate:'Duplicate',
  generate:'Generate',
  regenerate:'Regenerate',
  upscale:'Upscale',
  more:'More',
  comment:'Comment',
  sure:'sure',
  activity:'Activity',
  latestDesign:'Latest Design',
  upgrade:'Upgrade',
  upPro:'Upgrade to Pro',
  upload:`Drag and Drop or <span>Browse</span> to upload`,
  uploadBtn:'Upload new photo',
  help:'Help',
  signOut:'Sign Out',
  signUp:'Sign Up',
  signIn:'Sign In',
  continue:'Continue',
  subscribe:'Subscribe',
  contactSale:'Contact Sales',
  submit:'Submit',
  furniture:'Furniture',
  architectural:'Architectural',
  selectAll:'Select all',
  selected:'Selected',
  deselectAll:'Deselect all',
  create: 'Create',
  general: 'General',
  interiorDesign: 'Interior Design',
  useAsReference: 'Use as reference',
  Delete: 'Delete',
  update: 'Update',
  talk:`Talk to Us`,
  SubmitBusiness:`Submit Business Interest`,
  All:'All',
  Rendering:'Rendering',
  Video:'Video',
  Assistant:'Assistant',
  showMore:'Show More',
  viewLess:'View Less',
  viewAll: 'View All',
  ALL:'Live updates',
  ALL_VIEW:'All time leaderboard',
  try:'Try',
  tryBetter:'for better quality',
  close: 'Close',
  createYourOwnAICabinetStyle: 'Create Your Own AI Cabinet Style',
  createYourOwnWallModel:'Create Your Own Wall/Floor AI Model',
  Rename:'Rename',
  Erase:'Erase',
  clear:'Clear',
  brushSize:'Brush size',
  uploadUrl:'Upload Image',
  setBg:'Set Background',
  prompt:'Enter prompt',
  editing:'Editing',
  add:'Add',
  enterStudio:'Enter Design Studio',
  Freestyle:'Freestyle',
  // Floorplan:'Floorplan',
  Floorplan:'Floorplan & 3D',
  Architect:'Architect',
  Interior:'Interior',
  save: 'Save',
  undo: 'Undo',
  redo: 'Redo',
  enterFullScreen: 'Enter full screen',
  exitFullScreen: 'autoFit',
  EditFurniture:'Edit And Replace Furniture',
  replaceFurniture:'Replace Furniture',
  selectInputPicture:'Set as Input Picture',
  StartfromScratch: 'Start from Scratch',
  AIDetection: 'AI Detection',
  BuildWithCollovAI: 'Enterprise: Build with Collov AI',
}
