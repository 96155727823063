export const billing = {
  // Billing Information
  billing: {
    title: 'Billing information',
  },
  currentPlan: 'Current Plan',
  crewzzPro: 'Crewzz Pro',  
  perMonth: 'per month',
  perMonthTxt: 'Your Credit usage for the current period',
  credits: 'Credits',
  remaining: 'remaining',
  usageResetOn: 'usage reset on',
  billingCycle: 'Billing cycle',
  monthly: 'Monthly',
  billingPeriod: 'Billing period',
  paymentMethod: 'Payment method',
  visaEndingIn : 'Visa ending in',
  unsubscribe: 'Unsubscribe',
  unsubscribeTxt: 'Please be aware that unsubscribe will cause you to lose all of your saved content.',
  needHelp: 'Need help?',
  needHelpTxt: 'If you need any further help with billing, our support team are here to help.',
  deleteMyAccount: 'Delete my account',
  deleteMyAccountTxt: 'Are you sure you want to delete your account? This will permanently erase your account.',  
  // Credit History
  credit: {
    title: 'Credit History',
  },
  btn: {
    upgrade: 'Upgrade',
    unsubscribe: 'Unsubscribe',
    contactSupport: 'Contact Support',
    delete: 'Delete',
    changePaymentMethod: 'Change payment method',
  },
  table: {
    billingHistory: 'Billing History',
    creditHistory: 'Credit History',
    invoiceID: 'INVOICE ID',
    service: 'SERVICE',
    date: 'DATE',
    cost: 'COST',
    remaining: 'REMAINING',
  },
} 