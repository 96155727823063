import request from '@/utils/request'
import type { imagebase64Form } from './types'

enum API {
  INITDATA_URL = '/common/initData',
  GETIMAGEBASE64_URL = '/common/getImageBase64'
}

const formatParam = function (params: any) {
  return {
    params: params
  }
}

export const initData = () => request.get(API.INITDATA_URL)
export const getImageBase64 = (data: imagebase64Form) => request.get(API.GETIMAGEBASE64_URL, { params: data })
export const requestUploadBase64 = params => request.post('/file/uploadBase64', params)
export const requestUpload = params => (request as any).upload('/file/upload', params)
export const requestRemoveBg = params => (request as any).post('/removeBg/manage/create', params)
export const requestSvg2png = svgText => (request as any).post('/file/uploadImgSvg2Png', { svgText })

// /gpt/api
export const login = params => request.post('/login', params)
export const oauthRender = params => request.get('/restAuth/render', formatParam(params))
export const checkLogin = params => request.get('/restAuth/checkLogin',  formatParam(params))
export const sendCode = params => request.post('/noauth/sendCode', params)
export const checkCode = params => request.post('/noauth/checkCode', params)
export const updatePwd = params => request.post('/noauth/updatePwd', params)
export const resetPwd = params => request.post('/hasauth/resetPwd', params)
export const getUserInfo = () => request.get('/api/user/getUserInfo')
export const updateUser = (params) => request.post('/api/user/updateUser',params)
export const register = params => (request as any).post('/register', params)
export const logout = params => (request as any).post('/logout')
export const getRecord = params => (request as any).get('/record/getRecord', formatParam(params))
export const generateImage = params => (request as any).post('/record/generateImage', params)
export const generateImage1 = params => (request as any).post('flair/web/aiGenerateRecord/generateImage', params)
export const searchMineAiGenerateRecord = params => (request as any).get('/record/searchMineAiGenerateRecord', formatParam(params))
