import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'
import { login as loginApi, register as registerApi, logout } from '@/apis/common'
import {
  generateImage,
  requestSelectImage,
  getRecord,
  // requestMagicBrush, realtime 魔术擦接口
} from "@/apis/design/newIndex";
import { usePolling } from "@/hooks/util";
import { resCode } from "@/utils/constant";
import { useRouter } from 'vue-router';
export const useLoginModelStore = defineStore('login-modal', () => {
  const show = ref(false)
  const forget = ref(false)
  const type = ref('')
  const reset = ref(false)
  const typeVisible = ref(false)
  const auth = reactive({
    token: '',
    userInfo: null as unknown as Auth.UserInfo
  })
  const choosType = (val: string) => {
    type.value = val
  }
  const toogleVisibleType = () => {
    typeVisible.value = !typeVisible.value
    choosType('')
  }
  const toggle = () => {
    show.value = !show.value
    forget.value = false
    reset.value = false
  }
  const toReset = () => {
    forget.value = false
    reset.value = true
  }
  const toLogin = () => {
    forget.value = false
    reset.value = false
  }
  const toggleForget = () => {
    forget.value = !forget.value
  }
  const login = async (params: any) => {
    const res = (await loginApi(params).catch(console.log)) as Record<any, any>
    console.log('res: ', res)
    if (Reflect.has(res, 'data')) {
      auth.token = res.data.token
      auth.userInfo = res.data.userInfo
      toggle()
      return res.data
    }
    return false
  }

  return { show, forget, toggleForget, reset, toggle, login, choosType, type, typeVisible, toogleVisibleType, auth, toReset, toLogin }
})

export const useRegisterModelStore = defineStore('register-modal', () => {
  const show = ref(false)
  const auth = reactive({
    token: '',
    userInfo: null as unknown as Auth.UserInfo
  })

  const toggle = () => {
    show.value = !show.value
  }

  const register = async (params: any) => {
    const res = (await registerApi(params).catch(console.log)) as Record<any, any>
    console.log('res: ', res)
    if (Reflect.has(res, 'data')) {
      auth.token = res.data.token
      auth.userInfo = res.data.userInfo
      toggle()
      return res.data
    }
    return false
  }

  return { show, toggle, register, auth }
})

export const useTipsModelStore = defineStore('user-tips', () => {
  const show = ref(false)

  const toggle = () => {
    show.value = !show.value
  }
  return { show, toggle }
})


export const useDesignModelStore = defineStore('design-model', () => {
  const uploadImage = ref('')
  const loading = ref(false)
  const generateRecordID = ref('')
  const setUploadImage = (url: string) => {
    uploadImage.value = url
  }
  let scalepolling=null
  const getUpscaleUrl=async (id)=>{
    return new Promise((resolve, reject) => {
      scalepolling = usePolling();
      scalepolling
        .run({
          promiseFn: getRecord,
          params: { id },
          interval:3000,
          resolveFn: (res: any) => {
            if (res.data.upScaleUrl) {
              scalepolling.stop();
              const image=new Image()
              image.src=res.data.upScaleUrl
              image.onload= function (){
                resolve(res.data);
              }
              return;
            }
            return false;
          },
        })
        .then((res: any) => {
          if (res.data.upScaleUrl) {
            resolve(res.data)
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  const getGenerateRecord = (id: number | string) => {
    return new Promise((resolve, reject) => {
      const polling = usePolling();
      polling
        .run({
          promiseFn: getRecord,
          params: { id },
          resolveFn: (res: any) => {
            if (res.code !== resCode.SUCCESS) {
              reject(res);
              polling.stop();
              return;
            }
            if (res.data.status === "FAILED") {
              reject(res);
              polling.stop();
              return;
            }
            if (res.data.status === "PENDING") {
              return false;
            }
            if (res.data.status === "SUCCESS") {
              return true;
            }
          },
        })
        .then((res: any) => {
          loading.value = false
          // generateRecord.value = res.data;
          // imageResults.value = JSON.parse(res.data.images);
          // if (generateRecord.value.mode === "VIRTUAL_STAGING_RENDER") {
          //   if (!res.data.generateUrl) {
          //     active.value = imageResults.value[0];
          //     res.data.generateUrl = active.value;
          //     context.selectIndex = 0;
          //     generateRecord.value.notSelect = true;
          //   }
          // } else {
          //   generateRecord.value.notSelect = false;
          // }
          // if (
          //   res.data.generateUrl &&
          //   !generateRecord.value.notSelect &&
          //   !generateRecord.value.upScaleUrl
          // ) {
          //   getUpscaleUrl(id);
          // }
          // if (!res.data.generateUrl) {
          //   context.pending = true;
          // }
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const toGenerate = async (params) => {
    if (loading.value) {
      return
    }
    try {
      loading.value = true
      const preRes = await generateImage(params);
      loading.value = false
      if (preRes.code === 0 && preRes.data.id) {
        generateRecordID.value = preRes.data.id;
        return preRes.data
      } else {
        ElMessage.error(preRes.msg || preRes.errDesc);
      }
    } catch (err) {
      ElMessage.error("Generate Faild");
      loading.value = false
    }
  }
  const toSelectImage = async (params) => {
    if (loading.value) {
      return
    }
    try {
      loading.value = true
      const preRes = await requestSelectImage(params);
      if (preRes.code === resCode.SUCCESS) {
        return preRes.data
      } else {
        loading.value = false
        ElMessage.error(preRes.msg || preRes.errDesc);
      }
    } catch (err) {
      ElMessage.error("Generate Faild");
      loading.value = false
    }
  }
  return { uploadImage, loading, setUploadImage, getGenerateRecord, toGenerate,getUpscaleUrl, toSelectImage }
})