export const enterprise = {
    buttons:{
        learnMore:'Learn More',
        successfulStory:'Successful Story',
        contactNow:'Contact Now',
    },
    landing: {
        cover: {
            title: `Innovate. <br/>Illustrate. Deliver.`,
            subTitle: 'Elevate Your Business For The Future of Design',
            name: `Leverage cutting-edge Generative AI technology to transform your unique design styles into digital assets, allowing for real-time, customizable design visualizations.`,
            contactNow:'Contact Now',
            demoVideo:'Demo Video',
        },
        boost:{
            title:'Boost Your Business Today',
            list:[
                'Increase Revenue and Design Efficiency',
                'Decrease In-house Design Cost',
                'Improve Customer Engagement',
            ]
        },
        future:{
            title:'The Future of Design'
        },
        digital:{
            title:'Create Your Digital Assets at Scale',
            name:`Collov AI's proprietary AI models understand unique design styles by learning your projects, transforming styles into unified digital assets`,
            list:[
                'Customizable for your brand',
                'Unified assets across all devices',
                'Centralized controls'
            ],
            products:['Dreambooth wo..','charcoal gray','cloud white','Smoke gray','Mocha','Meteor gray','Interstellar silver','Dawn gold','Ash gray',]
        },
        elevate:{
            title:'Elevate Your Design in Seconds',
            name:`Leveraging your unique style library, Collov AI detects, adapts, and visualizes preferred styles and products in customers' space in seconds`,
            list:[
                'Structure preserved',
                'High Quality 3D Rendering',
                'Shop-able Furniture List'
            ]
        },
        mask:{
            title:'Iterate Your Designs With A Few Clicks',
            name:`AI markup editor enables an interactive design experience and generates real-time design iterations with just a few clicks`,
            list:[
                'Partial Furniture Modification',
                'Fexible Style Changes',
                'Smart AI <br/>Markups'
            ]
        },
        leaders:{
            title:`Trusted by Industry Leaders`,
            // name:`Our AI-driven solutions are reshaping the design world, making us the top choice for premier real estate firms, interior designers, and furniture vendors globally. As pioneers in interior aesthetics, our innovations set the gold standard. Dive into a design experience that’s championed by the best in the business.`,
            name: 'Our AI-driven solutions are reshaping the design world, making us the top choice for premier design professionals, furniture vendors and real estate firms. Dive into a design experience that’s championed by the best in the business.'
        },
        clientSay:{
            title:`What Our Clients Say`,
            name:`Get Ready to Maximize Your Productivity With The Future of Design`,
            list: [
                {
                    name: 'Isabella Turner',
                    address: 'CEO, Furniture Industry',
                    content: '“Collov AI has been a game-changer for our brand. By integrating their technology, we’ve positioned ourselves as innovators. Customers love the AI-driven design suggestions, which invariably lead them to our premium collections.”'
                },
                {
                    name: 'Emily Peterson',
                    address: 'Real Estate Developer, Real Estates Industry',
                    content: `“Property showings have never been more efficient. With this AI soultion, our potential buyers get a glimpse of personalized interiors, leading to faster decision-making and more sales.”`
                },
                {
                    name: 'Liam Howard',
                    address: 'Sales Director, Kitchen Cabinet Industry',
                    content: `“Our kitchen cabinet sales have shot up since we’ve started using Collov AI. Visualizing real-time designs tailored to clients’ preferences has certainly given us a competitive edge.”`
                },
                {
                    name: 'Sophia Martinez',
                    address: 'Digital Strategist, Online Furniture E-commerce',
                    content: `“Collov AI’s feature of detecting and suggesting matches has reduced our cart abandonment rates. Our customers now feel confident in their choices, which has notably boosted our sales.”`
                },
                {
                    name: 'Lucas Kim',
                    address: 'Founder, Construction Industry',
                    content: `“In the furniture world, staying trendy and tech-forward is a must. With Collov AI, we’ve managed to offer our customers instant inspirations that align with their tastes, making us their favorite design stop.”`
                },
                {
                    name: 'Ava Chen',
                    address: 'Property Staging Specialist, Real Estates Industry',
                    content: `“We stage homes to sell, and Collov AI’s tool has minimized our effort. Personalized design generation has impressed our clients and buyers alike, resulting in rapid turnarounds.”`
                },
                {
                    name: 'Ethan Ruiz',
                    address: 'Marketing Manager, Commercial Design Industry',
                    content: `“Our website traffic and engagement have seen a massive boost. By embedding Collov’s AI tool, not only do we offer unique designs, but we also effortlessly capture potential leads.”`
                },
                {
                    name: 'Olivia Fischer',
                    address: 'Chief Designer, Decor Company',
                    content: `“As a designer, there are days when I need inspiration. Collov AI provides infinite ideas within seconds, making the process smoother and more efficient. It’s a designer’s dream!”`
                },
                {
                    name: 'Noah Singh',
                    address: 'COO, Tiles Industry',
                    content: `“The precision selling feature of Collov AI is a revelation. Our sales team has more meaningful conversations with potential clients, leading to a significant increase in conversion rates.”`
                },
            ],
        }
    },
    dashboard:{
        title:'AI Design Dashboard',
        cards: [
            {name: 'Number of photos generated', value: '',key:'recordCount'},
            // {name: 'Unique User(s)', value: '3443'},
            // {name: 'New Unique User', value: '359'},
            {name: 'AvgTime(s)', value: '',key:'avgTime',unit:'s'},
        ],
        tabs: ['Overall', 'Last Week', 'Last Month'],
        Product:'Product',
        Add:'Add',
        columns : [
            {title: 'RoomType', width: 100, dataIndex: 'roomType'},
            {title: 'Style', dataIndex: 'style', width: 150},
            {title: 'Privacy', dataIndex: 'privacy'},
            {title: 'Status', dataIndex: 'status', scopedSlots: {customRender: 'status'}},
            {title: 'Original', dataIndex: 'uploadUrl', width: 100, scopedSlots: {customRender: 'original'}},
            {title: 'Generate', dataIndex: 'generateUrl', width: 100, scopedSlots: {customRender: 'generate'}},
            {title: 'Time', dataIndex: 'time', width: 150},
            {
                title: 'Action',
                dataIndex: 'privacy',
                width: 80,
                scopedSlots: {customRender: 'action'},
            },
        ],
        success:{
            title:`Thank you for sending us the requested information.`,
            subtitle:`Our specialists will review it and be in touch with you shortly. We appreciate your
      cooperation and look forward to assisting you.`,
        }
    },
    aiCabinetDesign: {
        buttons: {
            tryItNow: 'Try It Now',
            purchaseNow: 'Purchase Now',
            successStory: 'Success Story',
            tryNow: 'Try Now',
            contactUs: 'Contact Us',
            buyNow: 'Buy Now'
        },
        cabinetRealFill: {
            title: 'Cabinet Real Fill',
            text: 'The World‘s First and Only AI Design Platform for Real, Vendor-Sourced Cabinet Renderings'
        },
        sku: {
            title: 'SKU to Digital Assets',
            text: 'Proprietary AI Understands and Learns Your SKU, Elevating Cabinets into Digital Assets with Precision.'
        },
        realCabinet: {
            title: 'Real Cabinet in AI Design',
            text: 'Accurately Reproducing Styles, Materials, and All Details, Visualizing Real Cabinets in AI-powered Design.'
        },
        bridging: {
            title: 'Bridging Design and Sales',
            text: 'Streamlining Design-to-Sale with Real, Purchasable Cabinets in AI Designs for Increased Sales Productivity.'
        },
        buyNow:{
            billedAnnually: 'Billed Annually',
            billedMonthly: 'Billed Monthly'
        }
    },
    payment: {
        clientSay:{
            title:`What Our Clients Say`,
            list: [
                {
                    name: 'Emily Thompson',
                    address: 'Kitchen Designer, Elite Interiors',
                    content: `As a designer, Collov AI has been a game changer. The ability to quickly visualize a range of cabinet designs in a client's actual kitchen space has streamlined the design process immensely. It's not only about saving time; it's about the ability to bring a client's vision to life in minutes. The positive feedback from clients has been overwhelming.`
                },
                {
                    name: 'Michael Anderson',
                    address: 'Chief Technology Officer, HomeCraft Cabinets',
                    content: `Implementing Collov AI into our website was a breeze. It took less than 30 minutes, and the impact was immediate. The AI’s ability to customize designs based on color, material, and pattern preferences has not only increased our conversion rates but also enhanced our customer satisfaction. It's a technological leap that's putting us ahead in the digital retail space.`
                },
                {
                    name: 'Sarah Jenkins',
                    address: 'Customer Experience Manager,Modern Kitchen Solutions',
                    content: `Collov AI has revolutionized the way we interact with our clients. The lowered communication barrier means our clients can express their preferences more freely, and see them come to life instantly. This has led to quicker decision-making, reduced lead times, and a more enjoyable experience for our customers. It's an invaluable tool for enhancing customer satisfaction.`
                }
            ],
        }
    },
    solutions:{
        AISolutions:`Collov AI Solutions`,
        applicationsText:`Ready-to-use AI applications for high-value use cases.`,
        AIDesignStudio:'AI Design Studio',
        AIVideoCreator:'AI Video Creator',
        AIVideoGenerator:'Short Video Generation',
        AIRealtimeDesign: 'AI Real-Time Design',
        AIStoryteller:'AI Storyteller',
        ProductEngine:'Product Engine',
        MarketingPersonalization:'Marketing Personalization',
        SalesDigitalization:'Sales Digitalization',
        AICabinetDesign:'Cabinet Real Fill',
        Overview:'Overview',
        FreePackage:'Free Package',
        earlyTitle:'Early Innovators Pilot Program',
        earlyText:`Embark on a visionary AI journey with our free pilot program and tap into the expansive
          community of Collov enthusiasts, propelling your brand into the future.`,
        Free:'Free Integration',
        core:'Core AI Experience',
        onboarding:'Simplified Onboarding',
        CollovAIDeveloperTools:`Collov AI Developer Tools`,
        CollovAIDeveloperToolsText:`Collov AI equips you with a comprehensive suite of tools for swift development and seamless deployment of AI-powered design.`,
        CollovAIAPI:'Collov AI API',
        CollovAITemplate:'Collov AI SDK & Template',
        AIDatabase:'Collov AI Database',
        AIDatabaseText:'Collov AI transforms your styles and SKU into scalable digital assets for AI design',
        DigitalSKU:'Digital SKU',
        DigitalStyles:'Digital Design Styles',
        IgniteCover:'Ignite Design Inspiration With AI',
        IgniteCoverDes:`Collov AI transforms your unique styles into digital assets, enabling dynamic and personalized
          interior design in real time.`,

        CreateAssets:'Create Your Digital Assets at Scale',
        CreateAssetsDes:`CollovGPT's proprietary AI models understand unique design styles by learning your projects,
        transforming styles into unified digital assets`,
        brandAssets:`Customizable for your brand`,
        assetsDevices:`Unified assets across all devices`,
        CentralizedControls:`Centralized controls`,

        ElevateDesign:'Elevate Your Design in Seconds',
        ElevateDesignDes:`Leveraging your unique style library, Collov AI detects, adapts, and visualizes preferred styles
        and products in customers' space in seconds`,
        structurePreserved:'Structure preserved',
        HighQuality:'High Quality 3D Rendering',
        FurnitureList:'Shop-able Furniture List',

        Iterate:'Iterate Your Designs With A Few Clicks',
        IterateDes:`A suite of AI-powered editing tools facilitates an interactive design experience and generates
        real-time design iterations with just a few clicks`,
        PartialFurnitureModification:'Partial Furniture Modification',
        BackgroundRemover:'Background Remover',
        SmartAIMarkups:'Smart AI Markups',
        Ready:'Ready to transform your design experience with Collov AI?',

        BetterStorytelling:'In Setting For Better Storytelling',
        BetterStorytellingDes:'Seamlessly integrate your products and concepts into dynamic settings for enhanced, engaging storytelling, creating memorable, immersive design experiences.',
        EffortlessTransformation:'Effortless Style Transformation',
        EffortlessTransformationDes:'Redefine your space with the Furniture Background Swap. Easily switch between different lifestyles to find the perfect fit for your home, visualizing real furniture in your own setting.',
        VisualizeSpace:'Visualize Real Decor in Your Space',
        VisualizeSpaceDes:`Instantly see how various real flooring, wall textures, and paint colors will look in your actual environment, making it easier to choose the perfect materials.`,
        BroughtLife:`Your Design Story, Brought to Life`,
        BroughtLifeDes:`Craft compelling narratives for your design projects. Collov AI storyboard allows you to develop your design concepts into engaging storylines, complete with visuals, for a more immersive design experience.`,

        videoContact:`Ready to transform your video experience with Collov AI?`,
        VideoContent:'Video Content Reimagined with AI',
        VideoDes:'Transform your approach to video with AI that not only animates renderings but also brings avatars to life and condenses your story.',
        VideoInterpreter:'Video Interpreter',
        VideoTransform:`Transform text into natural-sounding speech with our AI-powered voices and bring design presentations to life with a personalized virtual avatar for a smoother, more customized video experience`,
        VideoSummarizer:'Video Summarizer',
        VideoSummarizerdes:`Enhance design communication with concise video summaries, capturing the essence of your project
        and drawing key design insights from masterful inspiration videos.`,
        VideoTransformer:'Video Transformer',
        VideoTransformerText:'Elevate your design presentations by creating videos directly from renderings, enriching your marketing and social media with compelling storytelling content.',
        productshopping:`Ready to transform your product shopping experience with Collov AI?`,
        productElevate:'Elevate Shopping Experience With AI',
        productElevateDes:'Collov AI transforms product discovery with context-aware design renderings and intelligent product recommendation.',
        DigitalProduct:'Digital Product Assets at Scale',
        DigitalProductDes:'Convert products into customizable catalogs, enhancing the product discovery journey both pre and post design.',
        PointPurchase:'Point-of-Purchase',
        PointPurchaseDes:'A gateway to instantly purchasable products, ensuring an optimized sales fit tailored to individual needs. Collov AI seizes the right moments to attract attention with contextualized offerings',
        VisualDiscovery:'Visual Search & Discovery',
        VisualDiscoveryDes:'AI-powered recommendation engine helps your shoppers effortlessly discover and search curated products within design visuals, seamlessly linking design inspiration to purchase',


        marketingEnhance:'Enhance marketing with visual storytelling and AI',
        marketingEnhanceDes:'Collov AI generates and transforms design visuals into powerful marketing assets, driving engagement and strengthening your brand\'s story.',
        VisualContentGenerator:'Visual Content Generator',
        VisualContentGeneratorDes:'Produce rich visuals tailored for social media, influencer campaigns, display ads, and email outreach. Forge a consistent and compelling visual language across all marketing platforms with Collov AI',
        InteractiveLanding:'Interactive Landing Page',
        InteractiveLandingDes:'Integrate the prowess of AI design into your website with just a few lines of code, transforming static pages into interactive experiences. Elevate your brand, setting a new industry benchmark with cutting-edge AI distinction',
        Showroom:'24-hour Digital Showroom',
        ShowroomDes:'Launch a 24-hour showroom swiftly with Collov AI, consistently showcasing your latest products and designs. Beyond just viewing, foster an engaging community where customers contribute and resonate with dynamic content.',
        marketingReady:'Ready to transform your branding and marketing experience with Collov AI?',

        saleReady:'Get Ready to Boost Your Sales With The Future of Design',
        saleDes:'Enhance customer interactions with rapid AI-rendered visuals, propelling your sales into the digital era.',
        ChatDesigner:'Chat Designer',
        ChatDesignerDes:'Experience a seamless sales journey with our fluid conversational AI interface. By understanding natural language, it revolutionizes the design process, fostering a more interactive and engaging experience for customers.',
        collovCRM:'Collov CRM',
        collovCRMDes:'Dive into a streamlined dashboard that offers keen insights into user web interactions. Combined with a sophisticated backend, it enhances lead management and engagement, setting the gold standard in sales digitalization',
        scan:'Scan it. See it. Buy it',
        scandes:'Turn your customers\' mobile devices into powerful design portals via QR scanning in the showroom. Within moments, watch their design inspirations materialize, spotlighting your product in their personal space.',
        freeCover:`Early Innovators Pilot,<br/><span class="free">For Free.</span>`,
        freeCoverDes:`Be among the first to harness the transformative power of our AI-driven interior design tool, designed to streamline creative process and propelling your brand.`,
        AIJourney:'Embark on the AI Journey',
        AIJourneyDes:`Transition from a promising pilot to realizing the complete benefits of AI design as you scale the
      business`,
        Pilot:'Pilot',
        Scale:'Scale',
        Beyond:'Beyond',
        Integration:'Integration',
        DesignStyles:'Design Styles',
        RoomType:'Room Type',
        CatalogUpload:'Catalog Upload',
        PartialMagic:'Partial & Magic Editor',
        AIDesignAnalyzer:'AI Design Analyzer',
        Template:'Template',
        free:'FREE',

        CustomDevelopment:'Custom Development',
        styles:'styles',
        types:'types',
        SKU:'SKU',

        powerDiscover:'Discover the power of AI-driven interior design',
        powerDiscoverDes:`Easily integrate with your website and experience a powerful AI journey—upload a photo, select
          style and room type, and receive completed design effortlessly.`,
        LaunchPilot:'Launch Your AI Pilot',
        LaunchPilotDes:`We guide you from the initial 'how-to' to a fully functioning pilot, paving the way for
      innovation.`,
        ModuleIntegration:`Step 1: Module Integration`,
        websiteEmbed:`Embed Collov's AI design module into your website with just three lines of code. Quick. Simple. Efficient.`,
        InterfaceConfiguration:`Step 2: Interface Configuration`,
        InterfaceConfigurationPersonalize:'Personalize the user interface with your brand’s logos to maintain a consistent and professional look.',
        EnjoyDesign:'Step 3: Enjoy The AI Design',
        LeverageDesign:'Leverage the power of AI design to elevate your offerings and drive business growth.',
        creditHistory:'Credit History',
        billingInformation:'Billing Information',
        Pricing:'Pricing',
        Upgrade:'Upgrade',

    },
    AITools:{
        videoGenerator:{
            title:'Visual Stories Made Simple',
            subtitle:'From Words to Worlds - Effortless AI Video Generator',
            text:'Where your imagination takes flight. Collov AI cutting-edge AI video generator turns texts and images into mesmerizing videos, effortlessly weaving your ideas into captivating visual stories.',
            try:'Try Now',
            turnTitle:'Turn any idea or image to video, instantly',
            turnText:`Discover how the video generator transforms text prompts and images into captivating videos`,
            turnName:'From Text to Video',
            turnInfo:'Create any video you envision with just a text prompt. If you can think it, you can visually realize it.',
            turnLabel:'Surreal night scene with pink sky, golden river, Great Wall in Telon style, and luminous road.',
            turnBtn:'Generate Now',
            magicName:'From Static to Magic',
            magicText:'Bring static images to life by adding dynamic motion, transforming a single picture into an engaging video narrative.',
            possName:'Synergy, Unlimited Possibilities',
            possText:'Interoperated with Collov’s redesign toolkits, transform designs into videos, or create anything for your creative, marketing, and sales ventures.',
            stepTitle:'Create a video in 3 easy steps',
            ready:'A New Era for Video Creating',
            readyText:'Start crafting your future masterpieces today and redefine what\'s possible in video storytelling.',
            tools:[
                {name:'Vivid Storytelling',text:'Transforms simple texts and images into dynamic video narratives'},
                {name:'High-Quality Output',text:'Produce professional-grade videos with superior clarity and detail'},
                {name:'Efficiency and Simplicity',text:'User-friendly platform for quick, high-quality video production'},
            ],
            steps:[
                {name:'Enter your information',text:'Follow the steps to enter your script or reference image'},
                {name:'AI creates your video',text:'Collov Video Generator analyzes your input and creates the stunning video'},
                {name:'Download and enjoy',text:'Export, enjoy and share your video with the world'},
            ],

        },
        realtimeDesign:{
            title:'Real-Time AI Creativity',
            subtitle:'Instant Creation, Precision Control',
            text:'Experience unparalleled creativity with Collov AI Real Time, the world‘s first controllable real-time design tool, ensuring consistent and precise layout control. As you tweak and transform, your ideas instantly come to life. ',
            try:'Try Now',
            turnTitle:'Design at the Speed of Thought',
            turnText:`Match the pace of your creativity, ensuring instant realization of your imaginative concepts.`,
            turnName:'Lightning-Fast Generations',
            turnInfo:'Get instant design iterations with our rapid generation feature, accelerating your creative and collaborative process.',
            turnLabel:'Surreal night scene with pink sky, golden river, Great Wall in Telon style, and luminous road.',
            turnBtn:'Generate Now',
            magicName:'Diverse Input Compatibility',
            magicText:'Start real-time designing with varied inputs like images, screen sharing, or even live camera, catering to all your creative needs.',
            possName:'Smart Software Detection',
            possText:'Say goodbye to plug-ins. Fine-tuned algorithm designed to detect professional design software and offer real-time rendering as you edit your 3D models.',
            powerName: 'Power Tools to Flourish',
            powerText: 'Access precise tools, including brushes, pencils, color palette, design library, and more, for seamless and accurate design enhancements.',
            stepTitle:'Precision and Harmony',
            stepText: 'Unique Edge in Real-Time Design',
            ready:'Activate Your Real-time Experience',
            readyText:'Tap into the unmatched real-time design of Collov AI. Initiate your path to creative mastery and professional precision now',
            tools:[
                {name:'Instant Visualization',text:'Immediate change display boosts decision-making and efficiency.'},
                {name:'Fine-Tuned Control',text:'Match your precise vision with unparalleled control over your design layouts'},
                {name:'Time-Saving Workflow',text:'Reduces rendering times, speeding up project completion and productivity.'},
            ],
            steps:[
                {name:'Enter your information',text:'Follow the steps to enter your script or reference image'},
                {name:'AI creates your video',text:'Collov Video Generator analyzes your input and creates the stunning video'},
                {name:'Download and enjoy',text:'Export, enjoy and share your video with the world'},
            ],
            moreTitle: 'Discover More:'
        }
    }
}
