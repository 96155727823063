export const message={
    home:`Collov AI leverages cutting-edge AI to weave your home design aspirations into vivid, narrative-rich visuals, merging simplicity with aesthetics.`,
    upload:`Experience a smarter approach to design with collov.ai. To get started, simply upload a photo of your space and select the room type and style. collov.ai will then generate a realistic rendering of your room.`,
    redesign:`Experience a smarter approach to design with collov.ai. To get started, simply upload a photo of your space and select the room type and style. collov.ai will then generate a realistic rendering of your room.`,
    eraser:`The magic eraser can help you remove everything; you just need to use a brush tool to select the area you want to remove.`,
    editing:'Experience a smarter approach to design with collov.ai. To get started, simply upload a photo of your space and select the room type and style. collov.ai will then generate a realistic rendering of your room.',
    furniture:`This furniture image is for reference only; there's no guarantee the AI-generated version will
      be 100% similar.`,
    partial:`Select the part of your room you want to change, and our tool does the magic, redesigning only that area. It's the simple way to refresh your space without redoing everything."`,
    kitchenRemodeling:'Upload your kitchen photo, choose your desired cabinet style, and our AI will create a custom rendering of your kitchen with a modern, technology-enhanced cabinet design, showcasing futuristic aesthetics and smart features tailored to your space.',
    train:'Quickly modify the furniture in the image, and you can customize the style of furniture you want to replace.',
    addItem:'Add item',
    lighting:'Change lighting',
    detection:'Furniture detection',
    DIY:'Mood board DIY',
    Upscale:'Upscale',
    'TETX_2_IMG':'AI interiorGen is a cutting-edge technology that allows users to generate images from textual descriptions through a conversational interface. By providing detailed prompts to an AI, users can see their words translated into visual art instantaneously. This synthesis of natural language processing and generative art opens up creative possibilities for both casual users and professionals alike.',
    'MIDJOURNEY_V6':'AI interiorGen is a cutting-edge technology that allows users to generate images from textual descriptions through a conversational interface. By providing detailed prompts to an AI, users can see their words translated into visual art instantaneously. This synthesis of natural language processing and generative art opens up creative possibilities for both casual users and professionals alike.',
    'FLOORPLAN_GPT':'Floorplan Upload: Users can upload their floor plan to our website. This unique feature allows for the visualization of rooms based on the provided floor plan. It transforms a basic architectural drawing into a more comprehensible and interactive visual representation, making it easier for users to envisage their space.',
    'DRAFT_IMAGE':'Draft & Imagine AI design Customization: After the room is visualized, users have the flexibility to make any modifications to this visualized space. Our platform supports real-time updates, ensuring that any changes made by the user are immediately reflected in the visual representation. This feature enables users to experiment with different layouts, decorations, and styles, providing a dynamic and user-friendly interface for room planning and design.',
    ChatDesigner: 'AI chat-to-image is a cutting-edge technology that allows users to generate images from textual descriptions through a conversational interface. By providing detailed prompts to an AI, users can see their words translated into visual art instantaneously. This synthesis of natural language processing and generative art opens up creative possibilities for both casual users and professionals alike.',
    GenVideo: 'This tool is an innovative video generation software that can create a 2-second video clip based on your description. Simply input your ideas, concepts, or scenarios, and the tool uses advanced AI algorithms to interpret and translate your words into a brief, dynamic video.',
    ProductVideoStoryboard: 'Product Storyboard offers video storyline development and keyframe visualization for home designs and products, ensuring captivating, coherent narratives with impactful visuals in a professional and concise manner',
    fastPreserved:'Experience a smarter approach to design with collov.ai. To get started, simply upload a photo of your space and select the room type and style. collov.ai will then generate a realistic rendering of your room.',
    realTimeDesign:'AI-powered interior design tool: Adjust settings on the left, see the instant transformation on the right in a side-by-side comparison.',
    generateTips:'If it relates to image generation, it takes ~45s per images',
    Today:'Today',
    Yesterday:'Yesterday',
    Previous:'Previous 7 Days',
    comingSoonText:' We’re currently working on creating something fantastic. We’ll be here soon.'
}
