import type {DesignTool} from "@/dictionary/type";

type CanvasType = 'cursor' | 'lasso' | 'brush' | 'eraser' | 'mask' | 'dot' | 'history'
export const canvasStatus: CanvasType[] = ['lasso', 'brush', 'eraser', 'mask', 'cursor', 'dot']
export const designStatus: CanvasType[] = ['cursor', 'dot', 'history']
const generateForm = {
    design: {
        formKeys: ['uploadUrl', 'roomType', 'style', 'styleImage', 'prompt', 'mode', 'emptyRoom', 'privacy'],
        optionKey: {styleActive: ['style', 'styleImage', 'prompt']},
        step: [
            {key: 'uploadUrl', icon: 'upload', value: 'uploadUrl'},
            {key: 'roomType', icon: 'warehouse'},
            {
                key: 'style', icon: 'swatches', activeValue: 'styleActive', translate: (form, {styleActive}) => {
                    if (styleActive === 'style') {
                        return {
                            value: form['style']
                        }
                    } else if (styleActive === 'styleImage') {
                        return {
                            value: 'uploadUrl'
                        }
                    } else {
                        return {
                            value: form.prompt,
                            show: true
                        }
                    }
                }, condition: (form, {styleActive}) => {
                    return !!(form[styleActive])
                }
            },
            // {key: 'mode', icon:'mode'},
            {key: 'emptyRoom', icon: 'mode'},
            {key: 'privacy', icon: 'mode'},
        ],
        defaultValue: {
            emptyRoom: 'NO',
            privacy: 'PUBLIC',
            mode: 'PRESERVED',
        },
        formButton: ['generate'],
    },
    fastPreserved: {
        formKeys: ['uploadUrl', 'roomType', 'mode', 'style', 'emptyRoom', 'privacy'],
        step: [
            {
                key: 'selection', icon: 'upload', value: 'generateUrl', condition: (form) => {
                    return !!(form.generateUrl)
                }
            },
            {key: 'refine', required: false, icon: 'warehouse'},
            {key: 'homeRedesign', required: false, icon: 'warehouse'},
        ],
        defaultValue: {
            emptyRoom: 'NO',
            privacy: 'PUBLIC',
            mode: 'PRESERVED',
        },
        fixed: {
            mode: 'PRESERVED'
        },
        formButton: ['generate'],
    },
    roomType: {
        formKeys: ['uploadUrl', 'roomType', 'style', 'requestId', 'privacy'],
        step: [
            {key: 'uploadUrl', icon: 'upload'},
            {key: 'style', icon: 'swatches'},
            {key: 'privacy', icon: 'mode'},
        ],
        defaultValue: {
            roomType: 'kitchen',
            privacy: 'PUBLIC',
            mode: 'KITCHEN_REMODEL',
        },
        formButton: ['generate'],
    },
    partial: {
        formKeys: ['uploadUrl', 'maskUrl', 'maskTypes', 'furnishing', 'architectural', 'roomType', 'style', 'prompt', 'emptyRoom', 'privacy'],
        optionKey: {styleActive: ['style', 'prompt'], maskTypes: ['furnishing', 'architectural']},
        step: [
            {key: 'uploadUrl', icon: 'upload', value: 'uploadUrl'},
            {key: 'maskUrl', icon: 'mask', activeValue: 'maskTypes', navs: ['furnishing', 'architectural']},
            {key: 'roomType', icon: 'warehouse'},
            {
                key: 'style', icon: 'swatches', activeValue: 'styleActive', translate: (form, {styleActive}) => {
                    if (styleActive === 'style') {
                        return {
                            value: form.style
                        }
                    } else if (styleActive === 'styleImage') {
                        return {
                            value: 'uploadUrl'
                        }
                    } else {
                        return {
                            value: form.prompt,
                            show: true
                        }
                    }
                }, condition: (form, {styleActive}) => {
                    return !!(form[styleActive])
                }
            },
            {key: 'emptyRoom', icon: 'mode'},
            {key: 'privacy', icon: 'mode'},
        ],
        defaultValue: {
            emptyRoom: 'NO',
            privacy: 'PUBLIC',
            mode: 'MASK_GENERATE',
        },
        formButton: ['generate'],
    },
    editing: {
        formKeys: ['uploadUrl', 'maskUrl', 'category', 'productImage'],
        step: [
            {key: 'uploadUrl', icon: 'upload'},
            {
                key: 'area', icon: 'mask', condition: (form) => {
                    return !!(form.maskUrl)
                }
            },
            {key: 'category', icon: 'table'},
            {key: 'productImage', icon: 'swatches'},
        ],
        defaultValue: {
            mode: 'FURNITURE_EDITING',
        },
        formButton: ['generate'],
    },
    assistant: {
        formKeys: ['uploadUrl', 'maskUrl'],
        step: [
            {key: 'uploadUrl', icon: 'upload'},
            {
                key: 'assistant', icon: 'mask', condition: (form) => {
                    return !!(form.maskUrl)
                }
            },
        ],
        formButton: ['done'],
    },
    prompt: {
        formKeys: ['prompt', 'privacy', 'mode'],
        step: [
            {key: 'prompt', icon: 'swatches'},
            {key: 'privacy', icon: 'mode'},
        ],
        defaultValue: {
            privacy: 'PUBLIC',
            mode: 'TETX_2_IMG',
        },
        formButton: ['done'],
    },
    promptAndUplad: {
        formKeys: ['uploadUrl', 'prompt', 'privacy', 'mode'],
        step: [
            {key: 'uploadUrl', icon: 'upload'},
            {key: 'prompt', icon: 'swatches'},
            {key: 'privacy', icon: 'mode'},
        ],
        defaultValue: {
            privacy: 'PUBLIC',
            mode: 'TETX_2_IMG',
        },
        formButton: ['done'],
    },
}
export const formType = {
    design: {
        step: [
            {key: 'uploadUrl', required: true, icon: 'upload'},
            {key: 'roomType', required: true, icon: 'warehouse'},
            {
                key: 'style',
                required: true,
                icon: 'swatches',
                active: 'styleActive',
                navs: ['style', 'styleImage', 'prompt']
            },
            // {key: 'mode', required: true,icon:'mode',defaultValue:'PRESERVED'},
            {key: 'emptyRoom', required: true, icon: 'mode', defaultValue: 'NO'},
            {key: 'privacy', required: true, icon: 'mode'},
        ],
        formKey: {
            uploadUrl: ['uploadUrl'],
            roomType: ['roomType'],
            style: ['style', 'styleImage', 'prompt'],
            mode: ['mode'],
            emptyRoom: ['emptyRoom'],
            privacy: ['privacy'],
        },
        keyDetail: {
            uploadUrl: {
                keys: ['uploadUrl']
            },
            roomType: {
                keys: ['roomType']
            },
            style: {
                keys: ['style', 'styleImage', 'prompt'],
                defaultValue: {
                    style: '',
                    styleImage: '',
                    prompt: '',
                }
            },
            mode: {
                keys: ['mode'],
                defaultValue: {
                    mode: 'PRESERVED',
                }
            },
            emptyRoom: {
                keys: ['emptyRoom'],
                defaultValue: {
                    emptyRoom: 'NO',
                }
            },
            privacy: {
                keys: ['privacy'],
                defaultValue: {
                    privacy: 'PUBLIC',
                }
            },
        },
        formButton: ['generate'],
    },
    fastPreserved: {
        step: [
            {key: 'uploadUrl', required: true, icon: 'upload'},
            {key: 'roomType', required: true, icon: 'warehouse'},
            {
                key: 'style',
                required: true,
                icon: 'swatches',
                active: 'styleActive',
                navs: ['style', 'styleImage', 'prompt']
            },
            {key: 'emptyRoom', required: true, icon: 'mode', defaultValue: 'NO'},
            {key: 'privacy', required: true, icon: 'mode'},
        ],
        formKey: {
            uploadUrl: ['uploadUrl'],
            roomType: ['roomType'],
            style: ['style', 'styleImage', 'prompt'],
            emptyRoom: ['emptyRoom'],
            privacy: ['privacy'],
        },
        keyDetail: {
            uploadUrl: {
                keys: ['uploadUrl']
            },
            roomType: {
                keys: ['roomType']
            },
            style: {
                keys: ['style', 'styleImage', 'prompt'],
                defaultValue: {
                    style: '',
                    styleImage: '',
                    prompt: '',
                }
            },
            emptyRoom: {
                keys: ['emptyRoom'],
                defaultValue: {
                    mode: 'NO',
                }
            },
            privacy: {
                keys: ['privacy'],
                defaultValue: {
                    privacy: 'PUBLIC',
                }
            },
        }
    },
    roomType: {
        step: [
            {key: 'uploadUrl', required: true, icon: 'upload'},
            {key: 'style', required: true, icon: 'swatches', value: 'style', active: 'styleActive'},
            {key: 'privacy', required: false, icon: 'mode', defaultValue: 'PUBLIC'},
        ],
        formKey: {
            uploadUrl: ['uploadUrl'],
            style: ['style', 'requestId'],
            privacy: ['privacy'],
        },
        keyDetail: {
            uploadUrl: {
                keys: ['uploadUrl']
            },
            roomType: {
                keys: ['roomType'],
                defaultValue: {
                    roomType: 'kitchen'
                }
            },
            style: {
                keys: ['style', 'requestId'],
                defaultValue: {
                    style: '',
                    requestId: '',
                }
            },
            privacy: {
                keys: ['privacy'],
                defaultValue: {
                    privacy: 'PUBLIC',
                }
            },
            mode: {
                keys: ['mode'],
                defaultValue: {
                    mode: 'KITCHEN_REMODEL',
                }
            },
        }
    },
    partial: {
        step: [
            {key: 'uploadUrl', required: true, icon: 'upload'},
            {
                key: 'maskUrl',
                required: true,
                icon: 'mask',
                activeValue: 'maskTypes',
                navs: ['furnishing', 'architectural']
            },
            {key: 'roomType', required: true, icon: 'warehouse'},
            {key: 'style', required: true, active: 'styleActive', icon: 'swatches', navs: ['style', 'prompt']},
            {key: 'emptyRoom', required: true, icon: 'mode', defaultValue: 'NO'},
            {key: 'privacy', required: true, icon: 'mode', defaultValue: 'PUBLIC'},
        ],
        formKey: {
            uploadUrl: ['uploadUrl'],
            roomType: ['roomType'],
            maskUrl: ['maskUrl', 'maskTypes', 'architectural', 'furnishing'],
            style: ['style', 'prompt'],
            emptyRoom: ['emptyRoom'],
            privacy: ['privacy'],
        },
        keyDetail: {
            uploadUrl: {
                keys: ['uploadUrl']
            },
            roomType: {
                keys: ['roomType']
            },
            style: {
                keys: ['style', 'prompt'],
                defaultValue: {
                    style: '',
                    prompt: '',
                }
            },
            maskUrl: {
                keys: ['maskUrl', 'maskTypes', 'architectural', 'furnishing'],
                defaultValue: {
                    maskUrl: '',
                    maskTypes: '',
                    architectural: '',
                    furnishing: '',
                }
            },
            emptyRoom: {
                keys: ['emptyRoom'],
                defaultValue: {
                    emptyRoom: 'NO',
                }
            },
            privacy: {
                keys: ['privacy'],
                defaultValue: {
                    privacy: 'PUBLIC',
                }
            },
        },
        formButton: ['generate'],
    },
    editing: {
        step: [
            {key: 'uploadUrl', required: true, icon: 'upload'},
            {key: 'area', required: true, icon: 'mask'},
            {key: 'category', required: true, icon: 'table'},
            {key: 'productImage', required: true, icon: 'swatches'},
            // {key:'color',required:false},
            // {key:'material',required:false},
        ],
        formKey: {
            uploadUrl: ['uploadUrl'],
            area: ['maskUrl'],
            category: ['category'],
            productImage: ['productImage', 'productMessage'],
            // color:['color'],
            // material:['material'],
        },
        formButton: ['generate'],
    },
    assistant: {
        step: [
            {key: 'uploadUrl', required: true, icon: 'upload'},
            {key: 'assistant', required: true, icon: 'mask'},
        ],
        formKey: {
            uploadUrl: ['uploadUrl'],
            assistant: ['maskUrl'],
        },
        formButton: ['done'],
    },
    prompt: {
        step: [
            {key: 'prompt', required: true, icon: 'swatches'},
            {key: 'privacy', required: true, icon: 'mode', defaultValue: 'PUBLIC'},
        ],
        keyDetail: {
            prompt: {
                keys: ['prompt'],
                label: 'Enter prompt',
                defaultValue: {
                    prompt: ''
                }
            },
            chatExample: {},
            privacy: {
                keys: ['privacy'],
                defaultValue: {
                    privacy: 'PUBLIC'
                }
            },
        },
        verification: {
            condition: (form) => {
                const status = !!(form.prompt.trim().length > 0)
                if (!status) {
                    return {
                        status: false,
                        tip: 'Please input prompt'
                    }
                }
                return {
                    status: true,
                }
            }
        },
        formKey: {
            prompt: ['prompt'],
            privacy: ['privacy'],
        },
        formButton: ['generate'],
    },
    realTimeDesign: {
        keyDetail: {
            tabActive: {
                keys: ['active'],
                list: [{value: 'Interior'}, {value: 'Architect'}, {value: 'Floorplan'}, {value: 'Freestyle'},],
                required: false,
                defaultValue: {
                    active: 'Interior'
                }
            },
            Interior: {
                prompt: 'A realistic and clear rendering with a bright light',
                images: [
                    {url: 'https://d17axom7zrjq3q.cloudfront.net/20231226/cdd3f271-a3a0-4562-a341-734428b27304.jpg'},
                    {url: 'https://d17axom7zrjq3q.cloudfront.net/20231226/ca5bc53b-09c1-4c77-81a1-3d4332839c5d.png'},
                    {url: 'https://d17axom7zrjq3q.cloudfront.net/AI_GENERATE/20230908/a6ca00c8-74be-4c04-b5dd-acfe4d186e82.png'},
                    {url: 'https://d17axom7zrjq3q.cloudfront.net/AI_GENERATE/20230908/fca193ac-5850-4b3a-9ad1-8cc879f7fe26.png'}
                ],
            },
            Architect: {
                prompt: 'A photo of a building with glass exterior walls and white roof',
                images: [{url:'https://d17axom7zrjq3q.cloudfront.net/20231226/f3c9aeaa-80d9-471e-bb45-5b03076e8fa3.png'}, {url:'https://d17axom7zrjq3q.cloudfront.net/20231226/18fd36ce-4b04-4e07-b6b4-2a33070d6ee8.png'}]
            },
            Floorplan: {
                prompt: 'an overhead view of a photo realistic, modern-style bedroom with DimGray color',
                images: [{url:'https://d17axom7zrjq3q.cloudfront.net/20231226/d8dd7025-388f-40b4-afc7-ccbceb99f0be.png'}, {url:'https://d17axom7zrjq3q.cloudfront.net/20231226/7a17251f-c4a4-4535-bc02-8b262914fb09.png'}]
            }
        },
    },
    promptAndUplad: {
        step: [
            {key: 'uploadUrl', required: false, icon: 'upload'},
            {key: 'prompt', required: false, icon: 'swatches'},
            {key: 'privacy', required: true, icon: 'mode', defaultValue: 'PUBLIC'},
        ],
        formKey: {
            uploadUrl: ['uploadUrl'],
            roomType: ['prompt'],
            privacy: ['privacy'],
        },
        keyDetail: {
            tabActive: {
                keys: ['active'],
                list: [{name: 'Upload Image', value: 'uploadUrl'}, {name: 'Enter prompt', value: 'prompt'}],
                required: false,
                defaultValue: {
                    active: 'uploadUrl'
                }
            },
            uploadUrl: {
                keys: ['uploadUrl'],
                active: 'uploadUrl',
                required: false,
                defaultValue: {
                    uploadUrl: ''
                }
            },
            prompt: {
                keys: ['prompt'],
                active: 'prompt',
                required: false,
                defaultValue: {
                    prompt: ''
                }
            },
            chatExample: {
                active: 'prompt',
            },
            privacy: {
                keys: ['privacy'],
                required: false,
                defaultValue: {
                    privacy: 'PUBLIC'
                }
            },
        },
        verification: {
            condition: (form) => {
                let status = true
                if (form.active === 'prompt') {
                    status = !!(form.prompt.trim().length > 0)
                } else {
                    status = !!(form.uploadUrl.length > 0)
                }
                if (!status) {
                    return {
                        status: false,
                        tip: 'Please input prompt or upload image'
                    }
                }
                return {
                    status: true,
                }
            },
            getFormValue: (form) => {
                const params = {...form}
                if (form.active === 'prompt') {
                    delete params.uploadUrl
                } else {
                    delete params.prompt
                }
                delete params.active
                return params
            }
        },
        formButton: ['generate'],
    },
}
export const moduleType = {
    uploadTool: {
        left: [
            {'introduce': {}},
            {
                'buttonTools': [
                    {name: '', icon: 'like', key: '_LIKE_COUNT', type: 'statistics'},
                    {name: '', icon: 'account', key: '_USED_COUNT', type: 'statistics'},
                    {name: 'share', icon: '', type: 'share'},
                    {name: 'detail', icon: '', type: 'detail'},
                ]
            },
            {'navs': ['example', 'discussion']},
            {'example': {}},
            {'discussion': {}},
        ],
        right: [
            {'upload': {}},
        ]
    },
    chatTool: {
        left: [
            {'introduce': {}},
            {
                'buttonTools': [
                    {name: '', icon: 'like', key: '_LIKE_COUNT', type: 'statistics'},
                    {name: '', icon: 'account', key: '_USED_COUNT', type: 'statistics'},
                    {name: 'share', icon: '', type: 'share'},
                    {name: 'detail', icon: '', type: 'detail'},
                ]
            },
            {'navs': ['discussion']},
            {'discussion': {}},
        ],
        right: [
            {'chat': {icons: ['chat', 'history']}},
        ]
    },
    promptTool: {
        left: [
            {'introduce': {}},
            {
                'buttonTools': [
                    {name: '', icon: 'like', key: '_LIKE_COUNT', type: 'statistics'},
                    {name: '', icon: 'account', key: '_USED_COUNT', type: 'statistics'},
                    {name: 'share', icon: '', type: 'share'},
                    {name: 'detail', icon: '', type: 'detail'},
                ]
            },
            {'navs': ['discussion']},
            {'discussion': {}},
        ],
        right: [
            {'prompt': {}},
        ]
    },
    videoTool: {
        left: [
            {'introduce': {}},
            {
                'buttonTools': [
                    {name: '', icon: 'like', key: '_LIKE_COUNT', type: 'statistics'},
                    {name: '', icon: 'account', key: '_USED_COUNT', type: 'statistics'},
                    {name: 'share', icon: '', type: 'share'},
                    {name: 'detail', icon: '', type: 'detail'},
                ]
            },
            {'navs': ['discussion']},
            {'discussion': {}},
        ],
        right: [
            {'video': {}},
        ]
    },
}

export const designTool = [
    {
        canDraw: false,
        dot: false,
        tool: false,
        type: 'redesign',//前端判断的类型
        key: 'HOME_REDESIGN',//后端判断的类型
        formType: {...formType.design},//表单类型
        generateForm: {...generateForm.design},//表单类型
        url: 'https://d17axom7zrjq3q.cloudfront.net/20231026/216dcb46-4a95-4e80-8a61-e5e50fd7e270.jpg',//封面图
        includes: designStatus,
        mode: ['PRESERVED', 'WELL'],
        classType: ['general', 'interiorDesign'],
        showList: ['dashboard_home', 'dashboard_tools', 'index', 'result'],//需要展示的路由名
        modules: moduleType.uploadTool,
        redesign: true,
        upscale: true,
        time: 25,
        imgNum: 4,
        description: 'Home redesign offers AI-driven room redesigns from your photos.',
        queryLineUp: true,
        country: ['en', 'zh']
    },
    {
        canDraw: false,
        dot: false,
        tool: false,
        type: 'fastPreserved',//前端判断的类型
        key: 'FAST_PRESERVED',//后端判断的类型
        formType: {...formType.fastPreserved},//表单类型
        generateForm: {...generateForm.fastPreserved},//表单类型
        tag: 'Beta',
        url: 'https://d17axom7zrjq3q.cloudfront.net/20231212/225fcc4d-5c69-457a-b640-341ee8acfe0a.jpg',//封面图
        includes: designStatus,
        mode: 'FAST_PRESERVED',
        classType: ['general', 'interiorDesign'],
        showList: ['dashboard_home', 'dashboard_tools', 'result'],//需要展示的路由名
        modules: moduleType.uploadTool,
        redesign: true,
        upscale: true,
        // origin: 'https://instant.collov.ai',
        time: 2,
        tryTime: 0.1,
        imgNum: 4,
        description: 'Instant home redesign offers AI-driven room redesigns from your photos.',
        queryLineUp: true,
        country: ['en', 'zh']
    },
    {
        canDraw: true,
        dot: true,
        tool: false,
        hideMask: true,
        type: 'partial',
        key: 'PARTIAL_REMODEL',
        url: 'https://d17axom7zrjq3q.cloudfront.net/20231026/99101fdc-8247-4dbf-8645-8706c7ff8f40.jpg',
        formType: {...formType.partial},
        generateForm: {...generateForm.partial},
        includes: [...designStatus, ...canvasStatus],
        mode: 'MASK_GENERATE',
        classType: ['general', 'interiorDesign'],
        modules: moduleType.uploadTool,
        time: 30,
        imgNum: 4,
        upscale: true,
        queryLineUp: true,
        description: 'Select and revamp a room section with our AI-assisted partial remodel tool.',
        showList: ['dashboard_home', 'dashboard_tools', 'index', 'result'],//需要展示的路由名
        country: ['en', 'zh']
    },
    {
        canDraw: true,
        tool: false,
        dot: false,
        type: 'kitchenRemodeling',
        key: 'KITCHEN_REMODEL',
        url: 'https://d17axom7zrjq3q.cloudfront.net/AI_GENERATE/20231124/644abb14-7034-40b9-a718-b14a386285cf.jpg',
        formType: {...formType.roomType, fixed: {key: 'roomType', value: 'kitchen'}},
        generateForm: {...generateForm.roomType},
        includes: designStatus,
        example: 'kitchenlist',
        mode: 'KITCHEN_REMODEL',
        classType: ['general', 'interiorDesign'],
        modules: moduleType.uploadTool,
        redesign: true,
        time: 20,
        imgNum: 4,
        upscale: true,
        queryLineUp: true,
        description: 'Upload your kitchen image and choose a cabinet style; our AI instantly creates a personalized, futuristic design.',
        showList: ['dashboard_home', 'dashboard_tools', 'index', 'result'],//需要展示的路由名
        country: ['en', 'zh']
    },
    {
        type: 'realTimeDesign',
        key: 'REALTIME',
        tag: 'Beta',
        url: 'https://d17axom7zrjq3q.cloudfront.net/20231211/3236dda0-6fa4-4a60-9174-99365015b14f.jpg',
        mode: 'REALTIME',
        classType: ['general', 'interiorDesign'],
        realTime: true,
        example: '',
        // origin:'https://instant.collov.ai',
        upscale: true,
        modules: moduleType.uploadTool,
        description: 'AI-powered interior design tool: Adjust settings on the left, see the instant transformation on the right in a side-by-side comparison.',
        showList: ['dashboard_home', 'dashboard_tools', 'result'],
        country: ['en'],
        formType: {...formType.realTimeDesign},
    },
    {
        canDraw: true,
        tool: true,
        dot: true,
        type: 'editing',
        key: 'FURNITURE_EDITING',
        url: 'https://d17axom7zrjq3q.cloudfront.net/20231026/eec8d111-b863-4436-8e33-03c613b020de.jpg',
        formType: {...formType.editing},
        generateForm: {...generateForm.editing},
        includes: ['cursor'],
        mode: 'FURNITURE_EDITING',
        modules: moduleType.uploadTool,
        time: 30,
        imgNum: 1,
        queryLineUp: true,
        classType: ['general', 'interiorDesign'],
        description: 'Customize your room\'s furnishings with our AI-powered furniture editing suite.',
        showList: [/*'dashboard_home', */'dashboard_tools', 'index', 'result'],//需要展示的路由名
        country: ['en', 'zh']
    },
    {
        canDraw: true,
        dot: true,
        tool: true,
        type: 'eraser',
        key: 'MAGIC_ERASER',
        url: 'https://d17axom7zrjq3q.cloudfront.net/AI_GENERATE/20231116/80348aeb-a65c-4dd6-8d36-a510abe22a17.jpg',
        includes: [...designStatus, ...canvasStatus],
        mode: 'MAGIC_ERASER',
        modules: moduleType.uploadTool,
        imgNum: 1,
        classType: ['general', 'interiorDesign'],
        description: 'Erase room elements effortlessly with our AI magic eraser tool.',
        showList: [/*'dashboard_home', */'dashboard_tools', 'index', 'result'],//需要展示的路由名
        country: ['en', 'zh']
    },
    {
        canDraw: true,
        tool: true,
        dot: true,
        type: 'assistant',
        key: 'VisualAssistant',
        url: 'https://d17axom7zrjq3q.cloudfront.net/AI_GENERATE/20231111/d027308c-60a9-4c27-9312-27c48552b7bb.jpg',
        includes: [...designStatus, ...canvasStatus],
        formType: {...formType.assistant},//表单类型
        generateForm: {...generateForm.assistant},//表单类型
        disabled: true,
        classType: ['general', 'interiorDesign'],
        showList: ['index', 'result'],//需要展示的路由名
        country: ['en', 'zh']
    },
    {
        canDraw: false,
        tool: false,
        dot: false,
        type: 'ChatDesigner',//前端判断的类型
        key: 'CHAT_DESIGNER',//后端判断的类型
        url: 'https://dt1xk3a15ve0n.cloudfront.net/chenyi/WechatIMG7887.jpg',//封面图
        mode: 'CHAT_DESIGNER',
        modules: moduleType.chatTool,
        isDashboard: true,
        classType: ['general'],
        description: 'Generate artistic design inspirations through AI-powered conversational chat.',
        showList: ['dashboard_home', 'dashboard_tools'],//需要展示的路由名
        country: ['en', 'zh']
    },
    {
        canDraw: false,
        tool: false,
        dot: false,
        type: 'ProductVideoStoryboard',//前端判断的类型
        key: 'PRODUCT_VIDEO_STORYBOARD',//后端判断的类型
        url: 'https://dt1xk3a15ve0n.cloudfront.net/chenyi/WechatIMG7896.jpg',//封面图
        mode: 'PRODUCT_VIDEO_STORYBOARD',
        isDashboard: true,
        modules: moduleType.chatTool,
        classType: ['general'],
        description: 'Product StoryBoard Director, here to transform your product script ideas into captivating visual keyframes!',
        showList: ['dashboard_home', 'dashboard_tools'],//需要展示的路由名
        country: ['en', 'zh']
    },
    {
        canDraw: false,
        tool: false,
        dot: false,
        type: 'GenVideo',//前端判断的类型 DesignReel
        key: 'GEN_VIDEO',//后端判断的类型
        url: 'https://dt1xk3a15ve0n.cloudfront.net/chenyi/c5caf909884e66dead93f611d95264f0.jpg',//封面图
        mode: 'GEN_VIDEO',
        isDashboard: true,
        imgNum: 1,
        modules: moduleType.videoTool,
        classType: ['general'],
        queryLineUp: true,
        publish: true,
        formType: {...formType.promptAndUplad},
        generateForm: {...generateForm.prompt},
        description: 'Create and explore interior design concepts with AI-driven text-to-video generation.',
        showList: ['dashboard_home', 'dashboard_tools'],//需要展示的路由名
        country: ['en', 'zh']
    },
    {
        canDraw: false,
        tool: false,
        dot: false,
        type: 'TETX_2_IMG',//前端判断的类型
        key: 'TETX_2_IMG',//后端判断的类型
        url: 'https://d17axom7zrjq3q.cloudfront.net/AI_GENERATE/20231116/320321d0-f532-4b8a-b65b-210513037a3c.jpg',//封面图
        mode: 'TETX_2_IMG',
        isDashboard: true,
        modules: moduleType.promptTool,
        imgNum: 1,
        queryLineUp: true,
        classType: ['general'],
        formType: {...formType.prompt},
        generateForm: {...generateForm.prompt},
        publish: true,
        description: 'Envision and refine room designs instantly with text-to-image AI technology.',
        showList: ['dashboard_home', 'dashboard_tools'],//需要展示的路由名
        country: ['en', 'zh']
    },
    {
        canDraw: false,
        tool: false,
        dot: false,
        type: 'MIDJOURNEY_V6',//前端判断的类型
        key: 'MIDJOURNEY_V6',//后端判断的类型
        url: 'https://d17axom7zrjq3q.cloudfront.net/AI_GENERATE/20231116/320321d0-f532-4b8a-b65b-210513037a3c.jpg',//封面图
        mode: 'MIDJOURNEY_V6',
        isDashboard: true,
        modules: moduleType.promptTool,
        imgNum: 1,
        queryLineUp: true,
        classType: ['general'],
        formType: {...formType.prompt},
        generateForm: {...generateForm.prompt},
        publish: true,
        description: 'Envision and refine room designs instantly with text-to-image AI technology.',
        showList: ['dashboard_home', 'dashboard_tools'],//需要展示的路由名
        country: ['en', 'zh']
    },
    {
        canDraw: false,
        tool: false,
        dot: false,
        type: 'FLOORPLAN_GPT',//前端判断的类型
        key: 'FLOORPLAN_GPT',//后端判断的类型
        url: 'https://dt1xk3a15ve0n.cloudfront.net/chenyi/Floorplan_GPT.jpg',//封面图
        mode: 'FLOORPLAN_GPT',
        isDashboard: true,
        modules: moduleType.promptTool,
        imgNum: 1,
        queryLineUp: true,
        classType: ['general'],
        formType: {...formType.prompt},
        generateForm: {...generateForm.prompt},
        publish: true,
        description: 'This unique feature allows for the visualization of rooms based on the provided floor plan.',
        showList: ['dashboard_home', 'dashboard_tools'],//需要展示的路由名
        country: ['en', 'zh']
    },
    {
        canDraw: false,
        tool: false,
        dot: false,
        type: 'DRAFT_IMAGE',//前端判断的类型
        key: 'DRAFT_IMAGE',//后端判断的类型
        url: 'https://dt1xk3a15ve0n.cloudfront.net/chenyi/Draft_Image.jpg',//封面图
        mode: 'DRAFT_IMAGE',
        isDashboard: true,
        modules: moduleType.promptTool,
        imgNum: 1,
        queryLineUp: true,
        classType: ['general'],
        formType: {...formType.prompt},
        generateForm: {...generateForm.prompt},
        publish: true,
        description: 'Draft image: After the room is visualized, users have the flexibility to make any modifications to this visualized space.',
        showList: ['dashboard_home', 'dashboard_tools'],//需要展示的路由名
        country: ['en', 'zh']
    },
    {
        canDraw: false,
        dot: false,
        tool: false,
        type: 'customer',//自定义类型
        customerTitle: 'uploadTitle',
        key: 'UPLOAD',//
        mode: 'UPLOAD',
        classType: ['general'],
        showList: ['dashboard_home'],//需要展示的路由名
        country: ['en', 'zh']
    },
]
export const hasComplete = (form, item, activeKey) => {
    if (item.condition) {
        return item.condition(form, activeKey)
    } else {
        return form[item.key]
    }
}
export const getModeTool = (res, key) => {
    const idx = designTool.findIndex(f => {
        if (key) {
            return f[key] === res
        } else if (typeof f.mode === 'string') {
            return f.mode === res.mode
        } else if (f.mode) {
            return f.mode.indexOf(res.mode) > -1
        }
    })
    return idx >= 0 ? designTool[idx] : {}
}
export const createForm = (type, obj) => {
    const idx = designTool.findIndex(f => f.type === type)
    const detail = designTool[idx]
    const form = {}
    if (detail.formType.keyDetail) {
        const keyDetail = detail.formType.keyDetail
        for (let i in keyDetail) {
            const keys = keyDetail[i].keys || []
            keys.forEach(k => {
                const defaultValue = keyDetail[i].defaultValue ? keyDetail[i].defaultValue[k] : ''
                form[k] = obj[k] || defaultValue
            })
        }
    }
    return form
}
export const createGenerateForm = (generateForm, obj) => {
    const form = {}
    generateForm.formKeys.forEach(k => form[k] = '')
    const defaultValue = generateForm.defaultValue || {}
    const fixedValue = generateForm.fixed || {}
    for (let i in form) {
        const val = fixedValue[i] || obj[i] || defaultValue[i] || ''
        form[i] = val
    }
    return form
}
